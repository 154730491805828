import { observer } from "mobx-react-lite";
import React, { FC, Fragment, useEffect, useState } from "react";
import { gpt } from "@dasha.ai/sdk/web";
import { gptFunctionsStore } from "../../Functions/types";
import { Dropdown, Input, Label, Form as SemForm, TextArea } from "semantic-ui-react";
import Form from "@rjsf/semantic-ui";
import validator from "@rjsf/validator-ajv8";
import { JsonEditor } from "json-edit-react";
import { RJSFSchema } from "@rjsf/utils";
import "./index.css";

export const EditCallItem: FC<{
  item: gpt.FunctionCallMessage;
  functions?: gptFunctionsStore;
  onChange: (item: gpt.FunctionCallMessage) => void;
}> = observer(({ item, onChange, functions }) => {
  const [functionName, setFunctionName] = useState<string>(item.functionCallName);
  const [scheme, setScheme] = useState(functions?.GetByName(item.functionCallName)?.getJSONParams());
  const [args, setArgs] = useState(item.functionCallArgs);

  useEffect(() => {
    const scheme = functions?.GetByName(functionName)?.getJSONParams();
    if (scheme) {
      var hasNull = false;
      // fix schema until fixed in DSL compiler
      for (var [k, v] of Object.entries(scheme.properties)) {
        const types = new Set<string>();
        if (v.oneOf !== undefined) {
          for (var oneof of v.oneOf) {
            types.add(oneof.type);
          }
          if (types.has("null")) {
            scheme.properties[k].oneOf = v.oneOf.filter((x) => x.type !== "null");
            hasNull = true;
          }
        }

        const typesArray = Array.from(types);
        if (typesArray.length === 1) {
          scheme.properties[k].type = typesArray[0];
        } 
        if (typesArray.length > 1) {
          if (hasNull && scheme.properties[k].oneOf?.length === 1 ) {
            delete scheme.properties[k].oneOf;
          }
          scheme.properties[k].type = typesArray;
        }
      }
    }

    setScheme(scheme);
  }, [functionName, functions]);

  useEffect(() => {
    const droppArgs = Object.fromEntries(
      Object.entries(args).filter(([k, v]) => scheme === undefined || scheme.properties[k] !== undefined)
    );
    onChange({
      type: "call",
      functionCallArgs: droppArgs,
      functionCallName: functionName,
    });
  }, [functionName, args]);
  return (
    <>
      {functions && (
        <Dropdown
          options={functions.Functions.map((x) => ({ key: x.Name, text: x.Name, value: x.Name }))}
          value={functionName}
          onChange={(e, d) => {
            setFunctionName(d.value?.toString() ?? item.functionCallName);
          }}
        />
      )}
      {!functions && <Input value={functionName} onChange={(e, d) => setFunctionName(d.value)}></Input>}
      {scheme && (
        <Form
          omitExtraData={true}
          schema={scheme as RJSFSchema}
          validator={validator}
          formData={args}
          onChange={(x) => x.errors.length == 0 && setArgs(x.formData)}
        >
          <Fragment />
        </Form>
      )}
      <JsonEditor
        data={args}
        setData={setArgs}
      />
    </>
  );
});
