export function selectServers(): { auth: string, sip: string, app: string } {
    switch (window.location.hostname){
        // case "localhost":
        //     return {
        //         sip: "localhost:5080",
        //         app: "localhost:8084",
        //         auth: "http://auth",
        //     };      
        case "playground.staging.dasha.ai":
            return {
                sip: "sip.staging.dasha.ai",
                app: "app.staging.dasha.ai",
                auth: "auth.staging.dasha.ai",
            };
        case "playground.ru.dasha.ai":
            return {
                sip: "sip.ru.dasha.ai",
                app: "app.ru.dasha.ai",
                auth: "auth.ru.dasha.ai",
            };       
    }
    return {
        sip: "sip.us.dasha.ai",
        app: "app.us.dasha.ai",
        auth: "auth.dasha.ai",
    };            
}