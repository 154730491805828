import React, { FC, useEffect, useState, useRef, useCallback, useMemo } from "react";

import { observer } from "mobx-react-lite";
import * as dasha from "@dasha.ai/sdk/web";
import {
  Table,
  Loader,
  Button,
  Modal,
  Input,
  Dropdown,
  Message,
  Confirm,
  Popup,
  Form,
  TextArea,
} from "semantic-ui-react";
import { useStore } from "../../core/api/GlobalStoreContext";
import UIManager from "@core/misc/UIManager";

export const AddSupportTicketModal: FC<{
  additionalInformation: string | undefined;
  onCommited: () => void;
  children;
}> = observer(({ additionalInformation, onCommited, children }) => {
  const { account } = useStore();
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [error, setError] = useState<string | undefined>();

  const getFullDescription = () => {
    var newDescription = description;
    if (additionalInformation !== undefined) {
      newDescription += "\n\nAdditionaInformation: " + additionalInformation;
    }
    return newDescription.replaceAll("\n", "<br>\n");
  };

  const addTicket = useCallback(() => {
    const a = account.connect();
    dasha.support
      .createTicket(
        {
          title: title,
          description: getFullDescription(),
        },
        { account: a }
      )
      .then(() => {
        UIManager.success("Ticket created");
        setOpen(false);
        onCommited();
      })
      .catch((e) => setError(e.message));
  }, [account, title, description]);

  return (
    <Modal onClose={() => setOpen(false)} onOpen={() => setOpen(true)} open={open} trigger={children}>
      <Modal.Header>Add support ticket</Modal.Header>
      {error && <Message negative>{error}</Message>}
      <Modal.Content>
        <Form>
          <Form.Field content="Title">
            <Input placeholder="Title" value={title} onChange={(e, { value }) => setTitle(value)} />
          </Form.Field>
          <Form.Field content="Description">
            <TextArea
              placeholder="Issue description"
              value={description}
              onChange={(e, { value }) => setDescription(value?.toString() ?? "")}
            />
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => addTicket()}>Create ticket</Button>
      </Modal.Actions>
    </Modal>
  );
});
