import { action, computed, makeObservable, observable, runInAction, toJS } from "mobx";
import { chunk, groupBy } from "lodash";
import { v4 as uuid } from "uuid";
import dayjs from "dayjs";

import Project from "@core/explorer/Project";
import DatasetStorage from "@core/workspace/dataset-storage/DatasetStorage";

import { Emitter } from "../misc/emitter";
import { Trigger } from "../workspace/session-storage/devlogs";
import { Message, RunnerType, SessionProtocol } from "../workspace/session-storage/types";
import SessionRunner from "../workspace/session-storage/SessionRunner";
import MarkableMessage from "../workspace/session-storage/MarkableMessage";

import { createDownloadLink, filterLog, getDuration, getEntities, getIntents } from "./utils";
import ProfilerStorage from "./storage";
import Cluster from "./Cluster";
import Filter from "./Filter";
import { Phrase } from "./types";
import { NluSttReason } from "./storage/interfaces";

export interface State {
  filter: Filter | null;
  data: Cluster[];
}

export interface ProfileOptions {
  marked: Record<string, Trigger[]>;
  project: Project;
  storage: ProfilerStorage;
  datasetStorage?: DatasetStorage;
}

export default class ProfilerTable {
  public filter: Filter | null = null;
  public clusters: Cluster[] = [];
  public currentSessionPhrase: string | null = null;
  public nlustt: NluSttReason[] = [];
  public isExporting: boolean  = false;

  private readonly project: Project;
  private readonly storage: ProfilerStorage;
  private readonly datasetStorage?: DatasetStorage;
  private readonly marked: Record<string, Trigger[]>;
  private readonly conversations: ProfilerTable | null;

  private readonly _onDidOpenSession = new Emitter<SessionProtocol | null>();
  public readonly onDidOpenSession = this._onDidOpenSession.event;

  constructor(options: ProfileOptions) {
    this.project = options.project;
    this.storage = options.storage;
    this.marked = options.marked;
    this.datasetStorage = options.datasetStorage;

    makeObservable(this, {
      filter: observable,
      clusters: observable,
      currentSessionPhrase: observable,
      totalPhrases: computed,
      fixedPhrases: computed,
      unrecognizedPhrases: computed,
      nluRate: computed,
      openSession: action,
      prepareProfileData: action,
    });
  }

  get totalPhrases() {
    return this.clusters.reduce((acc, curr) => acc + curr.phrases.length, 0);
  }

  get fixedPhrases() {
    let count = 0;

    this.clusters.forEach((cluster) => {
      const editedPhrases = cluster.phrases.filter((phrase) => phrase.markableMessage.isEdited);

      count += editedPhrases.length;
    });

    return count;
  }

  get correctPhrases() {
    let count = 0;

    this.clusters.forEach((cluster) => {
      const phrases = cluster.phrases.filter(
        (phrase) => !phrase.markableMessage.isEdited && phrase.markableMessage.isMarked
      );

      count += phrases.length;
    });

    return count;
  }

  get unrecognizedPhrases() {
    return this.clusters.reduce((acc, curr) => {
      const count = curr.phrases.filter(
        (phrase) =>
          phrase.markableMessage.triggers.length === 0 ||
          phrase.markableMessage.triggers.every((trigger) => trigger.readOnly)
      ).length;

      return acc + count;
    }, 0);
  }

  get recognizedNotValidated() {
    let count = 0;

    this.clusters.forEach((cluster) => {
      const phrases = cluster.phrases.filter(
        (phrase) => !phrase.markableMessage.isEdited && !phrase.markableMessage.isMarked
      );

      count += phrases.length;
    });

    return count;
  }

  get validationRate() {
    return (this.fixedPhrases + this.correctPhrases) / this.totalPhrases;
  }

  get nluRate() {
    return (this.correctPhrases + this.recognizedNotValidated) / this.totalPhrases;
  }

  public async openSession(phrase: Phrase, cb?: () => void) {
    try {
      this._onDidOpenSession.fire(null);
      console.log("logs", "_onDidOpenSession");

      const logs = await this.storage?.getLog(phrase.session.id);
      const genId = (log) => `${log.reasonId}_${log.sttResponseId}_${phrase.session.id}_${log.voiceSegmentId}`;

      console.log("logs", logs);

      const messages: Message[] = logs.filter(filterLog).map((log) => ({
        id: log.sttResponseId && log.reasonId ? genId(log) : uuid(),
        from: log.incoming ? "human" : "ai",
        transitions: [],
        changeContext: {},
        triggers: [],
        message: log.msg,
        time: +new Date(log.time),
      }));

      console.log("logs", messages);

      const job = await this.storage.getSessionById(phrase.session.id);
      console.log("logs", { job });

      const session = new SessionRunner(
        {
          type: RunnerType.Text,
          jobId: phrase.session.id,
          timeStarted: job?.startedTime,
          timeEnded: job?.completedTime,
          duration: getDuration(job),
          messages,
          isSending: false,
          isReadOnly: false,
          outputData: phrase.session.jobData?.outputData,
          inputData: phrase.session.jobData?.inputData
        },
        this.project.account
      );

      this.currentSessionPhrase = phrase.uuid;
      session.selectMessage(genId(phrase));
      this._onDidOpenSession.fire(session);
      cb?.();
    } catch (err) {
      console.log(err);
    }
  }

  public exportToCsv() {
    try {
      this.isExporting = true;

      const fetchData = async() => {
        const rows = [["text", "nlu", "jobIds"]];
        for (let i = 0; i < this.nlustt.length; i++) {
          const entity = this.nlustt[i];
          const jobIds = await this.storage.getSessionIdsByReasonId(entity.id)
          const row: string[] = [entity.sttResponse, "'"+ JSON.stringify(entity.nluResponse)+ "'", "'"+ JSON.stringify(jobIds)+ "'"];
          rows.push(row);
        }

        const csvData = rows.map((r) => r.join(";")).join("\n");
        createDownloadLink([csvData], "text/csv", "nlu.csv");
  
      };

      fetchData().catch(console.log).finally(() => { this.isExporting = false; });
    } catch (err) {
      console.log(err);
    }
  }
  
  async prepareProfileData() {
    const reasons = await this.storage?.getNluStt();
    this.nlustt = reasons;
    const clusters = groupBy(reasons, ({ clusterId }) => clusterId);
    const clustersByTransition: Cluster[] = [];

    // Todo: render table when there are no clusters
    for (const clusterId in clusters) {
      const cluster = await this.storage?.getCluster(clusterId);
      //if (cluster == null) continue;

      const clusterReasons = clusters[clusterId];
      const clusterTransitions = {};
      const newCluster = new Cluster(clusterId, cluster?.title ?? "Unknown");

      for (const clusterReason of clusterReasons) {
        const transitions = await this.storage?.getTransitionsByReasonId(clusterReason.id);
        const filteredTransitions = transitions.filter(
          (transition) =>
            ![
              "PreprocessorReturn",
              "Preprocessor",
              "DigressionReturn",
              "GlobalPreprocessor",
              "GlobalPreprocessorReturn",
              "GlobalReturn",
            ].includes(transition.transitionType)
        );

        filteredTransitions.forEach((transition) => {
          if (clusterTransitions[transition.id]) {
            clusterTransitions[transition.id].reasons.push(clusterReason);
          } else {
            clusterTransitions[transition.id] = { transition, reasons: [clusterReason] };
          }
        });
      }

      for (const transitionId in clusterTransitions) {
        const list: { session: any; reason: any }[] = [];
        for (const reason of clusterTransitions[transitionId].reasons) {
          const sessions = await this.storage?.getSessionsByReasonIdAndTransitionTableId(
            reason.id,
            clusterTransitions[transitionId].transition.id
          );

          list.push(...sessions.map((session) => ({ session, reason })));
        }

        const pool: any[][] = cluster == null ? chunk(list, 50) : [list];

        let id = 0;
        const added = new Set<string>();
        for (const sessions of pool) {
          id += 1;
          for (const { session, reason } of sessions) {
            const msgId = `${reason.id}_${reason.sttResponseId}_${session.id}`;
            const triggers = this.marked[msgId] ?? [
              ...getEntities(reason.nluResponse.nluResponses, reason.sttResponse),
              ...getIntents(reason.nluResponse.nluResponses),
            ];
            const phrase: Message = {
              id: msgId,
              from: "human",
              transitions: [],
              changeContext: {},
              triggers: triggers,
              message: reason.sttResponse,
              time: dayjs().unix(),
            };
            
            const key = reason.sttResponse + "__" + triggers.map((x) => x.name).join("_") + session.id;
            if (added.has(key)) {
              continue;
            }
            added.add(key);

            newCluster.addPhrase({
              uuid: uuid(),
              reasonId: reason.id,
              session: session,
              sttResponseId: session.sttResponseId,
              voiceSegmentId: +session.voiceSegmentId,
              markableMessage: new MarkableMessage(phrase),
              transition: clusterTransitions[transitionId].transition,
            });
          }
        }
      }

      clustersByTransition.push(newCluster);
    }

    runInAction(() => {
      this.clusters = clustersByTransition.filter((cluster) => cluster.phrases.length !== 0);
      this.filter = new Filter(this.clusters, this.datasetStorage);
    });
  }

  editedTriggers() {
    const map: Record<string, Trigger[]> = {};
    this.clusters.forEach((cluster) => {
      cluster.phrases.forEach(({ markableMessage }) => {
        if (markableMessage.isEdited === false && markableMessage.isMarked === false) return;
        map[markableMessage.id] = toJS(markableMessage.triggers);
      });
    });

    return map;
  }

  generateDatasets() {
    const datasets: any[] = [];
    this.clusters.forEach((cluster) => {
      cluster.phrases.forEach(({ markableMessage }) => {
        if (markableMessage.isEdited === false && markableMessage.isMarked === false) return;
        datasets.push(markableMessage.generateDataset());
      });
    });

    return datasets;
  }
}
