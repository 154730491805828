// Generated from /DSL/DSL.Library/graph.g4 by ANTLR 4.13.1
// jshint ignore: start
import antlr4 from 'antlr4';
import graphListener from './graphListener.js';
import graphVisitor from './graphVisitor.js';

const serializedATN = [4,1,115,1107,2,0,7,0,2,1,7,1,2,2,7,2,2,3,7,3,2,4,
7,4,2,5,7,5,2,6,7,6,2,7,7,7,2,8,7,8,2,9,7,9,2,10,7,10,2,11,7,11,2,12,7,12,
2,13,7,13,2,14,7,14,2,15,7,15,2,16,7,16,2,17,7,17,2,18,7,18,2,19,7,19,2,
20,7,20,2,21,7,21,2,22,7,22,2,23,7,23,2,24,7,24,2,25,7,25,2,26,7,26,2,27,
7,27,2,28,7,28,2,29,7,29,2,30,7,30,2,31,7,31,2,32,7,32,2,33,7,33,2,34,7,
34,2,35,7,35,2,36,7,36,2,37,7,37,2,38,7,38,2,39,7,39,2,40,7,40,2,41,7,41,
2,42,7,42,2,43,7,43,2,44,7,44,2,45,7,45,2,46,7,46,2,47,7,47,2,48,7,48,2,
49,7,49,2,50,7,50,2,51,7,51,2,52,7,52,2,53,7,53,2,54,7,54,2,55,7,55,2,56,
7,56,2,57,7,57,2,58,7,58,2,59,7,59,2,60,7,60,2,61,7,61,2,62,7,62,2,63,7,
63,2,64,7,64,2,65,7,65,2,66,7,66,2,67,7,67,2,68,7,68,2,69,7,69,2,70,7,70,
2,71,7,71,2,72,7,72,2,73,7,73,2,74,7,74,2,75,7,75,2,76,7,76,2,77,7,77,2,
78,7,78,2,79,7,79,2,80,7,80,2,81,7,81,2,82,7,82,2,83,7,83,2,84,7,84,2,85,
7,85,2,86,7,86,2,87,7,87,2,88,7,88,2,89,7,89,2,90,7,90,2,91,7,91,2,92,7,
92,2,93,7,93,2,94,7,94,2,95,7,95,2,96,7,96,1,0,3,0,196,8,0,1,0,1,0,1,0,1,
1,1,1,1,1,1,2,1,2,1,2,1,3,5,3,208,8,3,10,3,12,3,211,9,3,1,3,5,3,214,8,3,
10,3,12,3,217,9,3,1,3,1,3,3,3,221,8,3,1,3,1,3,3,3,225,8,3,1,3,1,3,1,3,1,
3,1,3,1,3,1,3,5,3,234,8,3,10,3,12,3,237,9,3,1,4,1,4,1,4,1,4,5,4,243,8,4,
10,4,12,4,246,9,4,1,4,1,4,1,5,1,5,1,5,1,5,1,5,1,5,1,5,1,5,3,5,258,8,5,1,
6,1,6,1,6,1,6,5,6,264,8,6,10,6,12,6,267,9,6,1,6,1,6,1,7,1,7,3,7,273,8,7,
1,7,1,7,1,7,1,7,1,7,3,7,280,8,7,1,8,1,8,1,8,1,8,1,8,1,8,1,8,1,8,1,8,1,8,
1,9,1,9,1,9,1,9,3,9,296,8,9,1,9,1,9,1,10,1,10,1,10,3,10,303,8,10,1,10,1,
10,3,10,307,8,10,1,10,1,10,1,11,1,11,1,11,1,11,1,11,1,11,1,11,5,11,318,8,
11,10,11,12,11,321,9,11,1,11,3,11,324,8,11,1,11,1,11,1,12,1,12,1,12,5,12,
331,8,12,10,12,12,12,334,9,12,3,12,336,8,12,1,12,3,12,339,8,12,1,12,1,12,
5,12,343,8,12,10,12,12,12,346,9,12,3,12,348,8,12,1,12,1,12,1,12,1,12,1,12,
1,12,1,12,1,12,1,12,1,13,1,13,1,13,5,13,362,8,13,10,13,12,13,365,9,13,3,
13,367,8,13,1,13,3,13,370,8,13,1,13,1,13,5,13,374,8,13,10,13,12,13,377,9,
13,3,13,379,8,13,1,13,1,13,1,13,1,13,1,13,1,13,1,13,1,13,1,13,1,13,1,14,
3,14,392,8,14,1,14,1,14,1,14,1,14,1,14,1,14,1,14,1,14,1,14,3,14,403,8,14,
1,14,1,14,1,14,1,14,1,14,1,14,3,14,411,8,14,1,14,1,14,1,14,1,14,1,15,3,15,
418,8,15,1,15,1,15,1,15,1,15,3,15,424,8,15,1,15,1,15,1,15,1,16,3,16,430,
8,16,1,16,3,16,433,8,16,1,16,1,16,1,16,1,16,1,16,1,16,1,17,1,17,1,17,1,17,
1,17,5,17,446,8,17,10,17,12,17,449,9,17,1,17,1,17,1,17,1,17,3,17,455,8,17,
1,17,1,17,5,17,459,8,17,10,17,12,17,462,9,17,1,17,1,17,1,18,1,18,1,18,1,
19,1,19,3,19,471,8,19,1,19,1,19,3,19,475,8,19,1,19,3,19,478,8,19,1,19,3,
19,481,8,19,1,20,1,20,1,20,5,20,486,8,20,10,20,12,20,489,9,20,1,20,1,20,
1,20,3,20,494,8,20,1,20,1,20,1,20,3,20,499,8,20,1,20,1,20,1,21,1,21,1,21,
4,21,506,8,21,11,21,12,21,507,1,21,1,21,1,22,1,22,1,22,1,22,3,22,516,8,22,
1,23,1,23,1,23,1,24,1,24,1,24,5,24,524,8,24,10,24,12,24,527,9,24,1,24,1,
24,1,25,1,25,1,25,1,25,1,25,1,25,1,25,1,26,1,26,3,26,540,8,26,1,27,1,27,
3,27,544,8,27,1,28,1,28,1,28,1,28,1,29,1,29,1,29,1,29,3,29,554,8,29,1,29,
1,29,3,29,558,8,29,1,29,1,29,1,29,1,29,1,29,5,29,565,8,29,10,29,12,29,568,
9,29,3,29,570,8,29,3,29,572,8,29,1,30,1,30,1,31,1,31,1,31,1,32,1,32,5,32,
581,8,32,10,32,12,32,584,9,32,1,32,1,32,1,33,1,33,3,33,590,8,33,1,34,1,34,
1,34,3,34,595,8,34,1,35,1,35,1,36,1,36,1,36,1,37,1,37,1,37,1,37,1,37,1,37,
1,37,1,37,1,37,3,37,611,8,37,1,38,1,38,1,38,1,39,1,39,1,39,1,39,1,39,1,39,
1,39,1,39,1,39,1,39,1,39,1,39,3,39,628,8,39,1,40,1,40,1,40,1,40,1,40,5,40,
635,8,40,10,40,12,40,638,9,40,1,40,1,40,3,40,642,8,40,1,41,1,41,1,42,1,42,
3,42,648,8,42,1,43,1,43,1,43,3,43,653,8,43,1,43,1,43,3,43,657,8,43,1,44,
1,44,1,44,1,45,1,45,1,46,1,46,1,46,1,46,1,46,1,46,1,46,3,46,671,8,46,1,47,
1,47,1,47,1,47,1,47,1,47,1,47,1,47,1,48,1,48,1,48,1,49,1,49,1,49,3,49,687,
8,49,1,49,5,49,690,8,49,10,49,12,49,693,9,49,1,49,1,49,1,49,3,49,698,8,49,
1,50,1,50,1,50,1,51,1,51,1,51,1,51,1,51,1,52,1,52,1,52,1,52,1,52,1,52,1,
52,1,52,1,52,1,52,3,52,718,8,52,1,53,1,53,1,53,1,53,1,53,1,53,3,53,726,8,
53,1,53,1,53,1,53,1,53,1,53,1,53,1,53,1,53,1,53,1,53,1,53,1,53,5,53,740,
8,53,10,53,12,53,743,9,53,1,54,1,54,1,54,1,54,1,54,1,54,1,54,1,54,1,54,1,
54,1,54,1,54,1,54,1,54,1,54,1,54,1,54,1,54,1,54,3,54,764,8,54,1,54,1,54,
1,54,1,54,1,54,1,54,5,54,772,8,54,10,54,12,54,775,9,54,3,54,777,8,54,1,54,
1,54,1,54,3,54,782,8,54,1,54,1,54,5,54,786,8,54,10,54,12,54,789,9,54,1,54,
1,54,3,54,793,8,54,1,54,1,54,1,54,1,54,1,54,1,54,1,54,1,54,1,54,1,54,1,54,
1,54,1,54,1,54,1,54,1,54,1,54,1,54,1,54,1,54,1,54,1,54,1,54,1,54,1,54,1,
54,1,54,1,54,1,54,1,54,1,54,1,54,1,54,1,54,1,54,1,54,1,54,1,54,1,54,1,54,
1,54,1,54,1,54,1,54,1,54,1,54,1,54,1,54,1,54,1,54,1,54,1,54,1,54,1,54,1,
54,1,54,1,54,1,54,3,54,853,8,54,1,54,1,54,1,54,1,54,5,54,859,8,54,10,54,
12,54,862,9,54,1,55,1,55,1,55,1,55,1,56,1,56,1,56,1,56,1,57,1,57,1,57,1,
57,1,58,1,58,1,58,1,58,1,59,1,59,1,59,1,59,1,60,1,60,1,60,1,60,1,61,1,61,
1,61,3,61,891,8,61,1,61,1,61,3,61,895,8,61,1,62,1,62,1,62,5,62,900,8,62,
10,62,12,62,903,9,62,1,63,1,63,1,63,5,63,908,8,63,10,63,12,63,911,9,63,1,
64,1,64,1,65,1,65,1,65,1,65,1,66,1,66,1,66,5,66,922,8,66,10,66,12,66,925,
9,66,3,66,927,8,66,1,67,1,67,1,67,1,67,1,67,3,67,934,8,67,1,68,1,68,1,68,
1,68,1,68,3,68,941,8,68,1,68,1,68,1,68,1,68,1,68,3,68,948,8,68,1,68,1,68,
3,68,952,8,68,3,68,954,8,68,3,68,956,8,68,1,69,1,69,1,70,1,70,1,71,1,71,
1,72,1,72,1,73,1,73,1,74,1,74,1,75,1,75,1,76,1,76,1,77,1,77,1,78,1,78,1,
79,1,79,1,80,1,80,1,81,1,81,1,82,1,82,1,83,1,83,1,84,1,84,1,85,1,85,1,86,
1,86,1,87,1,87,1,87,1,88,1,88,1,88,1,88,1,88,1,88,5,88,1003,8,88,10,88,12,
88,1006,9,88,1,89,1,89,3,89,1010,8,89,1,90,1,90,1,91,1,91,1,91,1,91,1,91,
1,91,1,91,1,91,1,91,1,91,5,91,1024,8,91,10,91,12,91,1027,9,91,1,91,1,91,
1,91,3,91,1032,8,91,1,91,1,91,1,91,5,91,1037,8,91,10,91,12,91,1040,9,91,
1,91,1,91,1,91,3,91,1045,8,91,1,91,1,91,5,91,1049,8,91,10,91,12,91,1052,
9,91,1,91,1,91,1,91,3,91,1057,8,91,1,91,1,91,5,91,1061,8,91,10,91,12,91,
1064,9,91,1,91,1,91,1,91,3,91,1069,8,91,1,91,1,91,3,91,1073,8,91,1,91,1,
91,5,91,1077,8,91,10,91,12,91,1080,9,91,1,92,1,92,1,92,1,92,1,92,3,92,1087,
8,92,1,93,1,93,3,93,1091,8,93,1,93,1,93,1,94,1,94,1,94,1,94,1,94,1,94,1,
94,1,95,1,95,1,95,1,96,1,96,1,96,0,3,106,108,182,97,0,2,4,6,8,10,12,14,16,
18,20,22,24,26,28,30,32,34,36,38,40,42,44,46,48,50,52,54,56,58,60,62,64,
66,68,70,72,74,76,78,80,82,84,86,88,90,92,94,96,98,100,102,104,106,108,110,
112,114,116,118,120,122,124,126,128,130,132,134,136,138,140,142,144,146,
148,150,152,154,156,158,160,162,164,166,168,170,172,174,176,178,180,182,
184,186,188,190,192,0,9,1,0,2,6,2,0,8,9,110,110,1,0,10,12,1,0,8,9,2,0,87,
88,105,106,1,0,103,104,2,0,7,7,111,111,1,0,76,77,1,0,96,97,1175,0,195,1,
0,0,0,2,200,1,0,0,0,4,203,1,0,0,0,6,209,1,0,0,0,8,238,1,0,0,0,10,257,1,0,
0,0,12,259,1,0,0,0,14,272,1,0,0,0,16,281,1,0,0,0,18,291,1,0,0,0,20,299,1,
0,0,0,22,310,1,0,0,0,24,327,1,0,0,0,26,358,1,0,0,0,28,410,1,0,0,0,30,417,
1,0,0,0,32,429,1,0,0,0,34,440,1,0,0,0,36,465,1,0,0,0,38,468,1,0,0,0,40,482,
1,0,0,0,42,505,1,0,0,0,44,511,1,0,0,0,46,517,1,0,0,0,48,520,1,0,0,0,50,530,
1,0,0,0,52,539,1,0,0,0,54,543,1,0,0,0,56,545,1,0,0,0,58,549,1,0,0,0,60,573,
1,0,0,0,62,575,1,0,0,0,64,578,1,0,0,0,66,589,1,0,0,0,68,594,1,0,0,0,70,596,
1,0,0,0,72,598,1,0,0,0,74,610,1,0,0,0,76,612,1,0,0,0,78,627,1,0,0,0,80,641,
1,0,0,0,82,643,1,0,0,0,84,645,1,0,0,0,86,649,1,0,0,0,88,658,1,0,0,0,90,661,
1,0,0,0,92,663,1,0,0,0,94,672,1,0,0,0,96,680,1,0,0,0,98,697,1,0,0,0,100,
699,1,0,0,0,102,702,1,0,0,0,104,717,1,0,0,0,106,725,1,0,0,0,108,792,1,0,
0,0,110,863,1,0,0,0,112,867,1,0,0,0,114,871,1,0,0,0,116,875,1,0,0,0,118,
879,1,0,0,0,120,883,1,0,0,0,122,894,1,0,0,0,124,896,1,0,0,0,126,904,1,0,
0,0,128,912,1,0,0,0,130,914,1,0,0,0,132,926,1,0,0,0,134,928,1,0,0,0,136,
955,1,0,0,0,138,957,1,0,0,0,140,959,1,0,0,0,142,961,1,0,0,0,144,963,1,0,
0,0,146,965,1,0,0,0,148,967,1,0,0,0,150,969,1,0,0,0,152,971,1,0,0,0,154,
973,1,0,0,0,156,975,1,0,0,0,158,977,1,0,0,0,160,979,1,0,0,0,162,981,1,0,
0,0,164,983,1,0,0,0,166,985,1,0,0,0,168,987,1,0,0,0,170,989,1,0,0,0,172,
991,1,0,0,0,174,993,1,0,0,0,176,996,1,0,0,0,178,1009,1,0,0,0,180,1011,1,
0,0,0,182,1068,1,0,0,0,184,1086,1,0,0,0,186,1088,1,0,0,0,188,1094,1,0,0,
0,190,1101,1,0,0,0,192,1104,1,0,0,0,194,196,5,46,0,0,195,194,1,0,0,0,195,
196,1,0,0,0,196,197,1,0,0,0,197,198,3,6,3,0,198,199,5,0,0,1,199,1,1,0,0,
0,200,201,3,136,68,0,201,202,5,0,0,1,202,3,1,0,0,0,203,204,3,108,54,0,204,
205,5,0,0,1,205,5,1,0,0,0,206,208,3,18,9,0,207,206,1,0,0,0,208,211,1,0,0,
0,209,207,1,0,0,0,209,210,1,0,0,0,210,215,1,0,0,0,211,209,1,0,0,0,212,214,
3,22,11,0,213,212,1,0,0,0,214,217,1,0,0,0,215,213,1,0,0,0,215,216,1,0,0,
0,216,220,1,0,0,0,217,215,1,0,0,0,218,219,5,23,0,0,219,221,3,12,6,0,220,
218,1,0,0,0,220,221,1,0,0,0,221,224,1,0,0,0,222,223,5,64,0,0,223,225,3,8,
4,0,224,222,1,0,0,0,224,225,1,0,0,0,225,235,1,0,0,0,226,234,3,16,8,0,227,
234,3,22,11,0,228,234,3,30,15,0,229,234,3,28,14,0,230,234,3,32,16,0,231,
234,3,26,13,0,232,234,3,20,10,0,233,226,1,0,0,0,233,227,1,0,0,0,233,228,
1,0,0,0,233,229,1,0,0,0,233,230,1,0,0,0,233,231,1,0,0,0,233,232,1,0,0,0,
234,237,1,0,0,0,235,233,1,0,0,0,235,236,1,0,0,0,236,7,1,0,0,0,237,235,1,
0,0,0,238,244,5,92,0,0,239,240,3,10,5,0,240,241,3,180,90,0,241,243,1,0,0,
0,242,239,1,0,0,0,243,246,1,0,0,0,244,242,1,0,0,0,244,245,1,0,0,0,245,247,
1,0,0,0,246,244,1,0,0,0,247,248,5,91,0,0,248,9,1,0,0,0,249,250,3,158,79,
0,250,251,5,95,0,0,251,252,3,108,54,0,252,258,1,0,0,0,253,254,3,158,79,0,
254,255,5,100,0,0,255,256,3,108,54,0,256,258,1,0,0,0,257,249,1,0,0,0,257,
253,1,0,0,0,258,11,1,0,0,0,259,265,5,92,0,0,260,261,3,14,7,0,261,262,3,180,
90,0,262,264,1,0,0,0,263,260,1,0,0,0,264,267,1,0,0,0,265,263,1,0,0,0,265,
266,1,0,0,0,266,268,1,0,0,0,267,265,1,0,0,0,268,269,5,91,0,0,269,13,1,0,
0,0,270,273,5,44,0,0,271,273,5,54,0,0,272,270,1,0,0,0,272,271,1,0,0,0,272,
273,1,0,0,0,273,274,1,0,0,0,274,275,3,158,79,0,275,276,5,95,0,0,276,279,
3,90,45,0,277,278,5,100,0,0,278,280,3,108,54,0,279,277,1,0,0,0,279,280,1,
0,0,0,280,15,1,0,0,0,281,282,5,34,0,0,282,283,5,38,0,0,283,284,3,168,84,
0,284,285,5,90,0,0,285,286,3,132,66,0,286,287,5,89,0,0,287,288,5,95,0,0,
288,289,3,90,45,0,289,290,5,96,0,0,290,17,1,0,0,0,291,292,5,42,0,0,292,295,
5,7,0,0,293,294,5,1,0,0,294,296,5,7,0,0,295,293,1,0,0,0,295,296,1,0,0,0,
296,297,1,0,0,0,297,298,5,96,0,0,298,19,1,0,0,0,299,300,5,72,0,0,300,302,
7,0,0,0,301,303,3,138,69,0,302,301,1,0,0,0,302,303,1,0,0,0,303,306,1,0,0,
0,304,305,5,56,0,0,305,307,5,77,0,0,306,304,1,0,0,0,306,307,1,0,0,0,307,
308,1,0,0,0,308,309,3,62,31,0,309,21,1,0,0,0,310,311,5,69,0,0,311,312,3,
192,96,0,312,313,5,100,0,0,313,323,3,90,45,0,314,315,5,14,0,0,315,319,5,
92,0,0,316,318,3,24,12,0,317,316,1,0,0,0,318,321,1,0,0,0,319,317,1,0,0,0,
319,320,1,0,0,0,320,322,1,0,0,0,321,319,1,0,0,0,322,324,5,91,0,0,323,314,
1,0,0,0,323,324,1,0,0,0,324,325,1,0,0,0,325,326,5,96,0,0,326,23,1,0,0,0,
327,335,6,12,-1,0,328,331,5,112,0,0,329,331,5,114,0,0,330,328,1,0,0,0,330,
329,1,0,0,0,331,334,1,0,0,0,332,330,1,0,0,0,332,333,1,0,0,0,333,336,1,0,
0,0,334,332,1,0,0,0,335,332,1,0,0,0,335,336,1,0,0,0,336,338,1,0,0,0,337,
339,5,113,0,0,338,337,1,0,0,0,338,339,1,0,0,0,339,347,1,0,0,0,340,343,5,
112,0,0,341,343,5,114,0,0,342,340,1,0,0,0,342,341,1,0,0,0,343,346,1,0,0,
0,344,342,1,0,0,0,344,345,1,0,0,0,345,348,1,0,0,0,346,344,1,0,0,0,347,344,
1,0,0,0,347,348,1,0,0,0,348,349,1,0,0,0,349,350,6,12,-1,0,350,351,3,170,
85,0,351,352,5,90,0,0,352,353,3,132,66,0,353,354,5,89,0,0,354,355,5,95,0,
0,355,356,3,90,45,0,356,357,3,64,32,0,357,25,1,0,0,0,358,366,6,13,-1,0,359,
362,5,112,0,0,360,362,5,114,0,0,361,359,1,0,0,0,361,360,1,0,0,0,362,365,
1,0,0,0,363,361,1,0,0,0,363,364,1,0,0,0,364,367,1,0,0,0,365,363,1,0,0,0,
366,363,1,0,0,0,366,367,1,0,0,0,367,369,1,0,0,0,368,370,5,113,0,0,369,368,
1,0,0,0,369,370,1,0,0,0,370,378,1,0,0,0,371,374,5,112,0,0,372,374,5,114,
0,0,373,371,1,0,0,0,373,372,1,0,0,0,374,377,1,0,0,0,375,373,1,0,0,0,375,
376,1,0,0,0,376,379,1,0,0,0,377,375,1,0,0,0,378,375,1,0,0,0,378,379,1,0,
0,0,379,380,1,0,0,0,380,381,6,13,-1,0,381,382,5,38,0,0,382,383,3,170,85,
0,383,384,5,90,0,0,384,385,3,132,66,0,385,386,5,89,0,0,386,387,5,95,0,0,
387,388,3,90,45,0,388,389,3,64,32,0,389,27,1,0,0,0,390,392,5,63,0,0,391,
390,1,0,0,0,391,392,1,0,0,0,392,393,1,0,0,0,393,394,5,17,0,0,394,395,3,142,
71,0,395,396,5,90,0,0,396,397,3,132,66,0,397,398,5,89,0,0,398,399,5,95,0,
0,399,400,3,90,45,0,400,411,1,0,0,0,401,403,5,16,0,0,402,401,1,0,0,0,402,
403,1,0,0,0,403,404,1,0,0,0,404,405,5,17,0,0,405,406,3,142,71,0,406,407,
5,90,0,0,407,408,3,132,66,0,408,409,5,89,0,0,409,411,1,0,0,0,410,391,1,0,
0,0,410,402,1,0,0,0,411,412,1,0,0,0,412,413,5,92,0,0,413,414,3,6,3,0,414,
415,5,91,0,0,415,29,1,0,0,0,416,418,5,61,0,0,417,416,1,0,0,0,417,418,1,0,
0,0,418,419,1,0,0,0,419,420,5,48,0,0,420,421,3,140,70,0,421,423,5,92,0,0,
422,424,3,36,18,0,423,422,1,0,0,0,423,424,1,0,0,0,424,425,1,0,0,0,425,426,
3,38,19,0,426,427,5,91,0,0,427,31,1,0,0,0,428,430,5,39,0,0,429,428,1,0,0,
0,429,430,1,0,0,0,430,432,1,0,0,0,431,433,5,55,0,0,432,431,1,0,0,0,432,433,
1,0,0,0,433,434,1,0,0,0,434,435,5,27,0,0,435,436,3,144,72,0,436,437,5,92,
0,0,437,438,3,34,17,0,438,439,5,91,0,0,439,33,1,0,0,0,440,441,5,21,0,0,441,
447,5,92,0,0,442,443,3,54,27,0,443,444,5,96,0,0,444,446,1,0,0,0,445,442,
1,0,0,0,446,449,1,0,0,0,447,445,1,0,0,0,447,448,1,0,0,0,448,450,1,0,0,0,
449,447,1,0,0,0,450,460,5,91,0,0,451,452,5,60,0,0,452,455,3,86,43,0,453,
455,3,86,43,0,454,451,1,0,0,0,454,453,1,0,0,0,455,456,1,0,0,0,456,457,5,
96,0,0,457,459,1,0,0,0,458,454,1,0,0,0,459,462,1,0,0,0,460,458,1,0,0,0,460,
461,1,0,0,0,461,463,1,0,0,0,462,460,1,0,0,0,463,464,3,38,19,0,464,35,1,0,
0,0,465,466,5,20,0,0,466,467,5,7,0,0,467,37,1,0,0,0,468,470,3,62,31,0,469,
471,3,48,24,0,470,469,1,0,0,0,470,471,1,0,0,0,471,474,1,0,0,0,472,473,5,
55,0,0,473,475,3,62,31,0,474,472,1,0,0,0,474,475,1,0,0,0,475,477,1,0,0,0,
476,478,3,40,20,0,477,476,1,0,0,0,477,478,1,0,0,0,478,480,1,0,0,0,479,481,
3,46,23,0,480,479,1,0,0,0,480,481,1,0,0,0,481,39,1,0,0,0,482,483,5,53,0,
0,483,487,5,92,0,0,484,486,3,42,21,0,485,484,1,0,0,0,486,489,1,0,0,0,487,
485,1,0,0,0,487,488,1,0,0,0,488,493,1,0,0,0,489,487,1,0,0,0,490,491,5,25,
0,0,491,492,5,95,0,0,492,494,3,44,22,0,493,490,1,0,0,0,493,494,1,0,0,0,494,
498,1,0,0,0,495,496,5,27,0,0,496,497,5,95,0,0,497,499,3,44,22,0,498,495,
1,0,0,0,498,499,1,0,0,0,499,500,1,0,0,0,500,501,5,91,0,0,501,41,1,0,0,0,
502,503,3,146,73,0,503,504,5,95,0,0,504,506,1,0,0,0,505,502,1,0,0,0,506,
507,1,0,0,0,507,505,1,0,0,0,507,508,1,0,0,0,508,509,1,0,0,0,509,510,3,44,
22,0,510,43,1,0,0,0,511,515,3,62,31,0,512,513,5,51,0,0,513,514,5,22,0,0,
514,516,3,62,31,0,515,512,1,0,0,0,515,516,1,0,0,0,516,45,1,0,0,0,517,518,
5,52,0,0,518,519,3,64,32,0,519,47,1,0,0,0,520,521,5,66,0,0,521,525,5,92,
0,0,522,524,3,50,25,0,523,522,1,0,0,0,524,527,1,0,0,0,525,523,1,0,0,0,525,
526,1,0,0,0,526,528,1,0,0,0,527,525,1,0,0,0,528,529,5,91,0,0,529,49,1,0,
0,0,530,531,3,146,73,0,531,532,5,95,0,0,532,533,5,40,0,0,533,534,3,140,70,
0,534,535,3,52,26,0,535,536,5,96,0,0,536,51,1,0,0,0,537,540,3,54,27,0,538,
540,3,60,30,0,539,537,1,0,0,0,539,538,1,0,0,0,540,53,1,0,0,0,541,544,3,58,
29,0,542,544,3,56,28,0,543,541,1,0,0,0,543,542,1,0,0,0,544,55,1,0,0,0,545,
546,5,51,0,0,546,547,5,65,0,0,547,548,3,172,86,0,548,57,1,0,0,0,549,550,
5,51,0,0,550,553,3,108,54,0,551,552,5,56,0,0,552,554,5,77,0,0,553,551,1,
0,0,0,553,554,1,0,0,0,554,557,1,0,0,0,555,556,5,72,0,0,556,558,5,22,0,0,
557,555,1,0,0,0,557,558,1,0,0,0,558,571,1,0,0,0,559,560,5,67,0,0,560,569,
5,95,0,0,561,566,3,152,76,0,562,563,5,97,0,0,563,565,3,152,76,0,564,562,
1,0,0,0,565,568,1,0,0,0,566,564,1,0,0,0,566,567,1,0,0,0,567,570,1,0,0,0,
568,566,1,0,0,0,569,561,1,0,0,0,569,570,1,0,0,0,570,572,1,0,0,0,571,559,
1,0,0,0,571,572,1,0,0,0,572,59,1,0,0,0,573,574,1,0,0,0,574,61,1,0,0,0,575,
576,5,30,0,0,576,577,3,64,32,0,577,63,1,0,0,0,578,582,5,92,0,0,579,581,3,
66,33,0,580,579,1,0,0,0,581,584,1,0,0,0,582,580,1,0,0,0,582,583,1,0,0,0,
583,585,1,0,0,0,584,582,1,0,0,0,585,586,5,91,0,0,586,65,1,0,0,0,587,590,
3,68,34,0,588,590,3,64,32,0,589,587,1,0,0,0,589,588,1,0,0,0,590,67,1,0,0,
0,591,595,3,72,36,0,592,595,3,92,46,0,593,595,3,70,35,0,594,591,1,0,0,0,
594,592,1,0,0,0,594,593,1,0,0,0,595,69,1,0,0,0,596,597,3,94,47,0,597,71,
1,0,0,0,598,599,3,74,37,0,599,600,5,96,0,0,600,73,1,0,0,0,601,611,3,96,48,
0,602,611,3,100,50,0,603,611,3,102,51,0,604,611,3,84,42,0,605,611,3,82,41,
0,606,611,3,104,52,0,607,611,3,86,43,0,608,611,3,76,38,0,609,611,3,108,54,
0,610,601,1,0,0,0,610,602,1,0,0,0,610,603,1,0,0,0,610,604,1,0,0,0,610,605,
1,0,0,0,610,606,1,0,0,0,610,607,1,0,0,0,610,608,1,0,0,0,610,609,1,0,0,0,
611,75,1,0,0,0,612,613,5,47,0,0,613,614,3,108,54,0,614,77,1,0,0,0,615,616,
5,27,0,0,616,617,5,33,0,0,617,628,3,80,40,0,618,619,5,27,0,0,619,620,5,29,
0,0,620,628,3,80,40,0,621,622,5,27,0,0,622,623,5,58,0,0,623,628,3,80,40,
0,624,625,5,27,0,0,625,626,5,13,0,0,626,628,3,108,54,0,627,615,1,0,0,0,627,
618,1,0,0,0,627,621,1,0,0,0,627,624,1,0,0,0,628,79,1,0,0,0,629,642,3,144,
72,0,630,631,5,92,0,0,631,636,3,144,72,0,632,633,5,97,0,0,633,635,3,144,
72,0,634,632,1,0,0,0,635,638,1,0,0,0,636,634,1,0,0,0,636,637,1,0,0,0,637,
639,1,0,0,0,638,636,1,0,0,0,639,640,5,91,0,0,640,642,1,0,0,0,641,629,1,0,
0,0,641,630,1,0,0,0,642,81,1,0,0,0,643,644,5,35,0,0,644,83,1,0,0,0,645,647,
5,57,0,0,646,648,3,108,54,0,647,646,1,0,0,0,647,648,1,0,0,0,648,85,1,0,0,
0,649,652,3,88,44,0,650,651,5,95,0,0,651,653,3,90,45,0,652,650,1,0,0,0,652,
653,1,0,0,0,653,656,1,0,0,0,654,655,5,100,0,0,655,657,3,108,54,0,656,654,
1,0,0,0,656,657,1,0,0,0,657,87,1,0,0,0,658,659,5,70,0,0,659,660,3,158,79,
0,660,89,1,0,0,0,661,662,3,176,88,0,662,91,1,0,0,0,663,664,5,41,0,0,664,
665,5,90,0,0,665,666,3,108,54,0,666,667,5,89,0,0,667,670,3,66,33,0,668,669,
5,32,0,0,669,671,3,66,33,0,670,668,1,0,0,0,670,671,1,0,0,0,671,93,1,0,0,
0,672,673,5,37,0,0,673,674,5,90,0,0,674,675,3,88,44,0,675,676,5,43,0,0,676,
677,3,108,54,0,677,678,5,89,0,0,678,679,3,66,33,0,679,95,1,0,0,0,680,681,
5,71,0,0,681,682,3,98,49,0,682,97,1,0,0,0,683,684,5,92,0,0,684,691,3,146,
73,0,685,687,5,97,0,0,686,685,1,0,0,0,686,687,1,0,0,0,687,688,1,0,0,0,688,
690,3,146,73,0,689,686,1,0,0,0,690,693,1,0,0,0,691,689,1,0,0,0,691,692,1,
0,0,0,692,694,1,0,0,0,693,691,1,0,0,0,694,695,5,91,0,0,695,698,1,0,0,0,696,
698,5,10,0,0,697,683,1,0,0,0,697,696,1,0,0,0,698,99,1,0,0,0,699,700,5,40,
0,0,700,701,3,146,73,0,701,101,1,0,0,0,702,703,5,59,0,0,703,704,3,106,53,
0,704,705,5,100,0,0,705,706,3,108,54,0,706,103,1,0,0,0,707,708,5,59,0,0,
708,709,3,106,53,0,709,710,5,101,0,0,710,711,3,108,54,0,711,718,1,0,0,0,
712,713,5,59,0,0,713,714,3,106,53,0,714,715,5,102,0,0,715,716,3,108,54,0,
716,718,1,0,0,0,717,707,1,0,0,0,717,712,1,0,0,0,718,105,1,0,0,0,719,720,
6,53,-1,0,720,721,5,90,0,0,721,722,3,106,53,0,722,723,5,89,0,0,723,726,1,
0,0,0,724,726,3,136,68,0,725,719,1,0,0,0,725,724,1,0,0,0,726,741,1,0,0,0,
727,728,10,3,0,0,728,729,5,99,0,0,729,740,3,150,75,0,730,731,10,2,0,0,731,
732,5,86,0,0,732,733,5,77,0,0,733,740,5,85,0,0,734,735,10,1,0,0,735,736,
5,86,0,0,736,737,3,108,54,0,737,738,5,85,0,0,738,740,1,0,0,0,739,727,1,0,
0,0,739,730,1,0,0,0,739,734,1,0,0,0,740,743,1,0,0,0,741,739,1,0,0,0,741,
742,1,0,0,0,742,107,1,0,0,0,743,741,1,0,0,0,744,745,6,54,-1,0,745,793,3,
112,56,0,746,793,3,118,59,0,747,793,3,114,57,0,748,793,5,78,0,0,749,793,
3,116,58,0,750,793,3,172,86,0,751,793,5,7,0,0,752,753,5,90,0,0,753,754,3,
108,54,0,754,755,5,89,0,0,755,793,1,0,0,0,756,793,3,136,68,0,757,793,3,78,
39,0,758,759,7,1,0,0,759,793,3,108,54,15,760,761,3,136,68,0,761,763,5,45,
0,0,762,764,5,49,0,0,763,762,1,0,0,0,763,764,1,0,0,0,764,765,1,0,0,0,765,
766,5,26,0,0,766,793,1,0,0,0,767,776,5,92,0,0,768,773,3,110,55,0,769,770,
5,97,0,0,770,772,3,110,55,0,771,769,1,0,0,0,772,775,1,0,0,0,773,771,1,0,
0,0,773,774,1,0,0,0,774,777,1,0,0,0,775,773,1,0,0,0,776,768,1,0,0,0,776,
777,1,0,0,0,777,778,1,0,0,0,778,793,5,91,0,0,779,781,5,86,0,0,780,782,3,
108,54,0,781,780,1,0,0,0,781,782,1,0,0,0,782,787,1,0,0,0,783,784,5,97,0,
0,784,786,3,108,54,0,785,783,1,0,0,0,786,789,1,0,0,0,787,785,1,0,0,0,787,
788,1,0,0,0,788,790,1,0,0,0,789,787,1,0,0,0,790,793,5,85,0,0,791,793,5,84,
0,0,792,744,1,0,0,0,792,746,1,0,0,0,792,747,1,0,0,0,792,748,1,0,0,0,792,
749,1,0,0,0,792,750,1,0,0,0,792,751,1,0,0,0,792,752,1,0,0,0,792,756,1,0,
0,0,792,757,1,0,0,0,792,758,1,0,0,0,792,760,1,0,0,0,792,767,1,0,0,0,792,
779,1,0,0,0,792,791,1,0,0,0,793,860,1,0,0,0,794,795,10,12,0,0,795,796,7,
2,0,0,796,859,3,108,54,13,797,798,10,11,0,0,798,799,7,3,0,0,799,859,3,108,
54,12,800,801,10,10,0,0,801,802,7,4,0,0,802,859,3,108,54,11,803,804,10,9,
0,0,804,805,7,5,0,0,805,859,3,108,54,10,806,807,10,8,0,0,807,808,5,109,0,
0,808,859,3,108,54,9,809,810,10,7,0,0,810,811,5,107,0,0,811,859,3,108,54,
8,812,813,10,6,0,0,813,814,5,98,0,0,814,815,5,98,0,0,815,859,3,108,54,7,
816,817,10,5,0,0,817,818,5,98,0,0,818,819,3,108,54,0,819,820,5,95,0,0,820,
821,3,108,54,5,821,859,1,0,0,0,822,823,10,23,0,0,823,824,5,99,0,0,824,859,
3,150,75,0,825,826,10,22,0,0,826,827,5,98,0,0,827,828,5,99,0,0,828,859,3,
150,75,0,829,830,10,21,0,0,830,831,5,99,0,0,831,832,3,150,75,0,832,833,3,
120,60,0,833,859,1,0,0,0,834,835,10,20,0,0,835,836,5,98,0,0,836,837,5,99,
0,0,837,838,3,150,75,0,838,839,3,120,60,0,839,859,1,0,0,0,840,841,10,19,
0,0,841,842,5,86,0,0,842,843,5,77,0,0,843,859,5,85,0,0,844,845,10,18,0,0,
845,846,5,86,0,0,846,847,3,108,54,0,847,848,5,85,0,0,848,859,1,0,0,0,849,
850,10,14,0,0,850,852,5,45,0,0,851,853,5,49,0,0,852,851,1,0,0,0,852,853,
1,0,0,0,853,854,1,0,0,0,854,859,5,84,0,0,855,856,10,1,0,0,856,857,5,15,0,
0,857,859,3,90,45,0,858,794,1,0,0,0,858,797,1,0,0,0,858,800,1,0,0,0,858,
803,1,0,0,0,858,806,1,0,0,0,858,809,1,0,0,0,858,812,1,0,0,0,858,816,1,0,
0,0,858,822,1,0,0,0,858,825,1,0,0,0,858,829,1,0,0,0,858,834,1,0,0,0,858,
840,1,0,0,0,858,844,1,0,0,0,858,849,1,0,0,0,858,855,1,0,0,0,859,862,1,0,
0,0,860,858,1,0,0,0,860,861,1,0,0,0,861,109,1,0,0,0,862,860,1,0,0,0,863,
864,3,164,82,0,864,865,5,95,0,0,865,866,3,108,54,0,866,111,1,0,0,0,867,868,
5,34,0,0,868,869,3,168,84,0,869,870,3,120,60,0,870,113,1,0,0,0,871,872,5,
18,0,0,872,873,3,142,71,0,873,874,3,120,60,0,874,115,1,0,0,0,875,876,5,73,
0,0,876,877,3,192,96,0,877,878,3,120,60,0,878,117,1,0,0,0,879,880,5,74,0,
0,880,881,3,166,83,0,881,882,3,120,60,0,882,119,1,0,0,0,883,884,5,90,0,0,
884,885,3,122,61,0,885,886,5,89,0,0,886,121,1,0,0,0,887,890,3,126,63,0,888,
889,5,97,0,0,889,891,3,124,62,0,890,888,1,0,0,0,890,891,1,0,0,0,891,895,
1,0,0,0,892,895,3,124,62,0,893,895,1,0,0,0,894,887,1,0,0,0,894,892,1,0,0,
0,894,893,1,0,0,0,895,123,1,0,0,0,896,901,3,130,65,0,897,898,5,97,0,0,898,
900,3,130,65,0,899,897,1,0,0,0,900,903,1,0,0,0,901,899,1,0,0,0,901,902,1,
0,0,0,902,125,1,0,0,0,903,901,1,0,0,0,904,909,3,128,64,0,905,906,5,97,0,
0,906,908,3,128,64,0,907,905,1,0,0,0,908,911,1,0,0,0,909,907,1,0,0,0,909,
910,1,0,0,0,910,127,1,0,0,0,911,909,1,0,0,0,912,913,3,108,54,0,913,129,1,
0,0,0,914,915,3,160,80,0,915,916,5,95,0,0,916,917,3,108,54,0,917,131,1,0,
0,0,918,923,3,134,67,0,919,920,5,97,0,0,920,922,3,134,67,0,921,919,1,0,0,
0,922,925,1,0,0,0,923,921,1,0,0,0,923,924,1,0,0,0,924,927,1,0,0,0,925,923,
1,0,0,0,926,918,1,0,0,0,926,927,1,0,0,0,927,133,1,0,0,0,928,929,3,162,81,
0,929,930,5,95,0,0,930,933,3,90,45,0,931,932,5,100,0,0,932,934,3,108,54,
0,933,931,1,0,0,0,933,934,1,0,0,0,934,135,1,0,0,0,935,936,5,74,0,0,936,956,
3,148,74,0,937,956,3,148,74,0,938,940,5,75,0,0,939,941,3,148,74,0,940,939,
1,0,0,0,940,941,1,0,0,0,941,956,1,0,0,0,942,953,5,27,0,0,943,944,5,99,0,
0,944,947,3,144,72,0,945,946,5,99,0,0,946,948,5,60,0,0,947,945,1,0,0,0,947,
948,1,0,0,0,948,951,1,0,0,0,949,950,5,99,0,0,950,952,3,148,74,0,951,949,
1,0,0,0,951,952,1,0,0,0,952,954,1,0,0,0,953,943,1,0,0,0,953,954,1,0,0,0,
954,956,1,0,0,0,955,935,1,0,0,0,955,937,1,0,0,0,955,938,1,0,0,0,955,942,
1,0,0,0,956,137,1,0,0,0,957,958,5,111,0,0,958,139,1,0,0,0,959,960,5,111,
0,0,960,141,1,0,0,0,961,962,5,111,0,0,962,143,1,0,0,0,963,964,5,111,0,0,
964,145,1,0,0,0,965,966,5,111,0,0,966,147,1,0,0,0,967,968,5,111,0,0,968,
149,1,0,0,0,969,970,5,111,0,0,970,151,1,0,0,0,971,972,5,111,0,0,972,153,
1,0,0,0,973,974,5,111,0,0,974,155,1,0,0,0,975,976,5,111,0,0,976,157,1,0,
0,0,977,978,5,111,0,0,978,159,1,0,0,0,979,980,5,111,0,0,980,161,1,0,0,0,
981,982,5,111,0,0,982,163,1,0,0,0,983,984,7,6,0,0,984,165,1,0,0,0,985,986,
5,111,0,0,986,167,1,0,0,0,987,988,5,111,0,0,988,169,1,0,0,0,989,990,5,111,
0,0,990,171,1,0,0,0,991,992,7,7,0,0,992,173,1,0,0,0,993,994,3,176,88,0,994,
995,5,0,0,1,995,175,1,0,0,0,996,997,3,182,91,0,997,1004,3,178,89,0,998,999,
5,108,0,0,999,1000,3,182,91,0,1000,1001,3,178,89,0,1001,1003,1,0,0,0,1002,
998,1,0,0,0,1003,1006,1,0,0,0,1004,1002,1,0,0,0,1004,1005,1,0,0,0,1005,177,
1,0,0,0,1006,1004,1,0,0,0,1007,1010,5,98,0,0,1008,1010,1,0,0,0,1009,1007,
1,0,0,0,1009,1008,1,0,0,0,1010,179,1,0,0,0,1011,1012,7,8,0,0,1012,181,1,
0,0,0,1013,1014,6,91,-1,0,1014,1015,5,90,0,0,1015,1016,3,176,88,0,1016,1017,
5,89,0,0,1017,1069,1,0,0,0,1018,1069,3,184,92,0,1019,1025,5,92,0,0,1020,
1021,3,186,93,0,1021,1022,3,180,90,0,1022,1024,1,0,0,0,1023,1020,1,0,0,0,
1024,1027,1,0,0,0,1025,1023,1,0,0,0,1025,1026,1,0,0,0,1026,1031,1,0,0,0,
1027,1025,1,0,0,0,1028,1029,3,188,94,0,1029,1030,3,180,90,0,1030,1032,1,
0,0,0,1031,1028,1,0,0,0,1031,1032,1,0,0,0,1032,1038,1,0,0,0,1033,1034,3,
186,93,0,1034,1035,3,180,90,0,1035,1037,1,0,0,0,1036,1033,1,0,0,0,1037,1040,
1,0,0,0,1038,1036,1,0,0,0,1038,1039,1,0,0,0,1039,1041,1,0,0,0,1040,1038,
1,0,0,0,1041,1069,5,91,0,0,1042,1044,5,86,0,0,1043,1045,3,176,88,0,1044,
1043,1,0,0,0,1044,1045,1,0,0,0,1045,1050,1,0,0,0,1046,1047,5,97,0,0,1047,
1049,3,176,88,0,1048,1046,1,0,0,0,1049,1052,1,0,0,0,1050,1048,1,0,0,0,1050,
1051,1,0,0,0,1051,1053,1,0,0,0,1052,1050,1,0,0,0,1053,1069,5,85,0,0,1054,
1056,5,88,0,0,1055,1057,3,176,88,0,1056,1055,1,0,0,0,1056,1057,1,0,0,0,1057,
1062,1,0,0,0,1058,1059,5,108,0,0,1059,1061,3,176,88,0,1060,1058,1,0,0,0,
1061,1064,1,0,0,0,1062,1060,1,0,0,0,1062,1063,1,0,0,0,1063,1065,1,0,0,0,
1064,1062,1,0,0,0,1065,1069,5,87,0,0,1066,1069,5,7,0,0,1067,1069,3,192,96,
0,1068,1013,1,0,0,0,1068,1018,1,0,0,0,1068,1019,1,0,0,0,1068,1042,1,0,0,
0,1068,1054,1,0,0,0,1068,1066,1,0,0,0,1068,1067,1,0,0,0,1069,1078,1,0,0,
0,1070,1072,10,5,0,0,1071,1073,5,98,0,0,1072,1071,1,0,0,0,1072,1073,1,0,
0,0,1073,1074,1,0,0,0,1074,1075,5,86,0,0,1075,1077,5,85,0,0,1076,1070,1,
0,0,0,1077,1080,1,0,0,0,1078,1076,1,0,0,0,1078,1079,1,0,0,0,1079,183,1,0,
0,0,1080,1078,1,0,0,0,1081,1087,5,83,0,0,1082,1087,5,80,0,0,1083,1087,5,
81,0,0,1084,1087,5,82,0,0,1085,1087,5,79,0,0,1086,1081,1,0,0,0,1086,1082,
1,0,0,0,1086,1083,1,0,0,0,1086,1084,1,0,0,0,1086,1085,1,0,0,0,1087,185,1,
0,0,0,1088,1090,3,164,82,0,1089,1091,5,98,0,0,1090,1089,1,0,0,0,1090,1091,
1,0,0,0,1091,1092,1,0,0,0,1092,1093,3,190,95,0,1093,187,1,0,0,0,1094,1095,
5,86,0,0,1095,1096,5,111,0,0,1096,1097,5,95,0,0,1097,1098,5,82,0,0,1098,
1099,5,85,0,0,1099,1100,3,190,95,0,1100,189,1,0,0,0,1101,1102,5,95,0,0,1102,
1103,3,176,88,0,1103,191,1,0,0,0,1104,1105,5,111,0,0,1105,193,1,0,0,0,111,
195,209,215,220,224,233,235,244,257,265,272,279,295,302,306,319,323,330,
332,335,338,342,344,347,361,363,366,369,373,375,378,391,402,410,417,423,
429,432,447,454,460,470,474,477,480,487,493,498,507,515,525,539,543,553,
557,566,569,571,582,589,594,610,627,636,641,647,652,656,670,686,691,697,
717,725,739,741,763,773,776,781,787,792,852,858,860,890,894,901,909,923,
926,933,940,947,951,953,955,1004,1009,1025,1031,1038,1044,1050,1056,1062,
1068,1072,1078,1086,1090];


const atn = new antlr4.atn.ATNDeserializer().deserialize(serializedATN);

const decisionsToDFA = atn.decisionToState.map( (ds, index) => new antlr4.dfa.DFA(ds, index) );

const sharedContextCache = new antlr4.atn.PredictionContextCache();

export default class graphParser extends antlr4.Parser {

    static grammarFileName = "graph.g4";
    static literalNames = [ null, "'from'", "'starting'", "'exiting'", "'opened'", 
                            "'blockMessage'", "'amdVoice'", null, "'+'", 
                            "'-'", "'*'", "'/'", "'%'", "'apply'", "'with'", 
                            "'as'", "'async'", "'block'", "'blockcall'", 
                            "'catch'", "'comment'", "'conditions'", "'confident'", 
                            "'context'", "'cow'", "'default'", "'defined'", 
                            "'digression'", "'digressions'", "'disable'", 
                            "'do'", "'done'", "'else'", "'enable'", "'external'", 
                            "'exit'", "'finally'", "'for'", "'function'", 
                            "'global'", "'goto'", "'if'", "'import'", "'in'", 
                            "'input'", "'is'", "'library'", "'logexpr'", 
                            "'node'", "'not'", "'of'", "'on'", "'ondigreturn'", 
                            "'onexit'", "'output'", "'preprocessor'", "'priority'", 
                            "'return'", "'save'", "'set'", "'shared'", "'start'", 
                            "'static'", "'sync'", "'system'", "'timeout'", 
                            "'transitions'", "'tags'", "'try'", "'type'", 
                            "'var'", "'wait'", "'when'", "'fill'", "'#'", 
                            "'$'", null, null, null, "'empty'", "'number'", 
                            "'boolean'", "'string'", "'unknown'", "'null'", 
                            "']'", "'['", "'>'", "'<'", "')'", "'('", "'}'", 
                            "'{'", "'\"'", "'`'", "':'", "';'", "','", "'?'", 
                            "'.'", "'='", "'+='", "'-='", "'=='", "'!='", 
                            "'>='", "'<='", null, "'|'" ];
    static symbolicNames = [ null, null, null, null, null, null, null, "StringLiteral", 
                             "PLUS", "MINUS", "STAR", "SLASH", "PERCENT", 
                             "APPLY", "WITH", "AS", "ASYNC", "BLOCK", "BLOCKCALL", 
                             "CATCH", "COMMENT", "CONDITIONS", "CONFIDENT", 
                             "CONTEXT", "COW", "DEFAULT", "DEFINED", "DIGRESSION", 
                             "DIGRESSIONS", "DISABLE", "DO", "DONE", "ELSE", 
                             "ENABLE", "EXTERNAL", "EXIT", "FINALLY", "FOR", 
                             "FUNCTION", "GLOBAL", "GOTO", "IF", "IMPORT", 
                             "IN", "INPUT", "IS", "LIBRARY", "LOGEXPR", 
                             "NODE", "NOT", "OF", "ON", "ONDIGRETURN", "ONEXIT", 
                             "OUTPUT", "PREPROCESSOR", "PRIORITY", "RETURN", 
                             "SAVE", "SET", "SHARED", "START", "STATIC", 
                             "SYNC", "SYSTEM", "TIMEOUT", "TRANSITIONS", 
                             "TAGS", "TRY", "TYPE", "VAR", "WAIT", "WHEN", 
                             "FILL", "BuiltinMark", "ContextMark", "DecimalLiteral", 
                             "DecimalIntegerLiteral", "BooleanLiteral", 
                             "Empty", "Number", "Boolean", "String", "Unknown", 
                             "NULL", "RBRACKET", "LBRACKET", "RANGLE", "LANGLE", 
                             "RPAREN", "LPAREN", "RCURL", "LCURL", "QUOTE", 
                             "BACKTRICK", "COLON", "SEMICOLON", "COMMA", 
                             "QMARK", "PERIOD", "EQUALSIGN", "AddOp", "RemoveOp", 
                             "EqualOp", "NotEqualOp", "GreaterEqualOp", 
                             "LessEqualOp", "OrOp", "TypeOr", "AndOp", "NotOperator", 
                             "ID", "LineComment", "DocsComment", "BlockComment", 
                             "Whitespace" ];
    static ruleNames = [ "file", "referenceFile", "expressionFile", "blockContent", 
                         "systemDescription", "systemElementDescription", 
                         "contextDescription", "contextElementDescription", 
                         "externalFunctionDeclaration", "importDefinition", 
                         "whenDescription", "typeDefinition", "functionDescription", 
                         "contextFunctionDescription", "blockDeclaration", 
                         "nodeDeclaration", "digressionDeclaration", "digressionContent", 
                         "comment", "nodeContent", "nodeOnExitSection", 
                         "nodeOnExitSectionItem", "onExitDoWithConfident", 
                         "nodeOnDigressionReturnSection", "nodeTransitionSection", 
                         "namedTransitionDefinition", "transitionDefinition", 
                         "transitionDefinitionOnMessage", "timeoutTransitionDefinition", 
                         "conditionalTransitionDefinition", "unconditionalTransitionDefinition", 
                         "doSection", "blockBody", "oneOrGroupedCommand", 
                         "nodeCommand", "cycleCommand", "statement", "statementBody", 
                         "logExpressionTypeCommand", "digressionControl", 
                         "digressionSpec", "exitCommand", "returnCommand", 
                         "variableDefinition", "variableDeclaration", "typeDescription", 
                         "conditionalCommand", "foreachCommand", "waitCommand", 
                         "activatedTransitionList", "gotoCommand", "setCommand", 
                         "assignOpCommand", "lValue", "expression", "objectPropertyExpressionPart", 
                         "externalFunctionCall", "blockFunctionCall", "slotFillingCall", 
                         "builtinFunctionCall", "argumentInvokation", "argumentList", 
                         "namedArgumentList", "positionalArgumentList", 
                         "positionalArgument", "namedArgument", "parameterList", 
                         "parameter", "reference", "whenId", "nodeId", "blockId", 
                         "digressionId", "transitionName", "referenceName", 
                         "memberName", "tagName", "factName", "fieldName", 
                         "variableName", "argumentName", "parameterName", 
                         "propertyName", "builtinFunctionName", "externalFunctionName", 
                         "functionName", "anyNumber", "typeEof", "type_", 
                         "questionMarkOptional", "objectTypeElementSeparator", 
                         "nonNullableType", "predefinedType", "propertySignature", 
                         "indexSignature", "typeAnnotation", "typeAlias" ];

    constructor(input) {
        super(input);
        this._interp = new antlr4.atn.ParserATNSimulator(this, atn, decisionsToDFA, sharedContextCache);
        this.ruleNames = graphParser.ruleNames;
        this.literalNames = graphParser.literalNames;
        this.symbolicNames = graphParser.symbolicNames;
    }

    sempred(localctx, ruleIndex, predIndex) {
    	switch(ruleIndex) {
    	case 53:
    	    		return this.lValue_sempred(localctx, predIndex);
    	case 54:
    	    		return this.expression_sempred(localctx, predIndex);
    	case 91:
    	    		return this.nonNullableType_sempred(localctx, predIndex);
        default:
            throw "No predicate with index:" + ruleIndex;
       }
    }

    lValue_sempred(localctx, predIndex) {
    	switch(predIndex) {
    		case 0:
    			return this.precpred(this._ctx, 3);
    		case 1:
    			return this.precpred(this._ctx, 2);
    		case 2:
    			return this.precpred(this._ctx, 1);
    		default:
    			throw "No predicate with index:" + predIndex;
    	}
    };

    expression_sempred(localctx, predIndex) {
    	switch(predIndex) {
    		case 3:
    			return this.precpred(this._ctx, 12);
    		case 4:
    			return this.precpred(this._ctx, 11);
    		case 5:
    			return this.precpred(this._ctx, 10);
    		case 6:
    			return this.precpred(this._ctx, 9);
    		case 7:
    			return this.precpred(this._ctx, 8);
    		case 8:
    			return this.precpred(this._ctx, 7);
    		case 9:
    			return this.precpred(this._ctx, 6);
    		case 10:
    			return this.precpred(this._ctx, 5);
    		case 11:
    			return this.precpred(this._ctx, 23);
    		case 12:
    			return this.precpred(this._ctx, 22);
    		case 13:
    			return this.precpred(this._ctx, 21);
    		case 14:
    			return this.precpred(this._ctx, 20);
    		case 15:
    			return this.precpred(this._ctx, 19);
    		case 16:
    			return this.precpred(this._ctx, 18);
    		case 17:
    			return this.precpred(this._ctx, 14);
    		case 18:
    			return this.precpred(this._ctx, 1);
    		default:
    			throw "No predicate with index:" + predIndex;
    	}
    };

    nonNullableType_sempred(localctx, predIndex) {
    	switch(predIndex) {
    		case 19:
    			return this.precpred(this._ctx, 5);
    		default:
    			throw "No predicate with index:" + predIndex;
    	}
    };




	file() {
	    let localctx = new FileContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 0, graphParser.RULE_file);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 195;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        if(_la===46) {
	            this.state = 194;
	            this.match(graphParser.LIBRARY);
	        }

	        this.state = 197;
	        localctx.mainBlock = this.blockContent();
	        this.state = 198;
	        this.match(graphParser.EOF);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	referenceFile() {
	    let localctx = new ReferenceFileContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 2, graphParser.RULE_referenceFile);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 200;
	        this.reference();
	        this.state = 201;
	        this.match(graphParser.EOF);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	expressionFile() {
	    let localctx = new ExpressionFileContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 4, graphParser.RULE_expressionFile);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 203;
	        this.expression(0);
	        this.state = 204;
	        this.match(graphParser.EOF);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	blockContent() {
	    let localctx = new BlockContentContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 6, graphParser.RULE_blockContent);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 209;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        while(_la===42) {
	            this.state = 206;
	            localctx._importDefinition = this.importDefinition();
	            localctx.imports.push(localctx._importDefinition);
	            this.state = 211;
	            this._errHandler.sync(this);
	            _la = this._input.LA(1);
	        }
	        this.state = 215;
	        this._errHandler.sync(this);
	        var _alt = this._interp.adaptivePredict(this._input,2,this._ctx)
	        while(_alt!=2 && _alt!=antlr4.atn.ATN.INVALID_ALT_NUMBER) {
	            if(_alt===1) {
	                this.state = 212;
	                localctx._typeDefinition = this.typeDefinition();
	                localctx.types.push(localctx._typeDefinition); 
	            }
	            this.state = 217;
	            this._errHandler.sync(this);
	            _alt = this._interp.adaptivePredict(this._input,2,this._ctx);
	        }

	        this.state = 220;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        if(_la===23) {
	            this.state = 218;
	            this.match(graphParser.CONTEXT);
	            this.state = 219;
	            localctx.contextType = this.contextDescription();
	        }

	        this.state = 224;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        if(_la===64) {
	            this.state = 222;
	            this.match(graphParser.SYSTEM);
	            this.state = 223;
	            localctx.system = this.systemDescription();
	        }

	        this.state = 235;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        while(((((_la - 16)) & ~0x1f) === 0 && ((1 << (_la - 16)) & 12847107) !== 0) || ((((_la - 48)) & ~0x1f) === 0 && ((1 << (_la - 48)) & 18915457) !== 0) || ((((_la - 112)) & ~0x1f) === 0 && ((1 << (_la - 112)) & 7) !== 0)) {
	            this.state = 233;
	            this._errHandler.sync(this);
	            switch(this._input.LA(1)) {
	            case 34:
	                this.state = 226;
	                localctx._externalFunctionDeclaration = this.externalFunctionDeclaration();
	                localctx.externalFunctions.push(localctx._externalFunctionDeclaration);
	                break;
	            case 69:
	                this.state = 227;
	                localctx._typeDefinition = this.typeDefinition();
	                localctx.types.push(localctx._typeDefinition);
	                break;
	            case 48:
	            case 61:
	                this.state = 228;
	                localctx._nodeDeclaration = this.nodeDeclaration();
	                localctx.nodes.push(localctx._nodeDeclaration);
	                break;
	            case 16:
	            case 17:
	            case 63:
	                this.state = 229;
	                localctx._blockDeclaration = this.blockDeclaration();
	                localctx.blocks.push(localctx._blockDeclaration);
	                break;
	            case 27:
	            case 39:
	            case 55:
	                this.state = 230;
	                localctx._digressionDeclaration = this.digressionDeclaration();
	                localctx.digressions.push(localctx._digressionDeclaration);
	                break;
	            case 38:
	            case 112:
	            case 113:
	            case 114:
	                this.state = 231;
	                localctx._contextFunctionDescription = this.contextFunctionDescription();
	                localctx.contextFunctions.push(localctx._contextFunctionDescription);
	                break;
	            case 72:
	                this.state = 232;
	                localctx._whenDescription = this.whenDescription();
	                localctx.whenDescriptions.push(localctx._whenDescription);
	                break;
	            default:
	                throw new antlr4.error.NoViableAltException(this);
	            }
	            this.state = 237;
	            this._errHandler.sync(this);
	            _la = this._input.LA(1);
	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	systemDescription() {
	    let localctx = new SystemDescriptionContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 8, graphParser.RULE_systemDescription);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 238;
	        this.match(graphParser.LCURL);
	        this.state = 244;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        while(_la===111) {
	            this.state = 239;
	            localctx._systemElementDescription = this.systemElementDescription();
	            localctx.elements.push(localctx._systemElementDescription);
	            this.state = 240;
	            this.objectTypeElementSeparator();
	            this.state = 246;
	            this._errHandler.sync(this);
	            _la = this._input.LA(1);
	        }
	        this.state = 247;
	        this.match(graphParser.RCURL);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	systemElementDescription() {
	    let localctx = new SystemElementDescriptionContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 10, graphParser.RULE_systemElementDescription);
	    try {
	        this.state = 257;
	        this._errHandler.sync(this);
	        var la_ = this._interp.adaptivePredict(this._input,8,this._ctx);
	        switch(la_) {
	        case 1:
	            this.enterOuterAlt(localctx, 1);
	            this.state = 249;
	            localctx.name = this.variableName();
	            this.state = 250;
	            this.match(graphParser.COLON);
	            this.state = 251;
	            localctx.value = this.expression(0);
	            break;

	        case 2:
	            this.enterOuterAlt(localctx, 2);
	            this.state = 253;
	            localctx.name = this.variableName();
	            this.state = 254;
	            this.match(graphParser.EQUALSIGN);
	            this.state = 255;
	            localctx.value = this.expression(0);
	            break;

	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	contextDescription() {
	    let localctx = new ContextDescriptionContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 12, graphParser.RULE_contextDescription);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 259;
	        this.match(graphParser.LCURL);
	        this.state = 265;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        while(_la===44 || _la===54 || _la===111) {
	            this.state = 260;
	            localctx._contextElementDescription = this.contextElementDescription();
	            localctx.elements.push(localctx._contextElementDescription);
	            this.state = 261;
	            this.objectTypeElementSeparator();
	            this.state = 267;
	            this._errHandler.sync(this);
	            _la = this._input.LA(1);
	        }
	        this.state = 268;
	        this.match(graphParser.RCURL);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	contextElementDescription() {
	    let localctx = new ContextElementDescriptionContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 14, graphParser.RULE_contextElementDescription);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 272;
	        this._errHandler.sync(this);
	        switch (this._input.LA(1)) {
	        case 44:
	        	this.state = 270;
	        	localctx.input = this.match(graphParser.INPUT);
	        	break;
	        case 54:
	        	this.state = 271;
	        	localctx.output = this.match(graphParser.OUTPUT);
	        	break;
	        case 111:
	        	break;
	        default:
	        	break;
	        }
	        this.state = 274;
	        localctx.name = this.variableName();
	        this.state = 275;
	        this.match(graphParser.COLON);
	        this.state = 276;
	        localctx.type = this.typeDescription();
	        this.state = 279;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        if(_la===100) {
	            this.state = 277;
	            this.match(graphParser.EQUALSIGN);
	            this.state = 278;
	            localctx.value = this.expression(0);
	        }

	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	externalFunctionDeclaration() {
	    let localctx = new ExternalFunctionDeclarationContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 16, graphParser.RULE_externalFunctionDeclaration);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 281;
	        this.match(graphParser.EXTERNAL);
	        this.state = 282;
	        this.match(graphParser.FUNCTION);
	        this.state = 283;
	        localctx.name = this.externalFunctionName();
	        this.state = 284;
	        this.match(graphParser.LPAREN);
	        this.state = 285;
	        localctx.parameters = this.parameterList();
	        this.state = 286;
	        this.match(graphParser.RPAREN);
	        this.state = 287;
	        this.match(graphParser.COLON);
	        this.state = 288;
	        localctx.returnType = this.typeDescription();
	        this.state = 289;
	        this.match(graphParser.SEMICOLON);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	importDefinition() {
	    let localctx = new ImportDefinitionContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 18, graphParser.RULE_importDefinition);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 291;
	        this.match(graphParser.IMPORT);
	        this.state = 292;
	        localctx.path = this.match(graphParser.StringLiteral);
	        this.state = 295;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        if(_la===1) {
	            this.state = 293;
	            this.match(graphParser.T__0);
	            this.state = 294;
	            localctx.packageName = this.match(graphParser.StringLiteral);
	        }

	        this.state = 297;
	        this.match(graphParser.SEMICOLON);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	whenDescription() {
	    let localctx = new WhenDescriptionContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 20, graphParser.RULE_whenDescription);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 299;
	        this.match(graphParser.WHEN);
	        this.state = 300;
	        localctx.event = this._input.LT(1);
	        _la = this._input.LA(1);
	        if(!((((_la) & ~0x1f) === 0 && ((1 << _la) & 124) !== 0))) {
	            localctx.event = this._errHandler.recoverInline(this);
	        }
	        else {
	        	this._errHandler.reportMatch(this);
	            this.consume();
	        }
	        this.state = 302;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        if(_la===111) {
	            this.state = 301;
	            localctx.name = this.whenId();
	        }

	        this.state = 306;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        if(_la===56) {
	            this.state = 304;
	            this.match(graphParser.PRIORITY);
	            this.state = 305;
	            localctx.priority = this.match(graphParser.DecimalIntegerLiteral);
	        }

	        this.state = 308;
	        localctx.doSec = this.doSection();
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	typeDefinition() {
	    let localctx = new TypeDefinitionContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 22, graphParser.RULE_typeDefinition);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 310;
	        this.match(graphParser.TYPE);
	        this.state = 311;
	        this.typeAlias();
	        this.state = 312;
	        this.match(graphParser.EQUALSIGN);
	        this.state = 313;
	        this.typeDescription();
	        this.state = 323;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        if(_la===14) {
	            this.state = 314;
	            this.match(graphParser.WITH);
	            this.state = 315;
	            this.match(graphParser.LCURL);
	            this.state = 319;
	            this._errHandler.sync(this);
	            _la = this._input.LA(1);
	            while(((((_la - 111)) & ~0x1f) === 0 && ((1 << (_la - 111)) & 15) !== 0)) {
	                this.state = 316;
	                localctx._functionDescription = this.functionDescription();
	                localctx.functions.push(localctx._functionDescription);
	                this.state = 321;
	                this._errHandler.sync(this);
	                _la = this._input.LA(1);
	            }
	            this.state = 322;
	            this.match(graphParser.RCURL);
	        }

	        this.state = 325;
	        this.match(graphParser.SEMICOLON);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	functionDescription() {
	    let localctx = new FunctionDescriptionContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 24, graphParser.RULE_functionDescription);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.enableChannel(2);
	        this.state = 335;
	        this._errHandler.sync(this);
	        var la_ = this._interp.adaptivePredict(this._input,19,this._ctx);
	        if(la_===1) {
	            this.state = 332;
	            this._errHandler.sync(this);
	            var _alt = this._interp.adaptivePredict(this._input,18,this._ctx)
	            while(_alt!=2 && _alt!=antlr4.atn.ATN.INVALID_ALT_NUMBER) {
	                if(_alt===1) {
	                    this.state = 330;
	                    this._errHandler.sync(this);
	                    switch(this._input.LA(1)) {
	                    case 112:
	                        this.state = 328;
	                        localctx._LineComment = this.match(graphParser.LineComment);
	                        localctx.comments.push(localctx._LineComment);
	                        break;
	                    case 114:
	                        this.state = 329;
	                        this.match(graphParser.BlockComment);
	                        break;
	                    default:
	                        throw new antlr4.error.NoViableAltException(this);
	                    } 
	                }
	                this.state = 334;
	                this._errHandler.sync(this);
	                _alt = this._interp.adaptivePredict(this._input,18,this._ctx);
	            }


	        }
	        this.state = 338;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        if(_la===113) {
	            this.state = 337;
	            localctx.doc = this.match(graphParser.DocsComment);
	        }

	        this.state = 347;
	        this._errHandler.sync(this);
	        var la_ = this._interp.adaptivePredict(this._input,23,this._ctx);
	        if(la_===1) {
	            this.state = 344;
	            this._errHandler.sync(this);
	            _la = this._input.LA(1);
	            while(_la===112 || _la===114) {
	                this.state = 342;
	                this._errHandler.sync(this);
	                switch(this._input.LA(1)) {
	                case 112:
	                    this.state = 340;
	                    localctx._LineComment = this.match(graphParser.LineComment);
	                    localctx.comments.push(localctx._LineComment);
	                    break;
	                case 114:
	                    this.state = 341;
	                    this.match(graphParser.BlockComment);
	                    break;
	                default:
	                    throw new antlr4.error.NoViableAltException(this);
	                }
	                this.state = 346;
	                this._errHandler.sync(this);
	                _la = this._input.LA(1);
	            }

	        }
	        this.disableChannel(2);
	        this.state = 350;
	        localctx.name = this.functionName();
	        this.state = 351;
	        this.match(graphParser.LPAREN);
	        this.state = 352;
	        localctx.parameters = this.parameterList();
	        this.state = 353;
	        this.match(graphParser.RPAREN);
	        this.state = 354;
	        this.match(graphParser.COLON);
	        this.state = 355;
	        localctx.returnType = this.typeDescription();
	        this.state = 356;
	        localctx.body = this.blockBody();
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	contextFunctionDescription() {
	    let localctx = new ContextFunctionDescriptionContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 26, graphParser.RULE_contextFunctionDescription);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.enableChannel(2);
	        this.state = 366;
	        this._errHandler.sync(this);
	        var la_ = this._interp.adaptivePredict(this._input,26,this._ctx);
	        if(la_===1) {
	            this.state = 363;
	            this._errHandler.sync(this);
	            var _alt = this._interp.adaptivePredict(this._input,25,this._ctx)
	            while(_alt!=2 && _alt!=antlr4.atn.ATN.INVALID_ALT_NUMBER) {
	                if(_alt===1) {
	                    this.state = 361;
	                    this._errHandler.sync(this);
	                    switch(this._input.LA(1)) {
	                    case 112:
	                        this.state = 359;
	                        localctx._LineComment = this.match(graphParser.LineComment);
	                        localctx.comments.push(localctx._LineComment);
	                        break;
	                    case 114:
	                        this.state = 360;
	                        this.match(graphParser.BlockComment);
	                        break;
	                    default:
	                        throw new antlr4.error.NoViableAltException(this);
	                    } 
	                }
	                this.state = 365;
	                this._errHandler.sync(this);
	                _alt = this._interp.adaptivePredict(this._input,25,this._ctx);
	            }


	        }
	        this.state = 369;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        if(_la===113) {
	            this.state = 368;
	            localctx.doc = this.match(graphParser.DocsComment);
	        }

	        this.state = 378;
	        this._errHandler.sync(this);
	        var la_ = this._interp.adaptivePredict(this._input,30,this._ctx);
	        if(la_===1) {
	            this.state = 375;
	            this._errHandler.sync(this);
	            _la = this._input.LA(1);
	            while(_la===112 || _la===114) {
	                this.state = 373;
	                this._errHandler.sync(this);
	                switch(this._input.LA(1)) {
	                case 112:
	                    this.state = 371;
	                    localctx._LineComment = this.match(graphParser.LineComment);
	                    localctx.comments.push(localctx._LineComment);
	                    break;
	                case 114:
	                    this.state = 372;
	                    this.match(graphParser.BlockComment);
	                    break;
	                default:
	                    throw new antlr4.error.NoViableAltException(this);
	                }
	                this.state = 377;
	                this._errHandler.sync(this);
	                _la = this._input.LA(1);
	            }

	        }
	        this.disableChannel(2);
	        this.state = 381;
	        this.match(graphParser.FUNCTION);
	        this.state = 382;
	        localctx.name = this.functionName();
	        this.state = 383;
	        this.match(graphParser.LPAREN);
	        this.state = 384;
	        localctx.parameters = this.parameterList();
	        this.state = 385;
	        this.match(graphParser.RPAREN);
	        this.state = 386;
	        this.match(graphParser.COLON);
	        this.state = 387;
	        localctx.returnType = this.typeDescription();
	        this.state = 388;
	        localctx.body = this.blockBody();
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	blockDeclaration() {
	    let localctx = new BlockDeclarationContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 28, graphParser.RULE_blockDeclaration);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 410;
	        this._errHandler.sync(this);
	        var la_ = this._interp.adaptivePredict(this._input,33,this._ctx);
	        switch(la_) {
	        case 1:
	            this.state = 391;
	            this._errHandler.sync(this);
	            _la = this._input.LA(1);
	            if(_la===63) {
	                this.state = 390;
	                localctx.sync = this.match(graphParser.SYNC);
	            }

	            this.state = 393;
	            this.match(graphParser.BLOCK);
	            this.state = 394;
	            this.blockId();
	            this.state = 395;
	            this.match(graphParser.LPAREN);
	            this.state = 396;
	            this.parameterList();
	            this.state = 397;
	            this.match(graphParser.RPAREN);
	            this.state = 398;
	            this.match(graphParser.COLON);
	            this.state = 399;
	            localctx.returnType = this.typeDescription();
	            break;

	        case 2:
	            this.state = 402;
	            this._errHandler.sync(this);
	            _la = this._input.LA(1);
	            if(_la===16) {
	                this.state = 401;
	                localctx.async = this.match(graphParser.ASYNC);
	            }

	            this.state = 404;
	            this.match(graphParser.BLOCK);
	            this.state = 405;
	            this.blockId();
	            this.state = 406;
	            this.match(graphParser.LPAREN);
	            this.state = 407;
	            this.parameterList();
	            this.state = 408;
	            this.match(graphParser.RPAREN);
	            break;

	        }
	        this.state = 412;
	        this.match(graphParser.LCURL);
	        this.state = 413;
	        localctx.content = this.blockContent();
	        this.state = 414;
	        this.match(graphParser.RCURL);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	nodeDeclaration() {
	    let localctx = new NodeDeclarationContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 30, graphParser.RULE_nodeDeclaration);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 417;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        if(_la===61) {
	            this.state = 416;
	            localctx.start = this.match(graphParser.START);
	        }

	        this.state = 419;
	        this.match(graphParser.NODE);
	        this.state = 420;
	        localctx.name = this.nodeId();
	        this.state = 421;
	        this.match(graphParser.LCURL);
	        this.state = 423;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        if(_la===20) {
	            this.state = 422;
	            this.comment();
	        }

	        this.state = 425;
	        localctx.content = this.nodeContent();
	        this.state = 426;
	        this.match(graphParser.RCURL);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	digressionDeclaration() {
	    let localctx = new DigressionDeclarationContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 32, graphParser.RULE_digressionDeclaration);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 429;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        if(_la===39) {
	            this.state = 428;
	            localctx.global = this.match(graphParser.GLOBAL);
	        }

	        this.state = 432;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        if(_la===55) {
	            this.state = 431;
	            localctx.preprocessor = this.match(graphParser.PREPROCESSOR);
	        }

	        this.state = 434;
	        this.match(graphParser.DIGRESSION);
	        this.state = 435;
	        localctx.name = this.digressionId();
	        this.state = 436;
	        this.match(graphParser.LCURL);
	        this.state = 437;
	        this.digressionContent();
	        this.state = 438;
	        this.match(graphParser.RCURL);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	digressionContent() {
	    let localctx = new DigressionContentContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 34, graphParser.RULE_digressionContent);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 440;
	        this.match(graphParser.CONDITIONS);
	        this.state = 441;
	        this.match(graphParser.LCURL);
	        this.state = 447;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        while(_la===51) {
	            this.state = 442;
	            localctx._transitionDefinitionOnMessage = this.transitionDefinitionOnMessage();
	            localctx.transitions.push(localctx._transitionDefinitionOnMessage);
	            this.state = 443;
	            this.match(graphParser.SEMICOLON);
	            this.state = 449;
	            this._errHandler.sync(this);
	            _la = this._input.LA(1);
	        }
	        this.state = 450;
	        this.match(graphParser.RCURL);
	        this.state = 460;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        while(_la===60 || _la===70) {
	            this.state = 454;
	            this._errHandler.sync(this);
	            switch(this._input.LA(1)) {
	            case 60:
	                this.state = 451;
	                this.match(graphParser.SHARED);
	                this.state = 452;
	                localctx._variableDefinition = this.variableDefinition();
	                localctx.sharedState.push(localctx._variableDefinition);
	                break;
	            case 70:
	                this.state = 453;
	                localctx._variableDefinition = this.variableDefinition();
	                localctx.copiedState.push(localctx._variableDefinition);
	                break;
	            default:
	                throw new antlr4.error.NoViableAltException(this);
	            }
	            this.state = 456;
	            this.match(graphParser.SEMICOLON);
	            this.state = 462;
	            this._errHandler.sync(this);
	            _la = this._input.LA(1);
	        }
	        this.state = 463;
	        this.nodeContent();
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	comment() {
	    let localctx = new CommentContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 36, graphParser.RULE_comment);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 465;
	        this.match(graphParser.COMMENT);
	        this.state = 466;
	        this.match(graphParser.StringLiteral);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	nodeContent() {
	    let localctx = new NodeContentContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 38, graphParser.RULE_nodeContent);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 468;
	        localctx.doSec = this.doSection();
	        this.state = 470;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        if(_la===66) {
	            this.state = 469;
	            localctx.transitions = this.nodeTransitionSection();
	        }

	        this.state = 474;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        if(_la===55) {
	            this.state = 472;
	            this.match(graphParser.PREPROCESSOR);
	            this.state = 473;
	            localctx.preExit = this.doSection();
	        }

	        this.state = 477;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        if(_la===53) {
	            this.state = 476;
	            localctx.onExit = this.nodeOnExitSection();
	        }

	        this.state = 480;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        if(_la===52) {
	            this.state = 479;
	            this.nodeOnDigressionReturnSection();
	        }

	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	nodeOnExitSection() {
	    let localctx = new NodeOnExitSectionContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 40, graphParser.RULE_nodeOnExitSection);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 482;
	        this.match(graphParser.ONEXIT);
	        this.state = 483;
	        this.match(graphParser.LCURL);
	        this.state = 487;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        while(_la===111) {
	            this.state = 484;
	            this.nodeOnExitSectionItem();
	            this.state = 489;
	            this._errHandler.sync(this);
	            _la = this._input.LA(1);
	        }
	        this.state = 493;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        if(_la===25) {
	            this.state = 490;
	            this.match(graphParser.DEFAULT);
	            this.state = 491;
	            this.match(graphParser.COLON);
	            this.state = 492;
	            localctx.defaultDo = this.onExitDoWithConfident();
	        }

	        this.state = 498;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        if(_la===27) {
	            this.state = 495;
	            this.match(graphParser.DIGRESSION);
	            this.state = 496;
	            this.match(graphParser.COLON);
	            this.state = 497;
	            localctx.digressionDo = this.onExitDoWithConfident();
	        }

	        this.state = 500;
	        this.match(graphParser.RCURL);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	nodeOnExitSectionItem() {
	    let localctx = new NodeOnExitSectionItemContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 42, graphParser.RULE_nodeOnExitSectionItem);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 505; 
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        do {
	            this.state = 502;
	            this.transitionName();
	            this.state = 503;
	            this.match(graphParser.COLON);
	            this.state = 507; 
	            this._errHandler.sync(this);
	            _la = this._input.LA(1);
	        } while(_la===111);
	        this.state = 509;
	        this.onExitDoWithConfident();
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	onExitDoWithConfident() {
	    let localctx = new OnExitDoWithConfidentContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 44, graphParser.RULE_onExitDoWithConfident);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 511;
	        localctx.doSec = this.doSection();
	        this.state = 515;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        if(_la===51) {
	            this.state = 512;
	            this.match(graphParser.ON);
	            this.state = 513;
	            this.match(graphParser.CONFIDENT);
	            this.state = 514;
	            localctx.confidentDo = this.doSection();
	        }

	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	nodeOnDigressionReturnSection() {
	    let localctx = new NodeOnDigressionReturnSectionContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 46, graphParser.RULE_nodeOnDigressionReturnSection);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 517;
	        this.match(graphParser.ONDIGRETURN);
	        this.state = 518;
	        this.blockBody();
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	nodeTransitionSection() {
	    let localctx = new NodeTransitionSectionContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 48, graphParser.RULE_nodeTransitionSection);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 520;
	        this.match(graphParser.TRANSITIONS);
	        this.state = 521;
	        this.match(graphParser.LCURL);
	        this.state = 525;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        while(_la===111) {
	            this.state = 522;
	            localctx._namedTransitionDefinition = this.namedTransitionDefinition();
	            localctx.list.push(localctx._namedTransitionDefinition);
	            this.state = 527;
	            this._errHandler.sync(this);
	            _la = this._input.LA(1);
	        }
	        this.state = 528;
	        this.match(graphParser.RCURL);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	namedTransitionDefinition() {
	    let localctx = new NamedTransitionDefinitionContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 50, graphParser.RULE_namedTransitionDefinition);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 530;
	        this.transitionName();
	        this.state = 531;
	        this.match(graphParser.COLON);
	        this.state = 532;
	        this.match(graphParser.GOTO);
	        this.state = 533;
	        localctx.target = this.nodeId();
	        this.state = 534;
	        this.transitionDefinition();
	        this.state = 535;
	        this.match(graphParser.SEMICOLON);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	transitionDefinition() {
	    let localctx = new TransitionDefinitionContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 52, graphParser.RULE_transitionDefinition);
	    try {
	        this.state = 539;
	        this._errHandler.sync(this);
	        switch(this._input.LA(1)) {
	        case 51:
	            this.enterOuterAlt(localctx, 1);
	            this.state = 537;
	            this.transitionDefinitionOnMessage();
	            break;
	        case 96:
	            this.enterOuterAlt(localctx, 2);
	            this.state = 538;
	            this.unconditionalTransitionDefinition();
	            break;
	        default:
	            throw new antlr4.error.NoViableAltException(this);
	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	transitionDefinitionOnMessage() {
	    let localctx = new TransitionDefinitionOnMessageContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 54, graphParser.RULE_transitionDefinitionOnMessage);
	    try {
	        this.state = 543;
	        this._errHandler.sync(this);
	        var la_ = this._interp.adaptivePredict(this._input,52,this._ctx);
	        switch(la_) {
	        case 1:
	            this.enterOuterAlt(localctx, 1);
	            this.state = 541;
	            this.conditionalTransitionDefinition();
	            break;

	        case 2:
	            this.enterOuterAlt(localctx, 2);
	            this.state = 542;
	            this.timeoutTransitionDefinition();
	            break;

	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	timeoutTransitionDefinition() {
	    let localctx = new TimeoutTransitionDefinitionContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 56, graphParser.RULE_timeoutTransitionDefinition);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 545;
	        this.match(graphParser.ON);
	        this.state = 546;
	        this.match(graphParser.TIMEOUT);
	        this.state = 547;
	        localctx.timeout = this.anyNumber();
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	conditionalTransitionDefinition() {
	    let localctx = new ConditionalTransitionDefinitionContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 58, graphParser.RULE_conditionalTransitionDefinition);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 549;
	        this.match(graphParser.ON);
	        this.state = 550;
	        localctx.condition = this.expression(0);
	        this.state = 553;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        if(_la===56) {
	            this.state = 551;
	            this.match(graphParser.PRIORITY);
	            this.state = 552;
	            localctx.priority = this.match(graphParser.DecimalIntegerLiteral);
	        }

	        this.state = 557;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        if(_la===72) {
	            this.state = 555;
	            this.match(graphParser.WHEN);
	            this.state = 556;
	            localctx.confident = this.match(graphParser.CONFIDENT);
	        }

	        this.state = 571;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        if(_la===67) {
	            this.state = 559;
	            this.match(graphParser.TAGS);
	            this.state = 560;
	            this.match(graphParser.COLON);
	            this.state = 569;
	            this._errHandler.sync(this);
	            _la = this._input.LA(1);
	            if(_la===111) {
	                this.state = 561;
	                localctx._tagName = this.tagName();
	                localctx.tags.push(localctx._tagName);
	                this.state = 566;
	                this._errHandler.sync(this);
	                _la = this._input.LA(1);
	                while(_la===97) {
	                    this.state = 562;
	                    this.match(graphParser.COMMA);
	                    this.state = 563;
	                    localctx._tagName = this.tagName();
	                    localctx.tags.push(localctx._tagName);
	                    this.state = 568;
	                    this._errHandler.sync(this);
	                    _la = this._input.LA(1);
	                }
	            }

	        }

	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	unconditionalTransitionDefinition() {
	    let localctx = new UnconditionalTransitionDefinitionContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 60, graphParser.RULE_unconditionalTransitionDefinition);
	    try {
	        this.enterOuterAlt(localctx, 1);

	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	doSection() {
	    let localctx = new DoSectionContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 62, graphParser.RULE_doSection);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 575;
	        this.match(graphParser.DO);
	        this.state = 576;
	        this.blockBody();
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	blockBody() {
	    let localctx = new BlockBodyContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 64, graphParser.RULE_blockBody);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 578;
	        this.match(graphParser.LCURL);
	        this.state = 582;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        while((((_la) & ~0x1f) === 0 && ((1 << _la) & 134480768) !== 0) || ((((_la - 34)) & ~0x1f) === 0 && ((1 << (_la - 34)) & 41951435) !== 0) || ((((_la - 70)) & ~0x1f) === 0 && ((1 << (_la - 70)) & 5325307) !== 0) || _la===110 || _la===111) {
	            this.state = 579;
	            localctx._oneOrGroupedCommand = this.oneOrGroupedCommand();
	            localctx.commands.push(localctx._oneOrGroupedCommand);
	            this.state = 584;
	            this._errHandler.sync(this);
	            _la = this._input.LA(1);
	        }
	        this.state = 585;
	        this.match(graphParser.RCURL);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	oneOrGroupedCommand() {
	    let localctx = new OneOrGroupedCommandContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 66, graphParser.RULE_oneOrGroupedCommand);
	    try {
	        this.state = 589;
	        this._errHandler.sync(this);
	        var la_ = this._interp.adaptivePredict(this._input,59,this._ctx);
	        switch(la_) {
	        case 1:
	            this.enterOuterAlt(localctx, 1);
	            this.state = 587;
	            this.nodeCommand();
	            break;

	        case 2:
	            this.enterOuterAlt(localctx, 2);
	            this.state = 588;
	            this.blockBody();
	            break;

	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	nodeCommand() {
	    let localctx = new NodeCommandContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 68, graphParser.RULE_nodeCommand);
	    try {
	        this.state = 594;
	        this._errHandler.sync(this);
	        switch(this._input.LA(1)) {
	        case 7:
	        case 8:
	        case 9:
	        case 18:
	        case 27:
	        case 34:
	        case 35:
	        case 40:
	        case 47:
	        case 57:
	        case 59:
	        case 70:
	        case 71:
	        case 73:
	        case 74:
	        case 75:
	        case 76:
	        case 77:
	        case 78:
	        case 84:
	        case 86:
	        case 90:
	        case 92:
	        case 110:
	        case 111:
	            this.enterOuterAlt(localctx, 1);
	            this.state = 591;
	            this.statement();
	            break;
	        case 41:
	            this.enterOuterAlt(localctx, 2);
	            this.state = 592;
	            this.conditionalCommand();
	            break;
	        case 37:
	            this.enterOuterAlt(localctx, 3);
	            this.state = 593;
	            this.cycleCommand();
	            break;
	        default:
	            throw new antlr4.error.NoViableAltException(this);
	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	cycleCommand() {
	    let localctx = new CycleCommandContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 70, graphParser.RULE_cycleCommand);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 596;
	        this.foreachCommand();
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	statement() {
	    let localctx = new StatementContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 72, graphParser.RULE_statement);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 598;
	        this.statementBody();
	        this.state = 599;
	        this.match(graphParser.SEMICOLON);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	statementBody() {
	    let localctx = new StatementBodyContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 74, graphParser.RULE_statementBody);
	    try {
	        this.state = 610;
	        this._errHandler.sync(this);
	        var la_ = this._interp.adaptivePredict(this._input,61,this._ctx);
	        switch(la_) {
	        case 1:
	            this.enterOuterAlt(localctx, 1);
	            this.state = 601;
	            this.waitCommand();
	            break;

	        case 2:
	            this.enterOuterAlt(localctx, 2);
	            this.state = 602;
	            this.gotoCommand();
	            break;

	        case 3:
	            this.enterOuterAlt(localctx, 3);
	            this.state = 603;
	            this.setCommand();
	            break;

	        case 4:
	            this.enterOuterAlt(localctx, 4);
	            this.state = 604;
	            this.returnCommand();
	            break;

	        case 5:
	            this.enterOuterAlt(localctx, 5);
	            this.state = 605;
	            this.exitCommand();
	            break;

	        case 6:
	            this.enterOuterAlt(localctx, 6);
	            this.state = 606;
	            this.assignOpCommand();
	            break;

	        case 7:
	            this.enterOuterAlt(localctx, 7);
	            this.state = 607;
	            this.variableDefinition();
	            break;

	        case 8:
	            this.enterOuterAlt(localctx, 8);
	            this.state = 608;
	            this.logExpressionTypeCommand();
	            break;

	        case 9:
	            this.enterOuterAlt(localctx, 9);
	            this.state = 609;
	            this.expression(0);
	            break;

	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	logExpressionTypeCommand() {
	    let localctx = new LogExpressionTypeCommandContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 76, graphParser.RULE_logExpressionTypeCommand);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 612;
	        this.match(graphParser.LOGEXPR);
	        this.state = 613;
	        this.expression(0);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	digressionControl() {
	    let localctx = new DigressionControlContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 78, graphParser.RULE_digressionControl);
	    try {
	        this.state = 627;
	        this._errHandler.sync(this);
	        var la_ = this._interp.adaptivePredict(this._input,62,this._ctx);
	        switch(la_) {
	        case 1:
	            localctx = new EnableDigressionContext(this, localctx);
	            this.enterOuterAlt(localctx, 1);
	            this.state = 615;
	            this.match(graphParser.DIGRESSION);
	            this.state = 616;
	            this.match(graphParser.ENABLE);
	            this.state = 617;
	            localctx.spec = this.digressionSpec();
	            break;

	        case 2:
	            localctx = new DisableDigressionContext(this, localctx);
	            this.enterOuterAlt(localctx, 2);
	            this.state = 618;
	            this.match(graphParser.DIGRESSION);
	            this.state = 619;
	            this.match(graphParser.DISABLE);
	            this.state = 620;
	            localctx.spec = this.digressionSpec();
	            break;

	        case 3:
	            localctx = new SaveDigressionContext(this, localctx);
	            this.enterOuterAlt(localctx, 3);
	            this.state = 621;
	            this.match(graphParser.DIGRESSION);
	            this.state = 622;
	            this.match(graphParser.SAVE);
	            this.state = 623;
	            localctx.spec = this.digressionSpec();
	            break;

	        case 4:
	            localctx = new ApplyDigressionContext(this, localctx);
	            this.enterOuterAlt(localctx, 4);
	            this.state = 624;
	            this.match(graphParser.DIGRESSION);
	            this.state = 625;
	            this.match(graphParser.APPLY);
	            this.state = 626;
	            localctx.mask = this.expression(0);
	            break;

	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	digressionSpec() {
	    let localctx = new DigressionSpecContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 80, graphParser.RULE_digressionSpec);
	    var _la = 0;
	    try {
	        this.state = 641;
	        this._errHandler.sync(this);
	        switch(this._input.LA(1)) {
	        case 111:
	            this.enterOuterAlt(localctx, 1);
	            this.state = 629;
	            localctx._digressionId = this.digressionId();
	            localctx.list.push(localctx._digressionId);
	            break;
	        case 92:
	            this.enterOuterAlt(localctx, 2);
	            this.state = 630;
	            this.match(graphParser.LCURL);
	            this.state = 631;
	            localctx._digressionId = this.digressionId();
	            localctx.list.push(localctx._digressionId);
	            this.state = 636;
	            this._errHandler.sync(this);
	            _la = this._input.LA(1);
	            while(_la===97) {
	                this.state = 632;
	                this.match(graphParser.COMMA);
	                this.state = 633;
	                localctx._digressionId = this.digressionId();
	                localctx.list.push(localctx._digressionId);
	                this.state = 638;
	                this._errHandler.sync(this);
	                _la = this._input.LA(1);
	            }
	            this.state = 639;
	            this.match(graphParser.RCURL);
	            break;
	        default:
	            throw new antlr4.error.NoViableAltException(this);
	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	exitCommand() {
	    let localctx = new ExitCommandContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 82, graphParser.RULE_exitCommand);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 643;
	        this.match(graphParser.EXIT);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	returnCommand() {
	    let localctx = new ReturnCommandContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 84, graphParser.RULE_returnCommand);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 645;
	        this.match(graphParser.RETURN);
	        this.state = 647;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        if(((((_la - 7)) & ~0x1f) === 0 && ((1 << (_la - 7)) & 135268359) !== 0) || ((((_la - 73)) & ~0x1f) === 0 && ((1 << (_la - 73)) & 665663) !== 0) || _la===110 || _la===111) {
	            this.state = 646;
	            localctx.returnValue = this.expression(0);
	        }

	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	variableDefinition() {
	    let localctx = new VariableDefinitionContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 86, graphParser.RULE_variableDefinition);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 649;
	        this.variableDeclaration();
	        this.state = 652;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        if(_la===95) {
	            this.state = 650;
	            this.match(graphParser.COLON);
	            this.state = 651;
	            localctx.type = this.typeDescription();
	        }

	        this.state = 656;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        if(_la===100) {
	            this.state = 654;
	            this.match(graphParser.EQUALSIGN);
	            this.state = 655;
	            localctx.value = this.expression(0);
	        }

	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	variableDeclaration() {
	    let localctx = new VariableDeclarationContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 88, graphParser.RULE_variableDeclaration);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 658;
	        this.match(graphParser.VAR);
	        this.state = 659;
	        this.variableName();
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	typeDescription() {
	    let localctx = new TypeDescriptionContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 90, graphParser.RULE_typeDescription);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 661;
	        this.type_();
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	conditionalCommand() {
	    let localctx = new ConditionalCommandContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 92, graphParser.RULE_conditionalCommand);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 663;
	        this.match(graphParser.IF);
	        this.state = 664;
	        this.match(graphParser.LPAREN);
	        this.state = 665;
	        localctx.condition = this.expression(0);
	        this.state = 666;
	        this.match(graphParser.RPAREN);
	        this.state = 667;
	        localctx.thenCommand = this.oneOrGroupedCommand();
	        this.state = 670;
	        this._errHandler.sync(this);
	        var la_ = this._interp.adaptivePredict(this._input,68,this._ctx);
	        if(la_===1) {
	            this.state = 668;
	            this.match(graphParser.ELSE);
	            this.state = 669;
	            localctx.elseCommand = this.oneOrGroupedCommand();

	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	foreachCommand() {
	    let localctx = new ForeachCommandContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 94, graphParser.RULE_foreachCommand);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 672;
	        this.match(graphParser.FOR);
	        this.state = 673;
	        this.match(graphParser.LPAREN);
	        this.state = 674;
	        this.variableDeclaration();
	        this.state = 675;
	        this.match(graphParser.IN);
	        this.state = 676;
	        localctx.container = this.expression(0);
	        this.state = 677;
	        this.match(graphParser.RPAREN);
	        this.state = 678;
	        localctx.body = this.oneOrGroupedCommand();
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	waitCommand() {
	    let localctx = new WaitCommandContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 96, graphParser.RULE_waitCommand);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 680;
	        this.match(graphParser.WAIT);
	        this.state = 681;
	        this.activatedTransitionList();
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	activatedTransitionList() {
	    let localctx = new ActivatedTransitionListContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 98, graphParser.RULE_activatedTransitionList);
	    var _la = 0;
	    try {
	        this.state = 697;
	        this._errHandler.sync(this);
	        switch(this._input.LA(1)) {
	        case 92:
	            localctx = new SelectedActivatedTransitionListContext(this, localctx);
	            this.enterOuterAlt(localctx, 1);
	            this.state = 683;
	            this.match(graphParser.LCURL);
	            this.state = 684;
	            this.transitionName();
	            this.state = 691;
	            this._errHandler.sync(this);
	            _la = this._input.LA(1);
	            while(_la===97 || _la===111) {
	                this.state = 686;
	                this._errHandler.sync(this);
	                _la = this._input.LA(1);
	                if(_la===97) {
	                    this.state = 685;
	                    this.match(graphParser.COMMA);
	                }

	                this.state = 688;
	                this.transitionName();
	                this.state = 693;
	                this._errHandler.sync(this);
	                _la = this._input.LA(1);
	            }
	            this.state = 694;
	            this.match(graphParser.RCURL);
	            break;
	        case 10:
	            localctx = new AllActivatedTransitionListContext(this, localctx);
	            this.enterOuterAlt(localctx, 2);
	            this.state = 696;
	            this.match(graphParser.STAR);
	            break;
	        default:
	            throw new antlr4.error.NoViableAltException(this);
	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	gotoCommand() {
	    let localctx = new GotoCommandContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 100, graphParser.RULE_gotoCommand);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 699;
	        this.match(graphParser.GOTO);
	        this.state = 700;
	        this.transitionName();
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	setCommand() {
	    let localctx = new SetCommandContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 102, graphParser.RULE_setCommand);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 702;
	        this.match(graphParser.SET);
	        this.state = 703;
	        localctx.ref = this.lValue(0);
	        this.state = 704;
	        this.match(graphParser.EQUALSIGN);
	        this.state = 705;
	        localctx.rightPartExpression = this.expression(0);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	assignOpCommand() {
	    let localctx = new AssignOpCommandContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 104, graphParser.RULE_assignOpCommand);
	    try {
	        this.state = 717;
	        this._errHandler.sync(this);
	        var la_ = this._interp.adaptivePredict(this._input,72,this._ctx);
	        switch(la_) {
	        case 1:
	            this.enterOuterAlt(localctx, 1);
	            this.state = 707;
	            this.match(graphParser.SET);
	            this.state = 708;
	            localctx.ref = this.lValue(0);
	            this.state = 709;
	            localctx.op = this.match(graphParser.AddOp);
	            this.state = 710;
	            localctx.rightPartExpression = this.expression(0);
	            break;

	        case 2:
	            this.enterOuterAlt(localctx, 2);
	            this.state = 712;
	            this.match(graphParser.SET);
	            this.state = 713;
	            localctx.ref = this.lValue(0);
	            this.state = 714;
	            localctx.op = this.match(graphParser.RemoveOp);
	            this.state = 715;
	            localctx.rightPartExpression = this.expression(0);
	            break;

	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}


	lValue(_p) {
		if(_p===undefined) {
		    _p = 0;
		}
	    const _parentctx = this._ctx;
	    const _parentState = this.state;
	    let localctx = new LValueContext(this, this._ctx, _parentState);
	    let _prevctx = localctx;
	    const _startState = 106;
	    this.enterRecursionRule(localctx, 106, graphParser.RULE_lValue, _p);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 725;
	        this._errHandler.sync(this);
	        switch(this._input.LA(1)) {
	        case 90:
	            localctx = new LValueParenthesizedExpressionContext(this, localctx);
	            this._ctx = localctx;
	            _prevctx = localctx;

	            this.state = 720;
	            this.match(graphParser.LPAREN);
	            this.state = 721;
	            this.lValue(0);
	            this.state = 722;
	            this.match(graphParser.RPAREN);
	            break;
	        case 27:
	        case 74:
	        case 75:
	        case 111:
	            localctx = new LValueReferenceExpressionContext(this, localctx);
	            this._ctx = localctx;
	            _prevctx = localctx;
	            this.state = 724;
	            this.reference();
	            break;
	        default:
	            throw new antlr4.error.NoViableAltException(this);
	        }
	        this._ctx.stop = this._input.LT(-1);
	        this.state = 741;
	        this._errHandler.sync(this);
	        var _alt = this._interp.adaptivePredict(this._input,75,this._ctx)
	        while(_alt!=2 && _alt!=antlr4.atn.ATN.INVALID_ALT_NUMBER) {
	            if(_alt===1) {
	                if(this._parseListeners!==null) {
	                    this.triggerExitRuleEvent();
	                }
	                _prevctx = localctx;
	                this.state = 739;
	                this._errHandler.sync(this);
	                var la_ = this._interp.adaptivePredict(this._input,74,this._ctx);
	                switch(la_) {
	                case 1:
	                    localctx = new LValueDotAccessExpressionContext(this, new LValueContext(this, _parentctx, _parentState));
	                    localctx.parent = _prevctx;
	                    this.pushNewRecursionContext(localctx, _startState, graphParser.RULE_lValue);
	                    this.state = 727;
	                    if (!( this.precpred(this._ctx, 3))) {
	                        throw new antlr4.error.FailedPredicateException(this, "this.precpred(this._ctx, 3)");
	                    }
	                    this.state = 728;
	                    this.match(graphParser.PERIOD);
	                    this.state = 729;
	                    localctx.member = this.memberName();
	                    break;

	                case 2:
	                    localctx = new LValueLiteralIndexAccessExpressionContext(this, new LValueContext(this, _parentctx, _parentState));
	                    localctx.parent = _prevctx;
	                    this.pushNewRecursionContext(localctx, _startState, graphParser.RULE_lValue);
	                    this.state = 730;
	                    if (!( this.precpred(this._ctx, 2))) {
	                        throw new antlr4.error.FailedPredicateException(this, "this.precpred(this._ctx, 2)");
	                    }
	                    this.state = 731;
	                    this.match(graphParser.LBRACKET);
	                    this.state = 732;
	                    localctx.index = this.match(graphParser.DecimalIntegerLiteral);
	                    this.state = 733;
	                    this.match(graphParser.RBRACKET);
	                    break;

	                case 3:
	                    localctx = new LValueExpressionIndexAccessExpressionContext(this, new LValueContext(this, _parentctx, _parentState));
	                    localctx.parent = _prevctx;
	                    this.pushNewRecursionContext(localctx, _startState, graphParser.RULE_lValue);
	                    this.state = 734;
	                    if (!( this.precpred(this._ctx, 1))) {
	                        throw new antlr4.error.FailedPredicateException(this, "this.precpred(this._ctx, 1)");
	                    }
	                    this.state = 735;
	                    this.match(graphParser.LBRACKET);
	                    this.state = 736;
	                    localctx.index = this.expression(0);
	                    this.state = 737;
	                    this.match(graphParser.RBRACKET);
	                    break;

	                } 
	            }
	            this.state = 743;
	            this._errHandler.sync(this);
	            _alt = this._interp.adaptivePredict(this._input,75,this._ctx);
	        }

	    } catch( error) {
	        if(error instanceof antlr4.error.RecognitionException) {
		        localctx.exception = error;
		        this._errHandler.reportError(this, error);
		        this._errHandler.recover(this, error);
		    } else {
		    	throw error;
		    }
	    } finally {
	        this.unrollRecursionContexts(_parentctx)
	    }
	    return localctx;
	}


	expression(_p) {
		if(_p===undefined) {
		    _p = 0;
		}
	    const _parentctx = this._ctx;
	    const _parentState = this.state;
	    let localctx = new ExpressionContext(this, this._ctx, _parentState);
	    let _prevctx = localctx;
	    const _startState = 108;
	    this.enterRecursionRule(localctx, 108, graphParser.RULE_expression, _p);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 792;
	        this._errHandler.sync(this);
	        var la_ = this._interp.adaptivePredict(this._input,81,this._ctx);
	        switch(la_) {
	        case 1:
	            localctx = new ExternFunctionCallExpressionContext(this, localctx);
	            this._ctx = localctx;
	            _prevctx = localctx;

	            this.state = 745;
	            this.externalFunctionCall();
	            break;

	        case 2:
	            localctx = new BuiltinFunctionCallExpressionContext(this, localctx);
	            this._ctx = localctx;
	            _prevctx = localctx;
	            this.state = 746;
	            this.builtinFunctionCall();
	            break;

	        case 3:
	            localctx = new BlockFunctionCallExpressionContext(this, localctx);
	            this._ctx = localctx;
	            _prevctx = localctx;
	            this.state = 747;
	            this.blockFunctionCall();
	            break;

	        case 4:
	            localctx = new BooleanLiteralExpressionContext(this, localctx);
	            this._ctx = localctx;
	            _prevctx = localctx;
	            this.state = 748;
	            this.match(graphParser.BooleanLiteral);
	            break;

	        case 5:
	            localctx = new SlotFillingCallExpressionContext(this, localctx);
	            this._ctx = localctx;
	            _prevctx = localctx;
	            this.state = 749;
	            this.slotFillingCall();
	            break;

	        case 6:
	            localctx = new NumberLiteralExpressionContext(this, localctx);
	            this._ctx = localctx;
	            _prevctx = localctx;
	            this.state = 750;
	            this.anyNumber();
	            break;

	        case 7:
	            localctx = new StringLiteralExpressionContext(this, localctx);
	            this._ctx = localctx;
	            _prevctx = localctx;
	            this.state = 751;
	            this.match(graphParser.StringLiteral);
	            break;

	        case 8:
	            localctx = new ParenthesizedExpressionContext(this, localctx);
	            this._ctx = localctx;
	            _prevctx = localctx;
	            this.state = 752;
	            this.match(graphParser.LPAREN);
	            this.state = 753;
	            this.expression(0);
	            this.state = 754;
	            this.match(graphParser.RPAREN);
	            break;

	        case 9:
	            localctx = new ReferenceExpressionContext(this, localctx);
	            this._ctx = localctx;
	            _prevctx = localctx;
	            this.state = 756;
	            this.reference();
	            break;

	        case 10:
	            localctx = new DigressionControlExpressionContext(this, localctx);
	            this._ctx = localctx;
	            _prevctx = localctx;
	            this.state = 757;
	            this.digressionControl();
	            break;

	        case 11:
	            localctx = new UnaryOpExpressionContext(this, localctx);
	            this._ctx = localctx;
	            _prevctx = localctx;
	            this.state = 758;
	            localctx.op = this._input.LT(1);
	            _la = this._input.LA(1);
	            if(!(_la===8 || _la===9 || _la===110)) {
	                localctx.op = this._errHandler.recoverInline(this);
	            }
	            else {
	            	this._errHandler.reportMatch(this);
	                this.consume();
	            }
	            this.state = 759;
	            this.expression(15);
	            break;

	        case 12:
	            localctx = new IsDefinedExpressionContext(this, localctx);
	            this._ctx = localctx;
	            _prevctx = localctx;
	            this.state = 760;
	            this.reference();
	            this.state = 761;
	            this.match(graphParser.IS);
	            this.state = 763;
	            this._errHandler.sync(this);
	            _la = this._input.LA(1);
	            if(_la===49) {
	                this.state = 762;
	                this.match(graphParser.NOT);
	            }

	            this.state = 765;
	            this.match(graphParser.DEFINED);
	            break;

	        case 13:
	            localctx = new ObjectLiteralExpressionContext(this, localctx);
	            this._ctx = localctx;
	            _prevctx = localctx;
	            this.state = 767;
	            this.match(graphParser.LCURL);
	            this.state = 776;
	            this._errHandler.sync(this);
	            _la = this._input.LA(1);
	            if(_la===7 || _la===111) {
	                this.state = 768;
	                localctx._objectPropertyExpressionPart = this.objectPropertyExpressionPart();
	                localctx.fields.push(localctx._objectPropertyExpressionPart);
	                this.state = 773;
	                this._errHandler.sync(this);
	                _la = this._input.LA(1);
	                while(_la===97) {
	                    this.state = 769;
	                    this.match(graphParser.COMMA);
	                    this.state = 770;
	                    localctx._objectPropertyExpressionPart = this.objectPropertyExpressionPart();
	                    localctx.fields.push(localctx._objectPropertyExpressionPart);
	                    this.state = 775;
	                    this._errHandler.sync(this);
	                    _la = this._input.LA(1);
	                }
	            }

	            this.state = 778;
	            this.match(graphParser.RCURL);
	            break;

	        case 14:
	            localctx = new TupleLiteralExpressionContext(this, localctx);
	            this._ctx = localctx;
	            _prevctx = localctx;
	            this.state = 779;
	            this.match(graphParser.LBRACKET);
	            this.state = 781;
	            this._errHandler.sync(this);
	            _la = this._input.LA(1);
	            if(((((_la - 7)) & ~0x1f) === 0 && ((1 << (_la - 7)) & 135268359) !== 0) || ((((_la - 73)) & ~0x1f) === 0 && ((1 << (_la - 73)) & 665663) !== 0) || _la===110 || _la===111) {
	                this.state = 780;
	                localctx._expression = this.expression(0);
	                localctx.elements.push(localctx._expression);
	            }

	            this.state = 787;
	            this._errHandler.sync(this);
	            _la = this._input.LA(1);
	            while(_la===97) {
	                this.state = 783;
	                this.match(graphParser.COMMA);
	                this.state = 784;
	                localctx._expression = this.expression(0);
	                localctx.elements.push(localctx._expression);
	                this.state = 789;
	                this._errHandler.sync(this);
	                _la = this._input.LA(1);
	            }
	            this.state = 790;
	            this.match(graphParser.RBRACKET);
	            break;

	        case 15:
	            localctx = new NullLiteralExpressionContext(this, localctx);
	            this._ctx = localctx;
	            _prevctx = localctx;
	            this.state = 791;
	            this.match(graphParser.NULL);
	            break;

	        }
	        this._ctx.stop = this._input.LT(-1);
	        this.state = 860;
	        this._errHandler.sync(this);
	        var _alt = this._interp.adaptivePredict(this._input,84,this._ctx)
	        while(_alt!=2 && _alt!=antlr4.atn.ATN.INVALID_ALT_NUMBER) {
	            if(_alt===1) {
	                if(this._parseListeners!==null) {
	                    this.triggerExitRuleEvent();
	                }
	                _prevctx = localctx;
	                this.state = 858;
	                this._errHandler.sync(this);
	                var la_ = this._interp.adaptivePredict(this._input,83,this._ctx);
	                switch(la_) {
	                case 1:
	                    localctx = new BinaryOpExpressionContext(this, new ExpressionContext(this, _parentctx, _parentState));
	                    localctx.left = _prevctx;
	                    this.pushNewRecursionContext(localctx, _startState, graphParser.RULE_expression);
	                    this.state = 794;
	                    if (!( this.precpred(this._ctx, 12))) {
	                        throw new antlr4.error.FailedPredicateException(this, "this.precpred(this._ctx, 12)");
	                    }
	                    this.state = 795;
	                    localctx.op = this._input.LT(1);
	                    _la = this._input.LA(1);
	                    if(!((((_la) & ~0x1f) === 0 && ((1 << _la) & 7168) !== 0))) {
	                        localctx.op = this._errHandler.recoverInline(this);
	                    }
	                    else {
	                    	this._errHandler.reportMatch(this);
	                        this.consume();
	                    }
	                    this.state = 796;
	                    localctx.right = this.expression(13);
	                    break;

	                case 2:
	                    localctx = new BinaryOpExpressionContext(this, new ExpressionContext(this, _parentctx, _parentState));
	                    localctx.left = _prevctx;
	                    this.pushNewRecursionContext(localctx, _startState, graphParser.RULE_expression);
	                    this.state = 797;
	                    if (!( this.precpred(this._ctx, 11))) {
	                        throw new antlr4.error.FailedPredicateException(this, "this.precpred(this._ctx, 11)");
	                    }
	                    this.state = 798;
	                    localctx.op = this._input.LT(1);
	                    _la = this._input.LA(1);
	                    if(!(_la===8 || _la===9)) {
	                        localctx.op = this._errHandler.recoverInline(this);
	                    }
	                    else {
	                    	this._errHandler.reportMatch(this);
	                        this.consume();
	                    }
	                    this.state = 799;
	                    localctx.right = this.expression(12);
	                    break;

	                case 3:
	                    localctx = new BinaryOpExpressionContext(this, new ExpressionContext(this, _parentctx, _parentState));
	                    localctx.left = _prevctx;
	                    this.pushNewRecursionContext(localctx, _startState, graphParser.RULE_expression);
	                    this.state = 800;
	                    if (!( this.precpred(this._ctx, 10))) {
	                        throw new antlr4.error.FailedPredicateException(this, "this.precpred(this._ctx, 10)");
	                    }
	                    this.state = 801;
	                    localctx.op = this._input.LT(1);
	                    _la = this._input.LA(1);
	                    if(!(((((_la - 87)) & ~0x1f) === 0 && ((1 << (_la - 87)) & 786435) !== 0))) {
	                        localctx.op = this._errHandler.recoverInline(this);
	                    }
	                    else {
	                    	this._errHandler.reportMatch(this);
	                        this.consume();
	                    }
	                    this.state = 802;
	                    localctx.right = this.expression(11);
	                    break;

	                case 4:
	                    localctx = new BinaryOpExpressionContext(this, new ExpressionContext(this, _parentctx, _parentState));
	                    localctx.left = _prevctx;
	                    this.pushNewRecursionContext(localctx, _startState, graphParser.RULE_expression);
	                    this.state = 803;
	                    if (!( this.precpred(this._ctx, 9))) {
	                        throw new antlr4.error.FailedPredicateException(this, "this.precpred(this._ctx, 9)");
	                    }
	                    this.state = 804;
	                    localctx.op = this._input.LT(1);
	                    _la = this._input.LA(1);
	                    if(!(_la===103 || _la===104)) {
	                        localctx.op = this._errHandler.recoverInline(this);
	                    }
	                    else {
	                    	this._errHandler.reportMatch(this);
	                        this.consume();
	                    }
	                    this.state = 805;
	                    localctx.right = this.expression(10);
	                    break;

	                case 5:
	                    localctx = new BinaryOpExpressionContext(this, new ExpressionContext(this, _parentctx, _parentState));
	                    localctx.left = _prevctx;
	                    this.pushNewRecursionContext(localctx, _startState, graphParser.RULE_expression);
	                    this.state = 806;
	                    if (!( this.precpred(this._ctx, 8))) {
	                        throw new antlr4.error.FailedPredicateException(this, "this.precpred(this._ctx, 8)");
	                    }
	                    this.state = 807;
	                    localctx.op = this.match(graphParser.AndOp);
	                    this.state = 808;
	                    localctx.right = this.expression(9);
	                    break;

	                case 6:
	                    localctx = new BinaryOpExpressionContext(this, new ExpressionContext(this, _parentctx, _parentState));
	                    localctx.left = _prevctx;
	                    this.pushNewRecursionContext(localctx, _startState, graphParser.RULE_expression);
	                    this.state = 809;
	                    if (!( this.precpred(this._ctx, 7))) {
	                        throw new antlr4.error.FailedPredicateException(this, "this.precpred(this._ctx, 7)");
	                    }
	                    this.state = 810;
	                    localctx.op = this.match(graphParser.OrOp);
	                    this.state = 811;
	                    localctx.right = this.expression(8);
	                    break;

	                case 7:
	                    localctx = new NullCollateExpressionContext(this, new ExpressionContext(this, _parentctx, _parentState));
	                    localctx.nullable = _prevctx;
	                    this.pushNewRecursionContext(localctx, _startState, graphParser.RULE_expression);
	                    this.state = 812;
	                    if (!( this.precpred(this._ctx, 6))) {
	                        throw new antlr4.error.FailedPredicateException(this, "this.precpred(this._ctx, 6)");
	                    }
	                    this.state = 813;
	                    this.match(graphParser.QMARK);
	                    this.state = 814;
	                    this.match(graphParser.QMARK);
	                    this.state = 815;
	                    localctx.defaultValue = this.expression(7);
	                    break;

	                case 8:
	                    localctx = new TernaryOpExpressionContext(this, new ExpressionContext(this, _parentctx, _parentState));
	                    localctx.condition = _prevctx;
	                    this.pushNewRecursionContext(localctx, _startState, graphParser.RULE_expression);
	                    this.state = 816;
	                    if (!( this.precpred(this._ctx, 5))) {
	                        throw new antlr4.error.FailedPredicateException(this, "this.precpred(this._ctx, 5)");
	                    }
	                    this.state = 817;
	                    this.match(graphParser.QMARK);
	                    this.state = 818;
	                    localctx.thenExpression = this.expression(0);
	                    this.state = 819;
	                    this.match(graphParser.COLON);
	                    this.state = 820;
	                    localctx.elseExpression = this.expression(5);
	                    break;

	                case 9:
	                    localctx = new DotAccessExpressionContext(this, new ExpressionContext(this, _parentctx, _parentState));
	                    localctx.parent = _prevctx;
	                    this.pushNewRecursionContext(localctx, _startState, graphParser.RULE_expression);
	                    this.state = 822;
	                    if (!( this.precpred(this._ctx, 23))) {
	                        throw new antlr4.error.FailedPredicateException(this, "this.precpred(this._ctx, 23)");
	                    }
	                    this.state = 823;
	                    this.match(graphParser.PERIOD);
	                    this.state = 824;
	                    localctx.member = this.memberName();
	                    break;

	                case 10:
	                    localctx = new OptionalDotAccessExpressionContext(this, new ExpressionContext(this, _parentctx, _parentState));
	                    localctx.parent = _prevctx;
	                    this.pushNewRecursionContext(localctx, _startState, graphParser.RULE_expression);
	                    this.state = 825;
	                    if (!( this.precpred(this._ctx, 22))) {
	                        throw new antlr4.error.FailedPredicateException(this, "this.precpred(this._ctx, 22)");
	                    }
	                    this.state = 826;
	                    this.match(graphParser.QMARK);
	                    this.state = 827;
	                    this.match(graphParser.PERIOD);
	                    this.state = 828;
	                    localctx.member = this.memberName();
	                    break;

	                case 11:
	                    localctx = new CallDotAccessExpressionContext(this, new ExpressionContext(this, _parentctx, _parentState));
	                    localctx.parent = _prevctx;
	                    this.pushNewRecursionContext(localctx, _startState, graphParser.RULE_expression);
	                    this.state = 829;
	                    if (!( this.precpred(this._ctx, 21))) {
	                        throw new antlr4.error.FailedPredicateException(this, "this.precpred(this._ctx, 21)");
	                    }
	                    this.state = 830;
	                    this.match(graphParser.PERIOD);
	                    this.state = 831;
	                    localctx.member = this.memberName();
	                    this.state = 832;
	                    localctx.arguments = this.argumentInvokation();
	                    break;

	                case 12:
	                    localctx = new OptionalCallDotAccessExpressionContext(this, new ExpressionContext(this, _parentctx, _parentState));
	                    localctx.parent = _prevctx;
	                    this.pushNewRecursionContext(localctx, _startState, graphParser.RULE_expression);
	                    this.state = 834;
	                    if (!( this.precpred(this._ctx, 20))) {
	                        throw new antlr4.error.FailedPredicateException(this, "this.precpred(this._ctx, 20)");
	                    }
	                    this.state = 835;
	                    this.match(graphParser.QMARK);
	                    this.state = 836;
	                    this.match(graphParser.PERIOD);
	                    this.state = 837;
	                    localctx.member = this.memberName();
	                    this.state = 838;
	                    localctx.arguments = this.argumentInvokation();
	                    break;

	                case 13:
	                    localctx = new LiteralIndexAccessExpressionContext(this, new ExpressionContext(this, _parentctx, _parentState));
	                    localctx.parent = _prevctx;
	                    this.pushNewRecursionContext(localctx, _startState, graphParser.RULE_expression);
	                    this.state = 840;
	                    if (!( this.precpred(this._ctx, 19))) {
	                        throw new antlr4.error.FailedPredicateException(this, "this.precpred(this._ctx, 19)");
	                    }
	                    this.state = 841;
	                    this.match(graphParser.LBRACKET);
	                    this.state = 842;
	                    localctx.index = this.match(graphParser.DecimalIntegerLiteral);
	                    this.state = 843;
	                    this.match(graphParser.RBRACKET);
	                    break;

	                case 14:
	                    localctx = new ExpressionIndexAccessExpressionContext(this, new ExpressionContext(this, _parentctx, _parentState));
	                    localctx.parent = _prevctx;
	                    this.pushNewRecursionContext(localctx, _startState, graphParser.RULE_expression);
	                    this.state = 844;
	                    if (!( this.precpred(this._ctx, 18))) {
	                        throw new antlr4.error.FailedPredicateException(this, "this.precpred(this._ctx, 18)");
	                    }
	                    this.state = 845;
	                    this.match(graphParser.LBRACKET);
	                    this.state = 846;
	                    localctx.index = this.expression(0);
	                    this.state = 847;
	                    this.match(graphParser.RBRACKET);
	                    break;

	                case 15:
	                    localctx = new IsNullExpressionContext(this, new ExpressionContext(this, _parentctx, _parentState));
	                    this.pushNewRecursionContext(localctx, _startState, graphParser.RULE_expression);
	                    this.state = 849;
	                    if (!( this.precpred(this._ctx, 14))) {
	                        throw new antlr4.error.FailedPredicateException(this, "this.precpred(this._ctx, 14)");
	                    }
	                    this.state = 850;
	                    this.match(graphParser.IS);
	                    this.state = 852;
	                    this._errHandler.sync(this);
	                    _la = this._input.LA(1);
	                    if(_la===49) {
	                        this.state = 851;
	                        this.match(graphParser.NOT);
	                    }

	                    this.state = 854;
	                    this.match(graphParser.NULL);
	                    break;

	                case 16:
	                    localctx = new CastExpressionContext(this, new ExpressionContext(this, _parentctx, _parentState));
	                    this.pushNewRecursionContext(localctx, _startState, graphParser.RULE_expression);
	                    this.state = 855;
	                    if (!( this.precpred(this._ctx, 1))) {
	                        throw new antlr4.error.FailedPredicateException(this, "this.precpred(this._ctx, 1)");
	                    }
	                    this.state = 856;
	                    this.match(graphParser.AS);
	                    this.state = 857;
	                    this.typeDescription();
	                    break;

	                } 
	            }
	            this.state = 862;
	            this._errHandler.sync(this);
	            _alt = this._interp.adaptivePredict(this._input,84,this._ctx);
	        }

	    } catch( error) {
	        if(error instanceof antlr4.error.RecognitionException) {
		        localctx.exception = error;
		        this._errHandler.reportError(this, error);
		        this._errHandler.recover(this, error);
		    } else {
		    	throw error;
		    }
	    } finally {
	        this.unrollRecursionContexts(_parentctx)
	    }
	    return localctx;
	}



	objectPropertyExpressionPart() {
	    let localctx = new ObjectPropertyExpressionPartContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 110, graphParser.RULE_objectPropertyExpressionPart);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 863;
	        localctx.name = this.propertyName();
	        this.state = 864;
	        this.match(graphParser.COLON);
	        this.state = 865;
	        this.expression(0);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	externalFunctionCall() {
	    let localctx = new ExternalFunctionCallContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 112, graphParser.RULE_externalFunctionCall);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 867;
	        this.match(graphParser.EXTERNAL);
	        this.state = 868;
	        localctx.name = this.externalFunctionName();
	        this.state = 869;
	        localctx.arguments = this.argumentInvokation();
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	blockFunctionCall() {
	    let localctx = new BlockFunctionCallContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 114, graphParser.RULE_blockFunctionCall);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 871;
	        this.match(graphParser.BLOCKCALL);
	        this.state = 872;
	        localctx.name = this.blockId();
	        this.state = 873;
	        localctx.arguments = this.argumentInvokation();
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	slotFillingCall() {
	    let localctx = new SlotFillingCallContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 116, graphParser.RULE_slotFillingCall);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 875;
	        this.match(graphParser.FILL);
	        this.state = 876;
	        localctx.name = this.typeAlias();
	        this.state = 877;
	        localctx.arguments = this.argumentInvokation();
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	builtinFunctionCall() {
	    let localctx = new BuiltinFunctionCallContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 118, graphParser.RULE_builtinFunctionCall);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 879;
	        this.match(graphParser.BuiltinMark);
	        this.state = 880;
	        localctx.name = this.builtinFunctionName();
	        this.state = 881;
	        localctx.arguments = this.argumentInvokation();
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	argumentInvokation() {
	    let localctx = new ArgumentInvokationContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 120, graphParser.RULE_argumentInvokation);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 883;
	        this.match(graphParser.LPAREN);
	        this.state = 884;
	        this.argumentList();
	        this.state = 885;
	        this.match(graphParser.RPAREN);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	argumentList() {
	    let localctx = new ArgumentListContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 122, graphParser.RULE_argumentList);
	    var _la = 0;
	    try {
	        this.state = 894;
	        this._errHandler.sync(this);
	        var la_ = this._interp.adaptivePredict(this._input,86,this._ctx);
	        switch(la_) {
	        case 1:
	            this.enterOuterAlt(localctx, 1);
	            this.state = 887;
	            localctx.positional = this.positionalArgumentList();
	            this.state = 890;
	            this._errHandler.sync(this);
	            _la = this._input.LA(1);
	            if(_la===97) {
	                this.state = 888;
	                this.match(graphParser.COMMA);
	                this.state = 889;
	                localctx.named = this.namedArgumentList();
	            }

	            break;

	        case 2:
	            this.enterOuterAlt(localctx, 2);
	            this.state = 892;
	            localctx.named = this.namedArgumentList();
	            break;

	        case 3:
	            this.enterOuterAlt(localctx, 3);

	            break;

	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	namedArgumentList() {
	    let localctx = new NamedArgumentListContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 124, graphParser.RULE_namedArgumentList);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 896;
	        localctx._namedArgument = this.namedArgument();
	        localctx.arguments.push(localctx._namedArgument);
	        this.state = 901;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        while(_la===97) {
	            this.state = 897;
	            this.match(graphParser.COMMA);
	            this.state = 898;
	            localctx._namedArgument = this.namedArgument();
	            localctx.arguments.push(localctx._namedArgument);
	            this.state = 903;
	            this._errHandler.sync(this);
	            _la = this._input.LA(1);
	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	positionalArgumentList() {
	    let localctx = new PositionalArgumentListContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 126, graphParser.RULE_positionalArgumentList);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 904;
	        localctx._positionalArgument = this.positionalArgument();
	        localctx.arguments.push(localctx._positionalArgument);
	        this.state = 909;
	        this._errHandler.sync(this);
	        var _alt = this._interp.adaptivePredict(this._input,88,this._ctx)
	        while(_alt!=2 && _alt!=antlr4.atn.ATN.INVALID_ALT_NUMBER) {
	            if(_alt===1) {
	                this.state = 905;
	                this.match(graphParser.COMMA);
	                this.state = 906;
	                localctx._positionalArgument = this.positionalArgument();
	                localctx.arguments.push(localctx._positionalArgument); 
	            }
	            this.state = 911;
	            this._errHandler.sync(this);
	            _alt = this._interp.adaptivePredict(this._input,88,this._ctx);
	        }

	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	positionalArgument() {
	    let localctx = new PositionalArgumentContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 128, graphParser.RULE_positionalArgument);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 912;
	        this.expression(0);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	namedArgument() {
	    let localctx = new NamedArgumentContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 130, graphParser.RULE_namedArgument);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 914;
	        this.argumentName();
	        this.state = 915;
	        this.match(graphParser.COLON);
	        this.state = 916;
	        this.expression(0);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	parameterList() {
	    let localctx = new ParameterListContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 132, graphParser.RULE_parameterList);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 926;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        if(_la===111) {
	            this.state = 918;
	            localctx._parameter = this.parameter();
	            localctx.parameters.push(localctx._parameter);
	            this.state = 923;
	            this._errHandler.sync(this);
	            _la = this._input.LA(1);
	            while(_la===97) {
	                this.state = 919;
	                this.match(graphParser.COMMA);
	                this.state = 920;
	                localctx._parameter = this.parameter();
	                localctx.parameters.push(localctx._parameter);
	                this.state = 925;
	                this._errHandler.sync(this);
	                _la = this._input.LA(1);
	            }
	        }

	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	parameter() {
	    let localctx = new ParameterContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 134, graphParser.RULE_parameter);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 928;
	        localctx.name = this.parameterName();
	        this.state = 929;
	        this.match(graphParser.COLON);
	        this.state = 930;
	        localctx.type = this.typeDescription();
	        this.state = 933;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        if(_la===100) {
	            this.state = 931;
	            this.match(graphParser.EQUALSIGN);
	            this.state = 932;
	            localctx.defaultValue = this.expression(0);
	        }

	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	reference() {
	    let localctx = new ReferenceContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 136, graphParser.RULE_reference);
	    try {
	        this.state = 955;
	        this._errHandler.sync(this);
	        switch(this._input.LA(1)) {
	        case 74:
	            localctx = new BuiltinReferenceContext(this, localctx);
	            this.enterOuterAlt(localctx, 1);
	            this.state = 935;
	            this.match(graphParser.BuiltinMark);
	            this.state = 936;
	            localctx.refName = this.referenceName();
	            break;
	        case 111:
	            localctx = new LocalReferenceContext(this, localctx);
	            this.enterOuterAlt(localctx, 2);
	            this.state = 937;
	            localctx.refName = this.referenceName();
	            break;
	        case 75:
	            localctx = new ContextReferenceContext(this, localctx);
	            this.enterOuterAlt(localctx, 3);
	            this.state = 938;
	            this.match(graphParser.ContextMark);
	            this.state = 940;
	            this._errHandler.sync(this);
	            var la_ = this._interp.adaptivePredict(this._input,92,this._ctx);
	            if(la_===1) {
	                this.state = 939;
	                localctx.refName = this.referenceName();

	            }
	            break;
	        case 27:
	            localctx = new DigressionReferenceContext(this, localctx);
	            this.enterOuterAlt(localctx, 4);
	            this.state = 942;
	            this.match(graphParser.DIGRESSION);
	            this.state = 953;
	            this._errHandler.sync(this);
	            var la_ = this._interp.adaptivePredict(this._input,95,this._ctx);
	            if(la_===1) {
	                this.state = 943;
	                this.match(graphParser.PERIOD);
	                this.state = 944;
	                localctx.name = this.digressionId();
	                this.state = 947;
	                this._errHandler.sync(this);
	                var la_ = this._interp.adaptivePredict(this._input,93,this._ctx);
	                if(la_===1) {
	                    this.state = 945;
	                    this.match(graphParser.PERIOD);
	                    this.state = 946;
	                    localctx.shared = this.match(graphParser.SHARED);

	                }
	                this.state = 951;
	                this._errHandler.sync(this);
	                var la_ = this._interp.adaptivePredict(this._input,94,this._ctx);
	                if(la_===1) {
	                    this.state = 949;
	                    this.match(graphParser.PERIOD);
	                    this.state = 950;
	                    localctx.refName = this.referenceName();

	                }

	            }
	            break;
	        default:
	            throw new antlr4.error.NoViableAltException(this);
	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	whenId() {
	    let localctx = new WhenIdContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 138, graphParser.RULE_whenId);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 957;
	        this.match(graphParser.ID);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	nodeId() {
	    let localctx = new NodeIdContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 140, graphParser.RULE_nodeId);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 959;
	        this.match(graphParser.ID);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	blockId() {
	    let localctx = new BlockIdContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 142, graphParser.RULE_blockId);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 961;
	        this.match(graphParser.ID);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	digressionId() {
	    let localctx = new DigressionIdContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 144, graphParser.RULE_digressionId);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 963;
	        this.match(graphParser.ID);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	transitionName() {
	    let localctx = new TransitionNameContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 146, graphParser.RULE_transitionName);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 965;
	        this.match(graphParser.ID);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	referenceName() {
	    let localctx = new ReferenceNameContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 148, graphParser.RULE_referenceName);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 967;
	        this.match(graphParser.ID);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	memberName() {
	    let localctx = new MemberNameContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 150, graphParser.RULE_memberName);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 969;
	        this.match(graphParser.ID);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	tagName() {
	    let localctx = new TagNameContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 152, graphParser.RULE_tagName);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 971;
	        this.match(graphParser.ID);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	factName() {
	    let localctx = new FactNameContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 154, graphParser.RULE_factName);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 973;
	        this.match(graphParser.ID);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	fieldName() {
	    let localctx = new FieldNameContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 156, graphParser.RULE_fieldName);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 975;
	        this.match(graphParser.ID);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	variableName() {
	    let localctx = new VariableNameContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 158, graphParser.RULE_variableName);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 977;
	        this.match(graphParser.ID);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	argumentName() {
	    let localctx = new ArgumentNameContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 160, graphParser.RULE_argumentName);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 979;
	        this.match(graphParser.ID);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	parameterName() {
	    let localctx = new ParameterNameContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 162, graphParser.RULE_parameterName);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 981;
	        this.match(graphParser.ID);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	propertyName() {
	    let localctx = new PropertyNameContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 164, graphParser.RULE_propertyName);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 983;
	        _la = this._input.LA(1);
	        if(!(_la===7 || _la===111)) {
	        this._errHandler.recoverInline(this);
	        }
	        else {
	        	this._errHandler.reportMatch(this);
	            this.consume();
	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	builtinFunctionName() {
	    let localctx = new BuiltinFunctionNameContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 166, graphParser.RULE_builtinFunctionName);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 985;
	        this.match(graphParser.ID);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	externalFunctionName() {
	    let localctx = new ExternalFunctionNameContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 168, graphParser.RULE_externalFunctionName);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 987;
	        this.match(graphParser.ID);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	functionName() {
	    let localctx = new FunctionNameContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 170, graphParser.RULE_functionName);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 989;
	        this.match(graphParser.ID);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	anyNumber() {
	    let localctx = new AnyNumberContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 172, graphParser.RULE_anyNumber);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 991;
	        _la = this._input.LA(1);
	        if(!(_la===76 || _la===77)) {
	        this._errHandler.recoverInline(this);
	        }
	        else {
	        	this._errHandler.reportMatch(this);
	            this.consume();
	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	typeEof() {
	    let localctx = new TypeEofContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 174, graphParser.RULE_typeEof);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 993;
	        this.type_();
	        this.state = 994;
	        this.match(graphParser.EOF);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	type_() {
	    let localctx = new Type_Context(this, this._ctx, this.state);
	    this.enterRule(localctx, 176, graphParser.RULE_type_);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 996;
	        localctx._nonNullableType = this.nonNullableType(0);
	        localctx.types.push(localctx._nonNullableType);
	        this.state = 997;
	        localctx._questionMarkOptional = this.questionMarkOptional();
	        localctx.nullableFlags.push(localctx._questionMarkOptional);
	        this.state = 1004;
	        this._errHandler.sync(this);
	        var _alt = this._interp.adaptivePredict(this._input,97,this._ctx)
	        while(_alt!=2 && _alt!=antlr4.atn.ATN.INVALID_ALT_NUMBER) {
	            if(_alt===1) {
	                this.state = 998;
	                this.match(graphParser.TypeOr);
	                this.state = 999;
	                localctx._nonNullableType = this.nonNullableType(0);
	                localctx.types.push(localctx._nonNullableType);
	                this.state = 1000;
	                localctx._questionMarkOptional = this.questionMarkOptional();
	                localctx.nullableFlags.push(localctx._questionMarkOptional); 
	            }
	            this.state = 1006;
	            this._errHandler.sync(this);
	            _alt = this._interp.adaptivePredict(this._input,97,this._ctx);
	        }

	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	questionMarkOptional() {
	    let localctx = new QuestionMarkOptionalContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 178, graphParser.RULE_questionMarkOptional);
	    try {
	        this.state = 1009;
	        this._errHandler.sync(this);
	        var la_ = this._interp.adaptivePredict(this._input,98,this._ctx);
	        switch(la_) {
	        case 1:
	            localctx = new PresentQuestionMarkContext(this, localctx);
	            this.enterOuterAlt(localctx, 1);
	            this.state = 1007;
	            this.match(graphParser.QMARK);
	            break;

	        case 2:
	            localctx = new NoQuestionMarkContext(this, localctx);
	            this.enterOuterAlt(localctx, 2);

	            break;

	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	objectTypeElementSeparator() {
	    let localctx = new ObjectTypeElementSeparatorContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 180, graphParser.RULE_objectTypeElementSeparator);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 1011;
	        _la = this._input.LA(1);
	        if(!(_la===96 || _la===97)) {
	        this._errHandler.recoverInline(this);
	        }
	        else {
	        	this._errHandler.reportMatch(this);
	            this.consume();
	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}


	nonNullableType(_p) {
		if(_p===undefined) {
		    _p = 0;
		}
	    const _parentctx = this._ctx;
	    const _parentState = this.state;
	    let localctx = new NonNullableTypeContext(this, this._ctx, _parentState);
	    let _prevctx = localctx;
	    const _startState = 182;
	    this.enterRecursionRule(localctx, 182, graphParser.RULE_nonNullableType, _p);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 1068;
	        this._errHandler.sync(this);
	        switch(this._input.LA(1)) {
	        case 90:
	            localctx = new ParenthesisedTypeContext(this, localctx);
	            this._ctx = localctx;
	            _prevctx = localctx;

	            this.state = 1014;
	            this.match(graphParser.LPAREN);
	            this.state = 1015;
	            this.type_();
	            this.state = 1016;
	            this.match(graphParser.RPAREN);
	            break;
	        case 79:
	        case 80:
	        case 81:
	        case 82:
	        case 83:
	            localctx = new PredefinedTypeRefContext(this, localctx);
	            this._ctx = localctx;
	            _prevctx = localctx;
	            this.state = 1018;
	            this.predefinedType();
	            break;
	        case 92:
	            localctx = new ObjectTypeContext(this, localctx);
	            this._ctx = localctx;
	            _prevctx = localctx;
	            this.state = 1019;
	            this.match(graphParser.LCURL);
	            this.state = 1025;
	            this._errHandler.sync(this);
	            var _alt = this._interp.adaptivePredict(this._input,99,this._ctx)
	            while(_alt!=2 && _alt!=antlr4.atn.ATN.INVALID_ALT_NUMBER) {
	                if(_alt===1) {
	                    this.state = 1020;
	                    localctx._propertySignature = this.propertySignature();
	                    localctx.properties.push(localctx._propertySignature);
	                    this.state = 1021;
	                    this.objectTypeElementSeparator(); 
	                }
	                this.state = 1027;
	                this._errHandler.sync(this);
	                _alt = this._interp.adaptivePredict(this._input,99,this._ctx);
	            }

	            this.state = 1031;
	            this._errHandler.sync(this);
	            _la = this._input.LA(1);
	            if(_la===86) {
	                this.state = 1028;
	                this.indexSignature();
	                this.state = 1029;
	                this.objectTypeElementSeparator();
	            }

	            this.state = 1038;
	            this._errHandler.sync(this);
	            _la = this._input.LA(1);
	            while(_la===7 || _la===111) {
	                this.state = 1033;
	                localctx._propertySignature = this.propertySignature();
	                localctx.properties.push(localctx._propertySignature);
	                this.state = 1034;
	                this.objectTypeElementSeparator();
	                this.state = 1040;
	                this._errHandler.sync(this);
	                _la = this._input.LA(1);
	            }
	            this.state = 1041;
	            this.match(graphParser.RCURL);
	            break;
	        case 86:
	            localctx = new TupleTypeContext(this, localctx);
	            this._ctx = localctx;
	            _prevctx = localctx;
	            this.state = 1042;
	            this.match(graphParser.LBRACKET);
	            this.state = 1044;
	            this._errHandler.sync(this);
	            _la = this._input.LA(1);
	            if(_la===7 || ((((_la - 79)) & ~0x1f) === 0 && ((1 << (_la - 79)) & 10911) !== 0) || _la===111) {
	                this.state = 1043;
	                localctx._type_ = this.type_();
	                localctx.elementTypes.push(localctx._type_);
	            }

	            this.state = 1050;
	            this._errHandler.sync(this);
	            _la = this._input.LA(1);
	            while(_la===97) {
	                this.state = 1046;
	                this.match(graphParser.COMMA);
	                this.state = 1047;
	                localctx._type_ = this.type_();
	                localctx.elementTypes.push(localctx._type_);
	                this.state = 1052;
	                this._errHandler.sync(this);
	                _la = this._input.LA(1);
	            }
	            this.state = 1053;
	            this.match(graphParser.RBRACKET);
	            break;
	        case 88:
	            localctx = new AngleUnionTypeContext(this, localctx);
	            this._ctx = localctx;
	            _prevctx = localctx;
	            this.state = 1054;
	            this.match(graphParser.LANGLE);
	            this.state = 1056;
	            this._errHandler.sync(this);
	            _la = this._input.LA(1);
	            if(_la===7 || ((((_la - 79)) & ~0x1f) === 0 && ((1 << (_la - 79)) & 10911) !== 0) || _la===111) {
	                this.state = 1055;
	                localctx._type_ = this.type_();
	                localctx.types.push(localctx._type_);
	            }

	            this.state = 1062;
	            this._errHandler.sync(this);
	            _la = this._input.LA(1);
	            while(_la===108) {
	                this.state = 1058;
	                this.match(graphParser.TypeOr);
	                this.state = 1059;
	                localctx._type_ = this.type_();
	                localctx.types.push(localctx._type_);
	                this.state = 1064;
	                this._errHandler.sync(this);
	                _la = this._input.LA(1);
	            }
	            this.state = 1065;
	            this.match(graphParser.RANGLE);
	            break;
	        case 7:
	            localctx = new StringLiteralTypeContext(this, localctx);
	            this._ctx = localctx;
	            _prevctx = localctx;
	            this.state = 1066;
	            localctx.value = this.match(graphParser.StringLiteral);
	            break;
	        case 111:
	            localctx = new TypeReferenceContext(this, localctx);
	            this._ctx = localctx;
	            _prevctx = localctx;
	            this.state = 1067;
	            this.typeAlias();
	            break;
	        default:
	            throw new antlr4.error.NoViableAltException(this);
	        }
	        this._ctx.stop = this._input.LT(-1);
	        this.state = 1078;
	        this._errHandler.sync(this);
	        var _alt = this._interp.adaptivePredict(this._input,108,this._ctx)
	        while(_alt!=2 && _alt!=antlr4.atn.ATN.INVALID_ALT_NUMBER) {
	            if(_alt===1) {
	                if(this._parseListeners!==null) {
	                    this.triggerExitRuleEvent();
	                }
	                _prevctx = localctx;
	                localctx = new ArrayTypeContext(this, new NonNullableTypeContext(this, _parentctx, _parentState));
	                this.pushNewRecursionContext(localctx, _startState, graphParser.RULE_nonNullableType);
	                this.state = 1070;
	                if (!( this.precpred(this._ctx, 5))) {
	                    throw new antlr4.error.FailedPredicateException(this, "this.precpred(this._ctx, 5)");
	                }
	                this.state = 1072;
	                this._errHandler.sync(this);
	                _la = this._input.LA(1);
	                if(_la===98) {
	                    this.state = 1071;
	                    localctx.nullable = this.match(graphParser.QMARK);
	                }

	                this.state = 1074;
	                this.match(graphParser.LBRACKET);
	                this.state = 1075;
	                this.match(graphParser.RBRACKET); 
	            }
	            this.state = 1080;
	            this._errHandler.sync(this);
	            _alt = this._interp.adaptivePredict(this._input,108,this._ctx);
	        }

	    } catch( error) {
	        if(error instanceof antlr4.error.RecognitionException) {
		        localctx.exception = error;
		        this._errHandler.reportError(this, error);
		        this._errHandler.recover(this, error);
		    } else {
		    	throw error;
		    }
	    } finally {
	        this.unrollRecursionContexts(_parentctx)
	    }
	    return localctx;
	}



	predefinedType() {
	    let localctx = new PredefinedTypeContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 184, graphParser.RULE_predefinedType);
	    try {
	        this.state = 1086;
	        this._errHandler.sync(this);
	        switch(this._input.LA(1)) {
	        case 83:
	            localctx = new UnknownTypeContext(this, localctx);
	            this.enterOuterAlt(localctx, 1);
	            this.state = 1081;
	            this.match(graphParser.Unknown);
	            break;
	        case 80:
	            localctx = new NumberTypeContext(this, localctx);
	            this.enterOuterAlt(localctx, 2);
	            this.state = 1082;
	            this.match(graphParser.Number);
	            break;
	        case 81:
	            localctx = new BooleanTypeContext(this, localctx);
	            this.enterOuterAlt(localctx, 3);
	            this.state = 1083;
	            this.match(graphParser.Boolean);
	            break;
	        case 82:
	            localctx = new StringTypeContext(this, localctx);
	            this.enterOuterAlt(localctx, 4);
	            this.state = 1084;
	            this.match(graphParser.String);
	            break;
	        case 79:
	            localctx = new EmptyTypeContext(this, localctx);
	            this.enterOuterAlt(localctx, 5);
	            this.state = 1085;
	            this.match(graphParser.Empty);
	            break;
	        default:
	            throw new antlr4.error.NoViableAltException(this);
	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	propertySignature() {
	    let localctx = new PropertySignatureContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 186, graphParser.RULE_propertySignature);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 1088;
	        this.propertyName();
	        this.state = 1090;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        if(_la===98) {
	            this.state = 1089;
	            this.match(graphParser.QMARK);
	        }

	        this.state = 1092;
	        this.typeAnnotation();
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	indexSignature() {
	    let localctx = new IndexSignatureContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 188, graphParser.RULE_indexSignature);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 1094;
	        this.match(graphParser.LBRACKET);
	        this.state = 1095;
	        this.match(graphParser.ID);
	        this.state = 1096;
	        this.match(graphParser.COLON);
	        this.state = 1097;
	        this.match(graphParser.String);
	        this.state = 1098;
	        this.match(graphParser.RBRACKET);
	        this.state = 1099;
	        this.typeAnnotation();
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	typeAnnotation() {
	    let localctx = new TypeAnnotationContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 190, graphParser.RULE_typeAnnotation);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 1101;
	        this.match(graphParser.COLON);
	        this.state = 1102;
	        this.type_();
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	typeAlias() {
	    let localctx = new TypeAliasContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 192, graphParser.RULE_typeAlias);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 1104;
	        this.match(graphParser.ID);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}


}

graphParser.EOF = antlr4.Token.EOF;
graphParser.T__0 = 1;
graphParser.T__1 = 2;
graphParser.T__2 = 3;
graphParser.T__3 = 4;
graphParser.T__4 = 5;
graphParser.T__5 = 6;
graphParser.StringLiteral = 7;
graphParser.PLUS = 8;
graphParser.MINUS = 9;
graphParser.STAR = 10;
graphParser.SLASH = 11;
graphParser.PERCENT = 12;
graphParser.APPLY = 13;
graphParser.WITH = 14;
graphParser.AS = 15;
graphParser.ASYNC = 16;
graphParser.BLOCK = 17;
graphParser.BLOCKCALL = 18;
graphParser.CATCH = 19;
graphParser.COMMENT = 20;
graphParser.CONDITIONS = 21;
graphParser.CONFIDENT = 22;
graphParser.CONTEXT = 23;
graphParser.COW = 24;
graphParser.DEFAULT = 25;
graphParser.DEFINED = 26;
graphParser.DIGRESSION = 27;
graphParser.DIGRESSIONS = 28;
graphParser.DISABLE = 29;
graphParser.DO = 30;
graphParser.DONE = 31;
graphParser.ELSE = 32;
graphParser.ENABLE = 33;
graphParser.EXTERNAL = 34;
graphParser.EXIT = 35;
graphParser.FINALLY = 36;
graphParser.FOR = 37;
graphParser.FUNCTION = 38;
graphParser.GLOBAL = 39;
graphParser.GOTO = 40;
graphParser.IF = 41;
graphParser.IMPORT = 42;
graphParser.IN = 43;
graphParser.INPUT = 44;
graphParser.IS = 45;
graphParser.LIBRARY = 46;
graphParser.LOGEXPR = 47;
graphParser.NODE = 48;
graphParser.NOT = 49;
graphParser.OF = 50;
graphParser.ON = 51;
graphParser.ONDIGRETURN = 52;
graphParser.ONEXIT = 53;
graphParser.OUTPUT = 54;
graphParser.PREPROCESSOR = 55;
graphParser.PRIORITY = 56;
graphParser.RETURN = 57;
graphParser.SAVE = 58;
graphParser.SET = 59;
graphParser.SHARED = 60;
graphParser.START = 61;
graphParser.STATIC = 62;
graphParser.SYNC = 63;
graphParser.SYSTEM = 64;
graphParser.TIMEOUT = 65;
graphParser.TRANSITIONS = 66;
graphParser.TAGS = 67;
graphParser.TRY = 68;
graphParser.TYPE = 69;
graphParser.VAR = 70;
graphParser.WAIT = 71;
graphParser.WHEN = 72;
graphParser.FILL = 73;
graphParser.BuiltinMark = 74;
graphParser.ContextMark = 75;
graphParser.DecimalLiteral = 76;
graphParser.DecimalIntegerLiteral = 77;
graphParser.BooleanLiteral = 78;
graphParser.Empty = 79;
graphParser.Number = 80;
graphParser.Boolean = 81;
graphParser.String = 82;
graphParser.Unknown = 83;
graphParser.NULL = 84;
graphParser.RBRACKET = 85;
graphParser.LBRACKET = 86;
graphParser.RANGLE = 87;
graphParser.LANGLE = 88;
graphParser.RPAREN = 89;
graphParser.LPAREN = 90;
graphParser.RCURL = 91;
graphParser.LCURL = 92;
graphParser.QUOTE = 93;
graphParser.BACKTRICK = 94;
graphParser.COLON = 95;
graphParser.SEMICOLON = 96;
graphParser.COMMA = 97;
graphParser.QMARK = 98;
graphParser.PERIOD = 99;
graphParser.EQUALSIGN = 100;
graphParser.AddOp = 101;
graphParser.RemoveOp = 102;
graphParser.EqualOp = 103;
graphParser.NotEqualOp = 104;
graphParser.GreaterEqualOp = 105;
graphParser.LessEqualOp = 106;
graphParser.OrOp = 107;
graphParser.TypeOr = 108;
graphParser.AndOp = 109;
graphParser.NotOperator = 110;
graphParser.ID = 111;
graphParser.LineComment = 112;
graphParser.DocsComment = 113;
graphParser.BlockComment = 114;
graphParser.Whitespace = 115;

graphParser.RULE_file = 0;
graphParser.RULE_referenceFile = 1;
graphParser.RULE_expressionFile = 2;
graphParser.RULE_blockContent = 3;
graphParser.RULE_systemDescription = 4;
graphParser.RULE_systemElementDescription = 5;
graphParser.RULE_contextDescription = 6;
graphParser.RULE_contextElementDescription = 7;
graphParser.RULE_externalFunctionDeclaration = 8;
graphParser.RULE_importDefinition = 9;
graphParser.RULE_whenDescription = 10;
graphParser.RULE_typeDefinition = 11;
graphParser.RULE_functionDescription = 12;
graphParser.RULE_contextFunctionDescription = 13;
graphParser.RULE_blockDeclaration = 14;
graphParser.RULE_nodeDeclaration = 15;
graphParser.RULE_digressionDeclaration = 16;
graphParser.RULE_digressionContent = 17;
graphParser.RULE_comment = 18;
graphParser.RULE_nodeContent = 19;
graphParser.RULE_nodeOnExitSection = 20;
graphParser.RULE_nodeOnExitSectionItem = 21;
graphParser.RULE_onExitDoWithConfident = 22;
graphParser.RULE_nodeOnDigressionReturnSection = 23;
graphParser.RULE_nodeTransitionSection = 24;
graphParser.RULE_namedTransitionDefinition = 25;
graphParser.RULE_transitionDefinition = 26;
graphParser.RULE_transitionDefinitionOnMessage = 27;
graphParser.RULE_timeoutTransitionDefinition = 28;
graphParser.RULE_conditionalTransitionDefinition = 29;
graphParser.RULE_unconditionalTransitionDefinition = 30;
graphParser.RULE_doSection = 31;
graphParser.RULE_blockBody = 32;
graphParser.RULE_oneOrGroupedCommand = 33;
graphParser.RULE_nodeCommand = 34;
graphParser.RULE_cycleCommand = 35;
graphParser.RULE_statement = 36;
graphParser.RULE_statementBody = 37;
graphParser.RULE_logExpressionTypeCommand = 38;
graphParser.RULE_digressionControl = 39;
graphParser.RULE_digressionSpec = 40;
graphParser.RULE_exitCommand = 41;
graphParser.RULE_returnCommand = 42;
graphParser.RULE_variableDefinition = 43;
graphParser.RULE_variableDeclaration = 44;
graphParser.RULE_typeDescription = 45;
graphParser.RULE_conditionalCommand = 46;
graphParser.RULE_foreachCommand = 47;
graphParser.RULE_waitCommand = 48;
graphParser.RULE_activatedTransitionList = 49;
graphParser.RULE_gotoCommand = 50;
graphParser.RULE_setCommand = 51;
graphParser.RULE_assignOpCommand = 52;
graphParser.RULE_lValue = 53;
graphParser.RULE_expression = 54;
graphParser.RULE_objectPropertyExpressionPart = 55;
graphParser.RULE_externalFunctionCall = 56;
graphParser.RULE_blockFunctionCall = 57;
graphParser.RULE_slotFillingCall = 58;
graphParser.RULE_builtinFunctionCall = 59;
graphParser.RULE_argumentInvokation = 60;
graphParser.RULE_argumentList = 61;
graphParser.RULE_namedArgumentList = 62;
graphParser.RULE_positionalArgumentList = 63;
graphParser.RULE_positionalArgument = 64;
graphParser.RULE_namedArgument = 65;
graphParser.RULE_parameterList = 66;
graphParser.RULE_parameter = 67;
graphParser.RULE_reference = 68;
graphParser.RULE_whenId = 69;
graphParser.RULE_nodeId = 70;
graphParser.RULE_blockId = 71;
graphParser.RULE_digressionId = 72;
graphParser.RULE_transitionName = 73;
graphParser.RULE_referenceName = 74;
graphParser.RULE_memberName = 75;
graphParser.RULE_tagName = 76;
graphParser.RULE_factName = 77;
graphParser.RULE_fieldName = 78;
graphParser.RULE_variableName = 79;
graphParser.RULE_argumentName = 80;
graphParser.RULE_parameterName = 81;
graphParser.RULE_propertyName = 82;
graphParser.RULE_builtinFunctionName = 83;
graphParser.RULE_externalFunctionName = 84;
graphParser.RULE_functionName = 85;
graphParser.RULE_anyNumber = 86;
graphParser.RULE_typeEof = 87;
graphParser.RULE_type_ = 88;
graphParser.RULE_questionMarkOptional = 89;
graphParser.RULE_objectTypeElementSeparator = 90;
graphParser.RULE_nonNullableType = 91;
graphParser.RULE_predefinedType = 92;
graphParser.RULE_propertySignature = 93;
graphParser.RULE_indexSignature = 94;
graphParser.RULE_typeAnnotation = 95;
graphParser.RULE_typeAlias = 96;

class FileContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_file;
        this.mainBlock = null;
    }

	EOF() {
	    return this.getToken(graphParser.EOF, 0);
	};

	blockContent() {
	    return this.getTypedRuleContext(BlockContentContext,0);
	};

	LIBRARY() {
	    return this.getToken(graphParser.LIBRARY, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterFile(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitFile(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitFile(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class ReferenceFileContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_referenceFile;
    }

	reference() {
	    return this.getTypedRuleContext(ReferenceContext,0);
	};

	EOF() {
	    return this.getToken(graphParser.EOF, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterReferenceFile(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitReferenceFile(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitReferenceFile(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class ExpressionFileContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_expressionFile;
    }

	expression() {
	    return this.getTypedRuleContext(ExpressionContext,0);
	};

	EOF() {
	    return this.getToken(graphParser.EOF, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterExpressionFile(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitExpressionFile(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitExpressionFile(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class BlockContentContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_blockContent;
        this._importDefinition = null;
        this.imports = [];
        this._typeDefinition = null;
        this.types = [];
        this.contextType = null;
        this.system = null;
        this._externalFunctionDeclaration = null;
        this.externalFunctions = [];
        this._nodeDeclaration = null;
        this.nodes = [];
        this._blockDeclaration = null;
        this.blocks = [];
        this._digressionDeclaration = null;
        this.digressions = [];
        this._contextFunctionDescription = null;
        this.contextFunctions = [];
        this._whenDescription = null;
        this.whenDescriptions = [];
    }

	CONTEXT() {
	    return this.getToken(graphParser.CONTEXT, 0);
	};

	SYSTEM() {
	    return this.getToken(graphParser.SYSTEM, 0);
	};

	importDefinition = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(ImportDefinitionContext);
	    } else {
	        return this.getTypedRuleContext(ImportDefinitionContext,i);
	    }
	};

	typeDefinition = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(TypeDefinitionContext);
	    } else {
	        return this.getTypedRuleContext(TypeDefinitionContext,i);
	    }
	};

	contextDescription() {
	    return this.getTypedRuleContext(ContextDescriptionContext,0);
	};

	systemDescription() {
	    return this.getTypedRuleContext(SystemDescriptionContext,0);
	};

	externalFunctionDeclaration = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(ExternalFunctionDeclarationContext);
	    } else {
	        return this.getTypedRuleContext(ExternalFunctionDeclarationContext,i);
	    }
	};

	nodeDeclaration = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(NodeDeclarationContext);
	    } else {
	        return this.getTypedRuleContext(NodeDeclarationContext,i);
	    }
	};

	blockDeclaration = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(BlockDeclarationContext);
	    } else {
	        return this.getTypedRuleContext(BlockDeclarationContext,i);
	    }
	};

	digressionDeclaration = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(DigressionDeclarationContext);
	    } else {
	        return this.getTypedRuleContext(DigressionDeclarationContext,i);
	    }
	};

	contextFunctionDescription = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(ContextFunctionDescriptionContext);
	    } else {
	        return this.getTypedRuleContext(ContextFunctionDescriptionContext,i);
	    }
	};

	whenDescription = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(WhenDescriptionContext);
	    } else {
	        return this.getTypedRuleContext(WhenDescriptionContext,i);
	    }
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterBlockContent(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitBlockContent(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitBlockContent(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class SystemDescriptionContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_systemDescription;
        this._systemElementDescription = null;
        this.elements = [];
    }

	LCURL() {
	    return this.getToken(graphParser.LCURL, 0);
	};

	RCURL() {
	    return this.getToken(graphParser.RCURL, 0);
	};

	objectTypeElementSeparator = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(ObjectTypeElementSeparatorContext);
	    } else {
	        return this.getTypedRuleContext(ObjectTypeElementSeparatorContext,i);
	    }
	};

	systemElementDescription = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(SystemElementDescriptionContext);
	    } else {
	        return this.getTypedRuleContext(SystemElementDescriptionContext,i);
	    }
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterSystemDescription(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitSystemDescription(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitSystemDescription(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class SystemElementDescriptionContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_systemElementDescription;
        this.name = null;
        this.value = null;
    }

	COLON() {
	    return this.getToken(graphParser.COLON, 0);
	};

	variableName() {
	    return this.getTypedRuleContext(VariableNameContext,0);
	};

	expression() {
	    return this.getTypedRuleContext(ExpressionContext,0);
	};

	EQUALSIGN() {
	    return this.getToken(graphParser.EQUALSIGN, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterSystemElementDescription(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitSystemElementDescription(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitSystemElementDescription(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class ContextDescriptionContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_contextDescription;
        this._contextElementDescription = null;
        this.elements = [];
    }

	LCURL() {
	    return this.getToken(graphParser.LCURL, 0);
	};

	RCURL() {
	    return this.getToken(graphParser.RCURL, 0);
	};

	objectTypeElementSeparator = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(ObjectTypeElementSeparatorContext);
	    } else {
	        return this.getTypedRuleContext(ObjectTypeElementSeparatorContext,i);
	    }
	};

	contextElementDescription = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(ContextElementDescriptionContext);
	    } else {
	        return this.getTypedRuleContext(ContextElementDescriptionContext,i);
	    }
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterContextDescription(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitContextDescription(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitContextDescription(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class ContextElementDescriptionContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_contextElementDescription;
        this.input = null;
        this.output = null;
        this.name = null;
        this.type = null;
        this.value = null;
    }

	COLON() {
	    return this.getToken(graphParser.COLON, 0);
	};

	variableName() {
	    return this.getTypedRuleContext(VariableNameContext,0);
	};

	typeDescription() {
	    return this.getTypedRuleContext(TypeDescriptionContext,0);
	};

	EQUALSIGN() {
	    return this.getToken(graphParser.EQUALSIGN, 0);
	};

	INPUT() {
	    return this.getToken(graphParser.INPUT, 0);
	};

	OUTPUT() {
	    return this.getToken(graphParser.OUTPUT, 0);
	};

	expression() {
	    return this.getTypedRuleContext(ExpressionContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterContextElementDescription(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitContextElementDescription(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitContextElementDescription(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class ExternalFunctionDeclarationContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_externalFunctionDeclaration;
        this.name = null;
        this.parameters = null;
        this.returnType = null;
    }

	EXTERNAL() {
	    return this.getToken(graphParser.EXTERNAL, 0);
	};

	FUNCTION() {
	    return this.getToken(graphParser.FUNCTION, 0);
	};

	LPAREN() {
	    return this.getToken(graphParser.LPAREN, 0);
	};

	RPAREN() {
	    return this.getToken(graphParser.RPAREN, 0);
	};

	COLON() {
	    return this.getToken(graphParser.COLON, 0);
	};

	SEMICOLON() {
	    return this.getToken(graphParser.SEMICOLON, 0);
	};

	externalFunctionName() {
	    return this.getTypedRuleContext(ExternalFunctionNameContext,0);
	};

	parameterList() {
	    return this.getTypedRuleContext(ParameterListContext,0);
	};

	typeDescription() {
	    return this.getTypedRuleContext(TypeDescriptionContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterExternalFunctionDeclaration(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitExternalFunctionDeclaration(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitExternalFunctionDeclaration(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class ImportDefinitionContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_importDefinition;
        this.path = null;
        this.packageName = null;
    }

	IMPORT() {
	    return this.getToken(graphParser.IMPORT, 0);
	};

	SEMICOLON() {
	    return this.getToken(graphParser.SEMICOLON, 0);
	};

	StringLiteral = function(i) {
		if(i===undefined) {
			i = null;
		}
	    if(i===null) {
	        return this.getTokens(graphParser.StringLiteral);
	    } else {
	        return this.getToken(graphParser.StringLiteral, i);
	    }
	};


	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterImportDefinition(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitImportDefinition(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitImportDefinition(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class WhenDescriptionContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_whenDescription;
        this.event = null;
        this.name = null;
        this.priority = null;
        this.doSec = null;
    }

	WHEN() {
	    return this.getToken(graphParser.WHEN, 0);
	};

	doSection() {
	    return this.getTypedRuleContext(DoSectionContext,0);
	};

	PRIORITY() {
	    return this.getToken(graphParser.PRIORITY, 0);
	};

	whenId() {
	    return this.getTypedRuleContext(WhenIdContext,0);
	};

	DecimalIntegerLiteral() {
	    return this.getToken(graphParser.DecimalIntegerLiteral, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterWhenDescription(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitWhenDescription(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitWhenDescription(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class TypeDefinitionContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_typeDefinition;
        this._functionDescription = null;
        this.functions = [];
    }

	TYPE() {
	    return this.getToken(graphParser.TYPE, 0);
	};

	typeAlias() {
	    return this.getTypedRuleContext(TypeAliasContext,0);
	};

	EQUALSIGN() {
	    return this.getToken(graphParser.EQUALSIGN, 0);
	};

	typeDescription() {
	    return this.getTypedRuleContext(TypeDescriptionContext,0);
	};

	SEMICOLON() {
	    return this.getToken(graphParser.SEMICOLON, 0);
	};

	WITH() {
	    return this.getToken(graphParser.WITH, 0);
	};

	LCURL() {
	    return this.getToken(graphParser.LCURL, 0);
	};

	RCURL() {
	    return this.getToken(graphParser.RCURL, 0);
	};

	functionDescription = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(FunctionDescriptionContext);
	    } else {
	        return this.getTypedRuleContext(FunctionDescriptionContext,i);
	    }
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterTypeDefinition(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitTypeDefinition(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitTypeDefinition(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class FunctionDescriptionContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_functionDescription;
        this._LineComment = null;
        this.comments = [];
        this.doc = null;
        this.name = null;
        this.parameters = null;
        this.returnType = null;
        this.body = null;
    }

	LPAREN() {
	    return this.getToken(graphParser.LPAREN, 0);
	};

	RPAREN() {
	    return this.getToken(graphParser.RPAREN, 0);
	};

	COLON() {
	    return this.getToken(graphParser.COLON, 0);
	};

	functionName() {
	    return this.getTypedRuleContext(FunctionNameContext,0);
	};

	parameterList() {
	    return this.getTypedRuleContext(ParameterListContext,0);
	};

	typeDescription() {
	    return this.getTypedRuleContext(TypeDescriptionContext,0);
	};

	blockBody() {
	    return this.getTypedRuleContext(BlockBodyContext,0);
	};

	DocsComment() {
	    return this.getToken(graphParser.DocsComment, 0);
	};

	BlockComment = function(i) {
		if(i===undefined) {
			i = null;
		}
	    if(i===null) {
	        return this.getTokens(graphParser.BlockComment);
	    } else {
	        return this.getToken(graphParser.BlockComment, i);
	    }
	};


	LineComment = function(i) {
		if(i===undefined) {
			i = null;
		}
	    if(i===null) {
	        return this.getTokens(graphParser.LineComment);
	    } else {
	        return this.getToken(graphParser.LineComment, i);
	    }
	};


	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterFunctionDescription(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitFunctionDescription(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitFunctionDescription(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class ContextFunctionDescriptionContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_contextFunctionDescription;
        this._LineComment = null;
        this.comments = [];
        this.doc = null;
        this.name = null;
        this.parameters = null;
        this.returnType = null;
        this.body = null;
    }

	FUNCTION() {
	    return this.getToken(graphParser.FUNCTION, 0);
	};

	LPAREN() {
	    return this.getToken(graphParser.LPAREN, 0);
	};

	RPAREN() {
	    return this.getToken(graphParser.RPAREN, 0);
	};

	COLON() {
	    return this.getToken(graphParser.COLON, 0);
	};

	functionName() {
	    return this.getTypedRuleContext(FunctionNameContext,0);
	};

	parameterList() {
	    return this.getTypedRuleContext(ParameterListContext,0);
	};

	typeDescription() {
	    return this.getTypedRuleContext(TypeDescriptionContext,0);
	};

	blockBody() {
	    return this.getTypedRuleContext(BlockBodyContext,0);
	};

	DocsComment() {
	    return this.getToken(graphParser.DocsComment, 0);
	};

	BlockComment = function(i) {
		if(i===undefined) {
			i = null;
		}
	    if(i===null) {
	        return this.getTokens(graphParser.BlockComment);
	    } else {
	        return this.getToken(graphParser.BlockComment, i);
	    }
	};


	LineComment = function(i) {
		if(i===undefined) {
			i = null;
		}
	    if(i===null) {
	        return this.getTokens(graphParser.LineComment);
	    } else {
	        return this.getToken(graphParser.LineComment, i);
	    }
	};


	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterContextFunctionDescription(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitContextFunctionDescription(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitContextFunctionDescription(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class BlockDeclarationContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_blockDeclaration;
        this.sync = null;
        this.returnType = null;
        this.async = null;
        this.content = null;
    }

	LCURL() {
	    return this.getToken(graphParser.LCURL, 0);
	};

	RCURL() {
	    return this.getToken(graphParser.RCURL, 0);
	};

	blockContent() {
	    return this.getTypedRuleContext(BlockContentContext,0);
	};

	BLOCK() {
	    return this.getToken(graphParser.BLOCK, 0);
	};

	blockId() {
	    return this.getTypedRuleContext(BlockIdContext,0);
	};

	LPAREN() {
	    return this.getToken(graphParser.LPAREN, 0);
	};

	parameterList() {
	    return this.getTypedRuleContext(ParameterListContext,0);
	};

	RPAREN() {
	    return this.getToken(graphParser.RPAREN, 0);
	};

	COLON() {
	    return this.getToken(graphParser.COLON, 0);
	};

	typeDescription() {
	    return this.getTypedRuleContext(TypeDescriptionContext,0);
	};

	SYNC() {
	    return this.getToken(graphParser.SYNC, 0);
	};

	ASYNC() {
	    return this.getToken(graphParser.ASYNC, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterBlockDeclaration(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitBlockDeclaration(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitBlockDeclaration(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class NodeDeclarationContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_nodeDeclaration;
        this.start = null;
        this.name = null;
        this.content = null;
    }

	NODE() {
	    return this.getToken(graphParser.NODE, 0);
	};

	LCURL() {
	    return this.getToken(graphParser.LCURL, 0);
	};

	RCURL() {
	    return this.getToken(graphParser.RCURL, 0);
	};

	nodeId() {
	    return this.getTypedRuleContext(NodeIdContext,0);
	};

	nodeContent() {
	    return this.getTypedRuleContext(NodeContentContext,0);
	};

	comment() {
	    return this.getTypedRuleContext(CommentContext,0);
	};

	START() {
	    return this.getToken(graphParser.START, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterNodeDeclaration(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitNodeDeclaration(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitNodeDeclaration(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class DigressionDeclarationContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_digressionDeclaration;
        this.global = null;
        this.preprocessor = null;
        this.name = null;
    }

	DIGRESSION() {
	    return this.getToken(graphParser.DIGRESSION, 0);
	};

	LCURL() {
	    return this.getToken(graphParser.LCURL, 0);
	};

	digressionContent() {
	    return this.getTypedRuleContext(DigressionContentContext,0);
	};

	RCURL() {
	    return this.getToken(graphParser.RCURL, 0);
	};

	digressionId() {
	    return this.getTypedRuleContext(DigressionIdContext,0);
	};

	GLOBAL() {
	    return this.getToken(graphParser.GLOBAL, 0);
	};

	PREPROCESSOR() {
	    return this.getToken(graphParser.PREPROCESSOR, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterDigressionDeclaration(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitDigressionDeclaration(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitDigressionDeclaration(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class DigressionContentContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_digressionContent;
        this._transitionDefinitionOnMessage = null;
        this.transitions = [];
        this._variableDefinition = null;
        this.sharedState = [];
        this.copiedState = [];
    }

	CONDITIONS() {
	    return this.getToken(graphParser.CONDITIONS, 0);
	};

	LCURL() {
	    return this.getToken(graphParser.LCURL, 0);
	};

	RCURL() {
	    return this.getToken(graphParser.RCURL, 0);
	};

	nodeContent() {
	    return this.getTypedRuleContext(NodeContentContext,0);
	};

	SEMICOLON = function(i) {
		if(i===undefined) {
			i = null;
		}
	    if(i===null) {
	        return this.getTokens(graphParser.SEMICOLON);
	    } else {
	        return this.getToken(graphParser.SEMICOLON, i);
	    }
	};


	transitionDefinitionOnMessage = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(TransitionDefinitionOnMessageContext);
	    } else {
	        return this.getTypedRuleContext(TransitionDefinitionOnMessageContext,i);
	    }
	};

	SHARED = function(i) {
		if(i===undefined) {
			i = null;
		}
	    if(i===null) {
	        return this.getTokens(graphParser.SHARED);
	    } else {
	        return this.getToken(graphParser.SHARED, i);
	    }
	};


	variableDefinition = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(VariableDefinitionContext);
	    } else {
	        return this.getTypedRuleContext(VariableDefinitionContext,i);
	    }
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterDigressionContent(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitDigressionContent(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitDigressionContent(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class CommentContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_comment;
    }

	COMMENT() {
	    return this.getToken(graphParser.COMMENT, 0);
	};

	StringLiteral() {
	    return this.getToken(graphParser.StringLiteral, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterComment(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitComment(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitComment(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class NodeContentContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_nodeContent;
        this.doSec = null;
        this.transitions = null;
        this.preExit = null;
        this.onExit = null;
    }

	doSection = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(DoSectionContext);
	    } else {
	        return this.getTypedRuleContext(DoSectionContext,i);
	    }
	};

	PREPROCESSOR() {
	    return this.getToken(graphParser.PREPROCESSOR, 0);
	};

	nodeOnDigressionReturnSection() {
	    return this.getTypedRuleContext(NodeOnDigressionReturnSectionContext,0);
	};

	nodeTransitionSection() {
	    return this.getTypedRuleContext(NodeTransitionSectionContext,0);
	};

	nodeOnExitSection() {
	    return this.getTypedRuleContext(NodeOnExitSectionContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterNodeContent(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitNodeContent(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitNodeContent(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class NodeOnExitSectionContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_nodeOnExitSection;
        this.defaultDo = null;
        this.digressionDo = null;
    }

	ONEXIT() {
	    return this.getToken(graphParser.ONEXIT, 0);
	};

	LCURL() {
	    return this.getToken(graphParser.LCURL, 0);
	};

	RCURL() {
	    return this.getToken(graphParser.RCURL, 0);
	};

	nodeOnExitSectionItem = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(NodeOnExitSectionItemContext);
	    } else {
	        return this.getTypedRuleContext(NodeOnExitSectionItemContext,i);
	    }
	};

	DEFAULT() {
	    return this.getToken(graphParser.DEFAULT, 0);
	};

	COLON = function(i) {
		if(i===undefined) {
			i = null;
		}
	    if(i===null) {
	        return this.getTokens(graphParser.COLON);
	    } else {
	        return this.getToken(graphParser.COLON, i);
	    }
	};


	DIGRESSION() {
	    return this.getToken(graphParser.DIGRESSION, 0);
	};

	onExitDoWithConfident = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(OnExitDoWithConfidentContext);
	    } else {
	        return this.getTypedRuleContext(OnExitDoWithConfidentContext,i);
	    }
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterNodeOnExitSection(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitNodeOnExitSection(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitNodeOnExitSection(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class NodeOnExitSectionItemContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_nodeOnExitSectionItem;
    }

	onExitDoWithConfident() {
	    return this.getTypedRuleContext(OnExitDoWithConfidentContext,0);
	};

	transitionName = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(TransitionNameContext);
	    } else {
	        return this.getTypedRuleContext(TransitionNameContext,i);
	    }
	};

	COLON = function(i) {
		if(i===undefined) {
			i = null;
		}
	    if(i===null) {
	        return this.getTokens(graphParser.COLON);
	    } else {
	        return this.getToken(graphParser.COLON, i);
	    }
	};


	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterNodeOnExitSectionItem(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitNodeOnExitSectionItem(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitNodeOnExitSectionItem(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class OnExitDoWithConfidentContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_onExitDoWithConfident;
        this.doSec = null;
        this.confidentDo = null;
    }

	doSection = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(DoSectionContext);
	    } else {
	        return this.getTypedRuleContext(DoSectionContext,i);
	    }
	};

	ON() {
	    return this.getToken(graphParser.ON, 0);
	};

	CONFIDENT() {
	    return this.getToken(graphParser.CONFIDENT, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterOnExitDoWithConfident(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitOnExitDoWithConfident(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitOnExitDoWithConfident(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class NodeOnDigressionReturnSectionContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_nodeOnDigressionReturnSection;
    }

	ONDIGRETURN() {
	    return this.getToken(graphParser.ONDIGRETURN, 0);
	};

	blockBody() {
	    return this.getTypedRuleContext(BlockBodyContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterNodeOnDigressionReturnSection(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitNodeOnDigressionReturnSection(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitNodeOnDigressionReturnSection(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class NodeTransitionSectionContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_nodeTransitionSection;
        this._namedTransitionDefinition = null;
        this.list = [];
    }

	TRANSITIONS() {
	    return this.getToken(graphParser.TRANSITIONS, 0);
	};

	LCURL() {
	    return this.getToken(graphParser.LCURL, 0);
	};

	RCURL() {
	    return this.getToken(graphParser.RCURL, 0);
	};

	namedTransitionDefinition = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(NamedTransitionDefinitionContext);
	    } else {
	        return this.getTypedRuleContext(NamedTransitionDefinitionContext,i);
	    }
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterNodeTransitionSection(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitNodeTransitionSection(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitNodeTransitionSection(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class NamedTransitionDefinitionContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_namedTransitionDefinition;
        this.target = null;
    }

	transitionName() {
	    return this.getTypedRuleContext(TransitionNameContext,0);
	};

	COLON() {
	    return this.getToken(graphParser.COLON, 0);
	};

	GOTO() {
	    return this.getToken(graphParser.GOTO, 0);
	};

	transitionDefinition() {
	    return this.getTypedRuleContext(TransitionDefinitionContext,0);
	};

	SEMICOLON() {
	    return this.getToken(graphParser.SEMICOLON, 0);
	};

	nodeId() {
	    return this.getTypedRuleContext(NodeIdContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterNamedTransitionDefinition(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitNamedTransitionDefinition(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitNamedTransitionDefinition(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class TransitionDefinitionContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_transitionDefinition;
    }

	transitionDefinitionOnMessage() {
	    return this.getTypedRuleContext(TransitionDefinitionOnMessageContext,0);
	};

	unconditionalTransitionDefinition() {
	    return this.getTypedRuleContext(UnconditionalTransitionDefinitionContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterTransitionDefinition(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitTransitionDefinition(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitTransitionDefinition(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class TransitionDefinitionOnMessageContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_transitionDefinitionOnMessage;
    }

	conditionalTransitionDefinition() {
	    return this.getTypedRuleContext(ConditionalTransitionDefinitionContext,0);
	};

	timeoutTransitionDefinition() {
	    return this.getTypedRuleContext(TimeoutTransitionDefinitionContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterTransitionDefinitionOnMessage(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitTransitionDefinitionOnMessage(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitTransitionDefinitionOnMessage(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class TimeoutTransitionDefinitionContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_timeoutTransitionDefinition;
        this.timeout = null;
    }

	ON() {
	    return this.getToken(graphParser.ON, 0);
	};

	TIMEOUT() {
	    return this.getToken(graphParser.TIMEOUT, 0);
	};

	anyNumber() {
	    return this.getTypedRuleContext(AnyNumberContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterTimeoutTransitionDefinition(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitTimeoutTransitionDefinition(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitTimeoutTransitionDefinition(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class ConditionalTransitionDefinitionContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_conditionalTransitionDefinition;
        this.condition = null;
        this.priority = null;
        this.confident = null;
        this._tagName = null;
        this.tags = [];
    }

	ON() {
	    return this.getToken(graphParser.ON, 0);
	};

	expression() {
	    return this.getTypedRuleContext(ExpressionContext,0);
	};

	PRIORITY() {
	    return this.getToken(graphParser.PRIORITY, 0);
	};

	WHEN() {
	    return this.getToken(graphParser.WHEN, 0);
	};

	TAGS() {
	    return this.getToken(graphParser.TAGS, 0);
	};

	COLON() {
	    return this.getToken(graphParser.COLON, 0);
	};

	DecimalIntegerLiteral() {
	    return this.getToken(graphParser.DecimalIntegerLiteral, 0);
	};

	CONFIDENT() {
	    return this.getToken(graphParser.CONFIDENT, 0);
	};

	tagName = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(TagNameContext);
	    } else {
	        return this.getTypedRuleContext(TagNameContext,i);
	    }
	};

	COMMA = function(i) {
		if(i===undefined) {
			i = null;
		}
	    if(i===null) {
	        return this.getTokens(graphParser.COMMA);
	    } else {
	        return this.getToken(graphParser.COMMA, i);
	    }
	};


	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterConditionalTransitionDefinition(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitConditionalTransitionDefinition(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitConditionalTransitionDefinition(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class UnconditionalTransitionDefinitionContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_unconditionalTransitionDefinition;
    }


	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterUnconditionalTransitionDefinition(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitUnconditionalTransitionDefinition(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitUnconditionalTransitionDefinition(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class DoSectionContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_doSection;
    }

	DO() {
	    return this.getToken(graphParser.DO, 0);
	};

	blockBody() {
	    return this.getTypedRuleContext(BlockBodyContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterDoSection(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitDoSection(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitDoSection(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class BlockBodyContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_blockBody;
        this._oneOrGroupedCommand = null;
        this.commands = [];
    }

	LCURL() {
	    return this.getToken(graphParser.LCURL, 0);
	};

	RCURL() {
	    return this.getToken(graphParser.RCURL, 0);
	};

	oneOrGroupedCommand = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(OneOrGroupedCommandContext);
	    } else {
	        return this.getTypedRuleContext(OneOrGroupedCommandContext,i);
	    }
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterBlockBody(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitBlockBody(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitBlockBody(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class OneOrGroupedCommandContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_oneOrGroupedCommand;
    }

	nodeCommand() {
	    return this.getTypedRuleContext(NodeCommandContext,0);
	};

	blockBody() {
	    return this.getTypedRuleContext(BlockBodyContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterOneOrGroupedCommand(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitOneOrGroupedCommand(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitOneOrGroupedCommand(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class NodeCommandContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_nodeCommand;
    }

	statement() {
	    return this.getTypedRuleContext(StatementContext,0);
	};

	conditionalCommand() {
	    return this.getTypedRuleContext(ConditionalCommandContext,0);
	};

	cycleCommand() {
	    return this.getTypedRuleContext(CycleCommandContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterNodeCommand(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitNodeCommand(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitNodeCommand(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class CycleCommandContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_cycleCommand;
    }

	foreachCommand() {
	    return this.getTypedRuleContext(ForeachCommandContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterCycleCommand(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitCycleCommand(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitCycleCommand(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class StatementContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_statement;
    }

	statementBody() {
	    return this.getTypedRuleContext(StatementBodyContext,0);
	};

	SEMICOLON() {
	    return this.getToken(graphParser.SEMICOLON, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterStatement(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitStatement(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitStatement(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class StatementBodyContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_statementBody;
    }

	waitCommand() {
	    return this.getTypedRuleContext(WaitCommandContext,0);
	};

	gotoCommand() {
	    return this.getTypedRuleContext(GotoCommandContext,0);
	};

	setCommand() {
	    return this.getTypedRuleContext(SetCommandContext,0);
	};

	returnCommand() {
	    return this.getTypedRuleContext(ReturnCommandContext,0);
	};

	exitCommand() {
	    return this.getTypedRuleContext(ExitCommandContext,0);
	};

	assignOpCommand() {
	    return this.getTypedRuleContext(AssignOpCommandContext,0);
	};

	variableDefinition() {
	    return this.getTypedRuleContext(VariableDefinitionContext,0);
	};

	logExpressionTypeCommand() {
	    return this.getTypedRuleContext(LogExpressionTypeCommandContext,0);
	};

	expression() {
	    return this.getTypedRuleContext(ExpressionContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterStatementBody(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitStatementBody(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitStatementBody(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class LogExpressionTypeCommandContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_logExpressionTypeCommand;
    }

	LOGEXPR() {
	    return this.getToken(graphParser.LOGEXPR, 0);
	};

	expression() {
	    return this.getTypedRuleContext(ExpressionContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterLogExpressionTypeCommand(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitLogExpressionTypeCommand(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitLogExpressionTypeCommand(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class DigressionControlContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_digressionControl;
    }


	 
		copyFrom(ctx) {
			super.copyFrom(ctx);
		}

}


class SaveDigressionContext extends DigressionControlContext {

    constructor(parser, ctx) {
        super(parser);
        this.spec = null;;
        super.copyFrom(ctx);
    }

	DIGRESSION() {
	    return this.getToken(graphParser.DIGRESSION, 0);
	};

	SAVE() {
	    return this.getToken(graphParser.SAVE, 0);
	};

	digressionSpec() {
	    return this.getTypedRuleContext(DigressionSpecContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterSaveDigression(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitSaveDigression(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitSaveDigression(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

graphParser.SaveDigressionContext = SaveDigressionContext;

class ApplyDigressionContext extends DigressionControlContext {

    constructor(parser, ctx) {
        super(parser);
        this.mask = null;;
        super.copyFrom(ctx);
    }

	DIGRESSION() {
	    return this.getToken(graphParser.DIGRESSION, 0);
	};

	APPLY() {
	    return this.getToken(graphParser.APPLY, 0);
	};

	expression() {
	    return this.getTypedRuleContext(ExpressionContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterApplyDigression(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitApplyDigression(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitApplyDigression(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

graphParser.ApplyDigressionContext = ApplyDigressionContext;

class EnableDigressionContext extends DigressionControlContext {

    constructor(parser, ctx) {
        super(parser);
        this.spec = null;;
        super.copyFrom(ctx);
    }

	DIGRESSION() {
	    return this.getToken(graphParser.DIGRESSION, 0);
	};

	ENABLE() {
	    return this.getToken(graphParser.ENABLE, 0);
	};

	digressionSpec() {
	    return this.getTypedRuleContext(DigressionSpecContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterEnableDigression(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitEnableDigression(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitEnableDigression(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

graphParser.EnableDigressionContext = EnableDigressionContext;

class DisableDigressionContext extends DigressionControlContext {

    constructor(parser, ctx) {
        super(parser);
        this.spec = null;;
        super.copyFrom(ctx);
    }

	DIGRESSION() {
	    return this.getToken(graphParser.DIGRESSION, 0);
	};

	DISABLE() {
	    return this.getToken(graphParser.DISABLE, 0);
	};

	digressionSpec() {
	    return this.getTypedRuleContext(DigressionSpecContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterDisableDigression(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitDisableDigression(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitDisableDigression(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

graphParser.DisableDigressionContext = DisableDigressionContext;

class DigressionSpecContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_digressionSpec;
        this._digressionId = null;
        this.list = [];
    }

	digressionId = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(DigressionIdContext);
	    } else {
	        return this.getTypedRuleContext(DigressionIdContext,i);
	    }
	};

	LCURL() {
	    return this.getToken(graphParser.LCURL, 0);
	};

	RCURL() {
	    return this.getToken(graphParser.RCURL, 0);
	};

	COMMA = function(i) {
		if(i===undefined) {
			i = null;
		}
	    if(i===null) {
	        return this.getTokens(graphParser.COMMA);
	    } else {
	        return this.getToken(graphParser.COMMA, i);
	    }
	};


	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterDigressionSpec(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitDigressionSpec(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitDigressionSpec(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class ExitCommandContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_exitCommand;
    }

	EXIT() {
	    return this.getToken(graphParser.EXIT, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterExitCommand(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitExitCommand(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitExitCommand(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class ReturnCommandContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_returnCommand;
        this.returnValue = null;
    }

	RETURN() {
	    return this.getToken(graphParser.RETURN, 0);
	};

	expression() {
	    return this.getTypedRuleContext(ExpressionContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterReturnCommand(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitReturnCommand(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitReturnCommand(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class VariableDefinitionContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_variableDefinition;
        this.type = null;
        this.value = null;
    }

	variableDeclaration() {
	    return this.getTypedRuleContext(VariableDeclarationContext,0);
	};

	COLON() {
	    return this.getToken(graphParser.COLON, 0);
	};

	EQUALSIGN() {
	    return this.getToken(graphParser.EQUALSIGN, 0);
	};

	typeDescription() {
	    return this.getTypedRuleContext(TypeDescriptionContext,0);
	};

	expression() {
	    return this.getTypedRuleContext(ExpressionContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterVariableDefinition(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitVariableDefinition(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitVariableDefinition(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class VariableDeclarationContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_variableDeclaration;
    }

	VAR() {
	    return this.getToken(graphParser.VAR, 0);
	};

	variableName() {
	    return this.getTypedRuleContext(VariableNameContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterVariableDeclaration(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitVariableDeclaration(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitVariableDeclaration(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class TypeDescriptionContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_typeDescription;
    }

	type_() {
	    return this.getTypedRuleContext(Type_Context,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterTypeDescription(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitTypeDescription(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitTypeDescription(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class ConditionalCommandContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_conditionalCommand;
        this.condition = null;
        this.thenCommand = null;
        this.elseCommand = null;
    }

	IF() {
	    return this.getToken(graphParser.IF, 0);
	};

	LPAREN() {
	    return this.getToken(graphParser.LPAREN, 0);
	};

	RPAREN() {
	    return this.getToken(graphParser.RPAREN, 0);
	};

	expression() {
	    return this.getTypedRuleContext(ExpressionContext,0);
	};

	oneOrGroupedCommand = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(OneOrGroupedCommandContext);
	    } else {
	        return this.getTypedRuleContext(OneOrGroupedCommandContext,i);
	    }
	};

	ELSE() {
	    return this.getToken(graphParser.ELSE, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterConditionalCommand(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitConditionalCommand(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitConditionalCommand(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class ForeachCommandContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_foreachCommand;
        this.container = null;
        this.body = null;
    }

	FOR() {
	    return this.getToken(graphParser.FOR, 0);
	};

	LPAREN() {
	    return this.getToken(graphParser.LPAREN, 0);
	};

	variableDeclaration() {
	    return this.getTypedRuleContext(VariableDeclarationContext,0);
	};

	IN() {
	    return this.getToken(graphParser.IN, 0);
	};

	RPAREN() {
	    return this.getToken(graphParser.RPAREN, 0);
	};

	expression() {
	    return this.getTypedRuleContext(ExpressionContext,0);
	};

	oneOrGroupedCommand() {
	    return this.getTypedRuleContext(OneOrGroupedCommandContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterForeachCommand(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitForeachCommand(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitForeachCommand(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class WaitCommandContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_waitCommand;
    }

	WAIT() {
	    return this.getToken(graphParser.WAIT, 0);
	};

	activatedTransitionList() {
	    return this.getTypedRuleContext(ActivatedTransitionListContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterWaitCommand(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitWaitCommand(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitWaitCommand(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class ActivatedTransitionListContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_activatedTransitionList;
    }


	 
		copyFrom(ctx) {
			super.copyFrom(ctx);
		}

}


class SelectedActivatedTransitionListContext extends ActivatedTransitionListContext {

    constructor(parser, ctx) {
        super(parser);
        super.copyFrom(ctx);
    }

	LCURL() {
	    return this.getToken(graphParser.LCURL, 0);
	};

	transitionName = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(TransitionNameContext);
	    } else {
	        return this.getTypedRuleContext(TransitionNameContext,i);
	    }
	};

	RCURL() {
	    return this.getToken(graphParser.RCURL, 0);
	};

	COMMA = function(i) {
		if(i===undefined) {
			i = null;
		}
	    if(i===null) {
	        return this.getTokens(graphParser.COMMA);
	    } else {
	        return this.getToken(graphParser.COMMA, i);
	    }
	};


	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterSelectedActivatedTransitionList(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitSelectedActivatedTransitionList(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitSelectedActivatedTransitionList(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

graphParser.SelectedActivatedTransitionListContext = SelectedActivatedTransitionListContext;

class AllActivatedTransitionListContext extends ActivatedTransitionListContext {

    constructor(parser, ctx) {
        super(parser);
        super.copyFrom(ctx);
    }

	STAR() {
	    return this.getToken(graphParser.STAR, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterAllActivatedTransitionList(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitAllActivatedTransitionList(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitAllActivatedTransitionList(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

graphParser.AllActivatedTransitionListContext = AllActivatedTransitionListContext;

class GotoCommandContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_gotoCommand;
    }

	GOTO() {
	    return this.getToken(graphParser.GOTO, 0);
	};

	transitionName() {
	    return this.getTypedRuleContext(TransitionNameContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterGotoCommand(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitGotoCommand(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitGotoCommand(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class SetCommandContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_setCommand;
        this.ref = null;
        this.rightPartExpression = null;
    }

	SET() {
	    return this.getToken(graphParser.SET, 0);
	};

	EQUALSIGN() {
	    return this.getToken(graphParser.EQUALSIGN, 0);
	};

	lValue() {
	    return this.getTypedRuleContext(LValueContext,0);
	};

	expression() {
	    return this.getTypedRuleContext(ExpressionContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterSetCommand(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitSetCommand(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitSetCommand(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class AssignOpCommandContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_assignOpCommand;
        this.ref = null;
        this.op = null;
        this.rightPartExpression = null;
    }

	SET() {
	    return this.getToken(graphParser.SET, 0);
	};

	lValue() {
	    return this.getTypedRuleContext(LValueContext,0);
	};

	AddOp() {
	    return this.getToken(graphParser.AddOp, 0);
	};

	expression() {
	    return this.getTypedRuleContext(ExpressionContext,0);
	};

	RemoveOp() {
	    return this.getToken(graphParser.RemoveOp, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterAssignOpCommand(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitAssignOpCommand(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitAssignOpCommand(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class LValueContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_lValue;
    }


	 
		copyFrom(ctx) {
			super.copyFrom(ctx);
		}

}


class LValueReferenceExpressionContext extends LValueContext {

    constructor(parser, ctx) {
        super(parser);
        super.copyFrom(ctx);
    }

	reference() {
	    return this.getTypedRuleContext(ReferenceContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterLValueReferenceExpression(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitLValueReferenceExpression(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitLValueReferenceExpression(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

graphParser.LValueReferenceExpressionContext = LValueReferenceExpressionContext;

class LValueParenthesizedExpressionContext extends LValueContext {

    constructor(parser, ctx) {
        super(parser);
        super.copyFrom(ctx);
    }

	LPAREN() {
	    return this.getToken(graphParser.LPAREN, 0);
	};

	lValue() {
	    return this.getTypedRuleContext(LValueContext,0);
	};

	RPAREN() {
	    return this.getToken(graphParser.RPAREN, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterLValueParenthesizedExpression(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitLValueParenthesizedExpression(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitLValueParenthesizedExpression(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

graphParser.LValueParenthesizedExpressionContext = LValueParenthesizedExpressionContext;

class LValueDotAccessExpressionContext extends LValueContext {

    constructor(parser, ctx) {
        super(parser);
        this.parent = null;;
        this.member = null;;
        super.copyFrom(ctx);
    }

	PERIOD() {
	    return this.getToken(graphParser.PERIOD, 0);
	};

	lValue() {
	    return this.getTypedRuleContext(LValueContext,0);
	};

	memberName() {
	    return this.getTypedRuleContext(MemberNameContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterLValueDotAccessExpression(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitLValueDotAccessExpression(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitLValueDotAccessExpression(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

graphParser.LValueDotAccessExpressionContext = LValueDotAccessExpressionContext;

class LValueLiteralIndexAccessExpressionContext extends LValueContext {

    constructor(parser, ctx) {
        super(parser);
        this.parent = null;;
        this.index = null;;
        super.copyFrom(ctx);
    }

	LBRACKET() {
	    return this.getToken(graphParser.LBRACKET, 0);
	};

	RBRACKET() {
	    return this.getToken(graphParser.RBRACKET, 0);
	};

	lValue() {
	    return this.getTypedRuleContext(LValueContext,0);
	};

	DecimalIntegerLiteral() {
	    return this.getToken(graphParser.DecimalIntegerLiteral, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterLValueLiteralIndexAccessExpression(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitLValueLiteralIndexAccessExpression(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitLValueLiteralIndexAccessExpression(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

graphParser.LValueLiteralIndexAccessExpressionContext = LValueLiteralIndexAccessExpressionContext;

class LValueExpressionIndexAccessExpressionContext extends LValueContext {

    constructor(parser, ctx) {
        super(parser);
        this.parent = null;;
        this.index = null;;
        super.copyFrom(ctx);
    }

	LBRACKET() {
	    return this.getToken(graphParser.LBRACKET, 0);
	};

	RBRACKET() {
	    return this.getToken(graphParser.RBRACKET, 0);
	};

	lValue() {
	    return this.getTypedRuleContext(LValueContext,0);
	};

	expression() {
	    return this.getTypedRuleContext(ExpressionContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterLValueExpressionIndexAccessExpression(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitLValueExpressionIndexAccessExpression(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitLValueExpressionIndexAccessExpression(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

graphParser.LValueExpressionIndexAccessExpressionContext = LValueExpressionIndexAccessExpressionContext;

class ExpressionContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_expression;
    }


	 
		copyFrom(ctx) {
			super.copyFrom(ctx);
		}

}


class TernaryOpExpressionContext extends ExpressionContext {

    constructor(parser, ctx) {
        super(parser);
        this.condition = null;;
        this.thenExpression = null;;
        this.elseExpression = null;;
        super.copyFrom(ctx);
    }

	QMARK() {
	    return this.getToken(graphParser.QMARK, 0);
	};

	COLON() {
	    return this.getToken(graphParser.COLON, 0);
	};

	expression = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(ExpressionContext);
	    } else {
	        return this.getTypedRuleContext(ExpressionContext,i);
	    }
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterTernaryOpExpression(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitTernaryOpExpression(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitTernaryOpExpression(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

graphParser.TernaryOpExpressionContext = TernaryOpExpressionContext;

class ExpressionIndexAccessExpressionContext extends ExpressionContext {

    constructor(parser, ctx) {
        super(parser);
        this.parent = null;;
        this.index = null;;
        super.copyFrom(ctx);
    }

	LBRACKET() {
	    return this.getToken(graphParser.LBRACKET, 0);
	};

	RBRACKET() {
	    return this.getToken(graphParser.RBRACKET, 0);
	};

	expression = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(ExpressionContext);
	    } else {
	        return this.getTypedRuleContext(ExpressionContext,i);
	    }
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterExpressionIndexAccessExpression(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitExpressionIndexAccessExpression(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitExpressionIndexAccessExpression(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

graphParser.ExpressionIndexAccessExpressionContext = ExpressionIndexAccessExpressionContext;

class NullCollateExpressionContext extends ExpressionContext {

    constructor(parser, ctx) {
        super(parser);
        this.nullable = null;;
        this.defaultValue = null;;
        super.copyFrom(ctx);
    }

	QMARK = function(i) {
		if(i===undefined) {
			i = null;
		}
	    if(i===null) {
	        return this.getTokens(graphParser.QMARK);
	    } else {
	        return this.getToken(graphParser.QMARK, i);
	    }
	};


	expression = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(ExpressionContext);
	    } else {
	        return this.getTypedRuleContext(ExpressionContext,i);
	    }
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterNullCollateExpression(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitNullCollateExpression(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitNullCollateExpression(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

graphParser.NullCollateExpressionContext = NullCollateExpressionContext;

class BooleanLiteralExpressionContext extends ExpressionContext {

    constructor(parser, ctx) {
        super(parser);
        super.copyFrom(ctx);
    }

	BooleanLiteral() {
	    return this.getToken(graphParser.BooleanLiteral, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterBooleanLiteralExpression(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitBooleanLiteralExpression(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitBooleanLiteralExpression(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

graphParser.BooleanLiteralExpressionContext = BooleanLiteralExpressionContext;

class NumberLiteralExpressionContext extends ExpressionContext {

    constructor(parser, ctx) {
        super(parser);
        super.copyFrom(ctx);
    }

	anyNumber() {
	    return this.getTypedRuleContext(AnyNumberContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterNumberLiteralExpression(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitNumberLiteralExpression(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitNumberLiteralExpression(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

graphParser.NumberLiteralExpressionContext = NumberLiteralExpressionContext;

class BinaryOpExpressionContext extends ExpressionContext {

    constructor(parser, ctx) {
        super(parser);
        this.left = null;;
        this.op = null;;
        this.right = null;;
        super.copyFrom(ctx);
    }

	expression = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(ExpressionContext);
	    } else {
	        return this.getTypedRuleContext(ExpressionContext,i);
	    }
	};

	STAR() {
	    return this.getToken(graphParser.STAR, 0);
	};

	SLASH() {
	    return this.getToken(graphParser.SLASH, 0);
	};

	PERCENT() {
	    return this.getToken(graphParser.PERCENT, 0);
	};

	PLUS() {
	    return this.getToken(graphParser.PLUS, 0);
	};

	MINUS() {
	    return this.getToken(graphParser.MINUS, 0);
	};

	LANGLE() {
	    return this.getToken(graphParser.LANGLE, 0);
	};

	LessEqualOp() {
	    return this.getToken(graphParser.LessEqualOp, 0);
	};

	RANGLE() {
	    return this.getToken(graphParser.RANGLE, 0);
	};

	GreaterEqualOp() {
	    return this.getToken(graphParser.GreaterEqualOp, 0);
	};

	EqualOp() {
	    return this.getToken(graphParser.EqualOp, 0);
	};

	NotEqualOp() {
	    return this.getToken(graphParser.NotEqualOp, 0);
	};

	AndOp() {
	    return this.getToken(graphParser.AndOp, 0);
	};

	OrOp() {
	    return this.getToken(graphParser.OrOp, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterBinaryOpExpression(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitBinaryOpExpression(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitBinaryOpExpression(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

graphParser.BinaryOpExpressionContext = BinaryOpExpressionContext;

class OptionalCallDotAccessExpressionContext extends ExpressionContext {

    constructor(parser, ctx) {
        super(parser);
        this.parent = null;;
        this.member = null;;
        this.arguments = null;;
        super.copyFrom(ctx);
    }

	QMARK() {
	    return this.getToken(graphParser.QMARK, 0);
	};

	PERIOD() {
	    return this.getToken(graphParser.PERIOD, 0);
	};

	expression() {
	    return this.getTypedRuleContext(ExpressionContext,0);
	};

	memberName() {
	    return this.getTypedRuleContext(MemberNameContext,0);
	};

	argumentInvokation() {
	    return this.getTypedRuleContext(ArgumentInvokationContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterOptionalCallDotAccessExpression(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitOptionalCallDotAccessExpression(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitOptionalCallDotAccessExpression(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

graphParser.OptionalCallDotAccessExpressionContext = OptionalCallDotAccessExpressionContext;

class CastExpressionContext extends ExpressionContext {

    constructor(parser, ctx) {
        super(parser);
        super.copyFrom(ctx);
    }

	expression() {
	    return this.getTypedRuleContext(ExpressionContext,0);
	};

	AS() {
	    return this.getToken(graphParser.AS, 0);
	};

	typeDescription() {
	    return this.getTypedRuleContext(TypeDescriptionContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterCastExpression(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitCastExpression(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitCastExpression(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

graphParser.CastExpressionContext = CastExpressionContext;

class BuiltinFunctionCallExpressionContext extends ExpressionContext {

    constructor(parser, ctx) {
        super(parser);
        super.copyFrom(ctx);
    }

	builtinFunctionCall() {
	    return this.getTypedRuleContext(BuiltinFunctionCallContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterBuiltinFunctionCallExpression(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitBuiltinFunctionCallExpression(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitBuiltinFunctionCallExpression(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

graphParser.BuiltinFunctionCallExpressionContext = BuiltinFunctionCallExpressionContext;

class ParenthesizedExpressionContext extends ExpressionContext {

    constructor(parser, ctx) {
        super(parser);
        super.copyFrom(ctx);
    }

	LPAREN() {
	    return this.getToken(graphParser.LPAREN, 0);
	};

	expression() {
	    return this.getTypedRuleContext(ExpressionContext,0);
	};

	RPAREN() {
	    return this.getToken(graphParser.RPAREN, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterParenthesizedExpression(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitParenthesizedExpression(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitParenthesizedExpression(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

graphParser.ParenthesizedExpressionContext = ParenthesizedExpressionContext;

class CallDotAccessExpressionContext extends ExpressionContext {

    constructor(parser, ctx) {
        super(parser);
        this.parent = null;;
        this.member = null;;
        this.arguments = null;;
        super.copyFrom(ctx);
    }

	PERIOD() {
	    return this.getToken(graphParser.PERIOD, 0);
	};

	expression() {
	    return this.getTypedRuleContext(ExpressionContext,0);
	};

	memberName() {
	    return this.getTypedRuleContext(MemberNameContext,0);
	};

	argumentInvokation() {
	    return this.getTypedRuleContext(ArgumentInvokationContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterCallDotAccessExpression(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitCallDotAccessExpression(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitCallDotAccessExpression(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

graphParser.CallDotAccessExpressionContext = CallDotAccessExpressionContext;

class NullLiteralExpressionContext extends ExpressionContext {

    constructor(parser, ctx) {
        super(parser);
        super.copyFrom(ctx);
    }

	NULL() {
	    return this.getToken(graphParser.NULL, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterNullLiteralExpression(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitNullLiteralExpression(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitNullLiteralExpression(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

graphParser.NullLiteralExpressionContext = NullLiteralExpressionContext;

class IsNullExpressionContext extends ExpressionContext {

    constructor(parser, ctx) {
        super(parser);
        super.copyFrom(ctx);
    }

	expression() {
	    return this.getTypedRuleContext(ExpressionContext,0);
	};

	IS() {
	    return this.getToken(graphParser.IS, 0);
	};

	NULL() {
	    return this.getToken(graphParser.NULL, 0);
	};

	NOT() {
	    return this.getToken(graphParser.NOT, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterIsNullExpression(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitIsNullExpression(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitIsNullExpression(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

graphParser.IsNullExpressionContext = IsNullExpressionContext;

class DigressionControlExpressionContext extends ExpressionContext {

    constructor(parser, ctx) {
        super(parser);
        super.copyFrom(ctx);
    }

	digressionControl() {
	    return this.getTypedRuleContext(DigressionControlContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterDigressionControlExpression(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitDigressionControlExpression(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitDigressionControlExpression(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

graphParser.DigressionControlExpressionContext = DigressionControlExpressionContext;

class TupleLiteralExpressionContext extends ExpressionContext {

    constructor(parser, ctx) {
        super(parser);
        this._expression = null;;
        this.elements = [];;
        super.copyFrom(ctx);
    }

	LBRACKET() {
	    return this.getToken(graphParser.LBRACKET, 0);
	};

	RBRACKET() {
	    return this.getToken(graphParser.RBRACKET, 0);
	};

	COMMA = function(i) {
		if(i===undefined) {
			i = null;
		}
	    if(i===null) {
	        return this.getTokens(graphParser.COMMA);
	    } else {
	        return this.getToken(graphParser.COMMA, i);
	    }
	};


	expression = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(ExpressionContext);
	    } else {
	        return this.getTypedRuleContext(ExpressionContext,i);
	    }
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterTupleLiteralExpression(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitTupleLiteralExpression(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitTupleLiteralExpression(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

graphParser.TupleLiteralExpressionContext = TupleLiteralExpressionContext;

class BlockFunctionCallExpressionContext extends ExpressionContext {

    constructor(parser, ctx) {
        super(parser);
        super.copyFrom(ctx);
    }

	blockFunctionCall() {
	    return this.getTypedRuleContext(BlockFunctionCallContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterBlockFunctionCallExpression(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitBlockFunctionCallExpression(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitBlockFunctionCallExpression(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

graphParser.BlockFunctionCallExpressionContext = BlockFunctionCallExpressionContext;

class ReferenceExpressionContext extends ExpressionContext {

    constructor(parser, ctx) {
        super(parser);
        super.copyFrom(ctx);
    }

	reference() {
	    return this.getTypedRuleContext(ReferenceContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterReferenceExpression(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitReferenceExpression(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitReferenceExpression(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

graphParser.ReferenceExpressionContext = ReferenceExpressionContext;

class OptionalDotAccessExpressionContext extends ExpressionContext {

    constructor(parser, ctx) {
        super(parser);
        this.parent = null;;
        this.member = null;;
        super.copyFrom(ctx);
    }

	QMARK() {
	    return this.getToken(graphParser.QMARK, 0);
	};

	PERIOD() {
	    return this.getToken(graphParser.PERIOD, 0);
	};

	expression() {
	    return this.getTypedRuleContext(ExpressionContext,0);
	};

	memberName() {
	    return this.getTypedRuleContext(MemberNameContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterOptionalDotAccessExpression(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitOptionalDotAccessExpression(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitOptionalDotAccessExpression(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

graphParser.OptionalDotAccessExpressionContext = OptionalDotAccessExpressionContext;

class ObjectLiteralExpressionContext extends ExpressionContext {

    constructor(parser, ctx) {
        super(parser);
        this._objectPropertyExpressionPart = null;;
        this.fields = [];;
        super.copyFrom(ctx);
    }

	LCURL() {
	    return this.getToken(graphParser.LCURL, 0);
	};

	RCURL() {
	    return this.getToken(graphParser.RCURL, 0);
	};

	objectPropertyExpressionPart = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(ObjectPropertyExpressionPartContext);
	    } else {
	        return this.getTypedRuleContext(ObjectPropertyExpressionPartContext,i);
	    }
	};

	COMMA = function(i) {
		if(i===undefined) {
			i = null;
		}
	    if(i===null) {
	        return this.getTokens(graphParser.COMMA);
	    } else {
	        return this.getToken(graphParser.COMMA, i);
	    }
	};


	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterObjectLiteralExpression(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitObjectLiteralExpression(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitObjectLiteralExpression(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

graphParser.ObjectLiteralExpressionContext = ObjectLiteralExpressionContext;

class LiteralIndexAccessExpressionContext extends ExpressionContext {

    constructor(parser, ctx) {
        super(parser);
        this.parent = null;;
        this.index = null;;
        super.copyFrom(ctx);
    }

	LBRACKET() {
	    return this.getToken(graphParser.LBRACKET, 0);
	};

	RBRACKET() {
	    return this.getToken(graphParser.RBRACKET, 0);
	};

	expression() {
	    return this.getTypedRuleContext(ExpressionContext,0);
	};

	DecimalIntegerLiteral() {
	    return this.getToken(graphParser.DecimalIntegerLiteral, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterLiteralIndexAccessExpression(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitLiteralIndexAccessExpression(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitLiteralIndexAccessExpression(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

graphParser.LiteralIndexAccessExpressionContext = LiteralIndexAccessExpressionContext;

class ExternFunctionCallExpressionContext extends ExpressionContext {

    constructor(parser, ctx) {
        super(parser);
        super.copyFrom(ctx);
    }

	externalFunctionCall() {
	    return this.getTypedRuleContext(ExternalFunctionCallContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterExternFunctionCallExpression(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitExternFunctionCallExpression(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitExternFunctionCallExpression(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

graphParser.ExternFunctionCallExpressionContext = ExternFunctionCallExpressionContext;

class StringLiteralExpressionContext extends ExpressionContext {

    constructor(parser, ctx) {
        super(parser);
        super.copyFrom(ctx);
    }

	StringLiteral() {
	    return this.getToken(graphParser.StringLiteral, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterStringLiteralExpression(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitStringLiteralExpression(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitStringLiteralExpression(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

graphParser.StringLiteralExpressionContext = StringLiteralExpressionContext;

class SlotFillingCallExpressionContext extends ExpressionContext {

    constructor(parser, ctx) {
        super(parser);
        super.copyFrom(ctx);
    }

	slotFillingCall() {
	    return this.getTypedRuleContext(SlotFillingCallContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterSlotFillingCallExpression(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitSlotFillingCallExpression(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitSlotFillingCallExpression(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

graphParser.SlotFillingCallExpressionContext = SlotFillingCallExpressionContext;

class IsDefinedExpressionContext extends ExpressionContext {

    constructor(parser, ctx) {
        super(parser);
        super.copyFrom(ctx);
    }

	reference() {
	    return this.getTypedRuleContext(ReferenceContext,0);
	};

	IS() {
	    return this.getToken(graphParser.IS, 0);
	};

	DEFINED() {
	    return this.getToken(graphParser.DEFINED, 0);
	};

	NOT() {
	    return this.getToken(graphParser.NOT, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterIsDefinedExpression(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitIsDefinedExpression(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitIsDefinedExpression(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

graphParser.IsDefinedExpressionContext = IsDefinedExpressionContext;

class UnaryOpExpressionContext extends ExpressionContext {

    constructor(parser, ctx) {
        super(parser);
        this.op = null;;
        super.copyFrom(ctx);
    }

	expression() {
	    return this.getTypedRuleContext(ExpressionContext,0);
	};

	PLUS() {
	    return this.getToken(graphParser.PLUS, 0);
	};

	MINUS() {
	    return this.getToken(graphParser.MINUS, 0);
	};

	NotOperator() {
	    return this.getToken(graphParser.NotOperator, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterUnaryOpExpression(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitUnaryOpExpression(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitUnaryOpExpression(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

graphParser.UnaryOpExpressionContext = UnaryOpExpressionContext;

class DotAccessExpressionContext extends ExpressionContext {

    constructor(parser, ctx) {
        super(parser);
        this.parent = null;;
        this.member = null;;
        super.copyFrom(ctx);
    }

	PERIOD() {
	    return this.getToken(graphParser.PERIOD, 0);
	};

	expression() {
	    return this.getTypedRuleContext(ExpressionContext,0);
	};

	memberName() {
	    return this.getTypedRuleContext(MemberNameContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterDotAccessExpression(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitDotAccessExpression(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitDotAccessExpression(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

graphParser.DotAccessExpressionContext = DotAccessExpressionContext;

class ObjectPropertyExpressionPartContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_objectPropertyExpressionPart;
        this.name = null;
    }

	COLON() {
	    return this.getToken(graphParser.COLON, 0);
	};

	expression() {
	    return this.getTypedRuleContext(ExpressionContext,0);
	};

	propertyName() {
	    return this.getTypedRuleContext(PropertyNameContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterObjectPropertyExpressionPart(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitObjectPropertyExpressionPart(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitObjectPropertyExpressionPart(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class ExternalFunctionCallContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_externalFunctionCall;
        this.name = null;
        this.arguments = null;
    }

	EXTERNAL() {
	    return this.getToken(graphParser.EXTERNAL, 0);
	};

	externalFunctionName() {
	    return this.getTypedRuleContext(ExternalFunctionNameContext,0);
	};

	argumentInvokation() {
	    return this.getTypedRuleContext(ArgumentInvokationContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterExternalFunctionCall(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitExternalFunctionCall(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitExternalFunctionCall(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class BlockFunctionCallContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_blockFunctionCall;
        this.name = null;
        this.arguments = null;
    }

	BLOCKCALL() {
	    return this.getToken(graphParser.BLOCKCALL, 0);
	};

	blockId() {
	    return this.getTypedRuleContext(BlockIdContext,0);
	};

	argumentInvokation() {
	    return this.getTypedRuleContext(ArgumentInvokationContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterBlockFunctionCall(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitBlockFunctionCall(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitBlockFunctionCall(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class SlotFillingCallContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_slotFillingCall;
        this.name = null;
        this.arguments = null;
    }

	FILL() {
	    return this.getToken(graphParser.FILL, 0);
	};

	typeAlias() {
	    return this.getTypedRuleContext(TypeAliasContext,0);
	};

	argumentInvokation() {
	    return this.getTypedRuleContext(ArgumentInvokationContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterSlotFillingCall(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitSlotFillingCall(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitSlotFillingCall(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class BuiltinFunctionCallContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_builtinFunctionCall;
        this.name = null;
        this.arguments = null;
    }

	BuiltinMark() {
	    return this.getToken(graphParser.BuiltinMark, 0);
	};

	builtinFunctionName() {
	    return this.getTypedRuleContext(BuiltinFunctionNameContext,0);
	};

	argumentInvokation() {
	    return this.getTypedRuleContext(ArgumentInvokationContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterBuiltinFunctionCall(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitBuiltinFunctionCall(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitBuiltinFunctionCall(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class ArgumentInvokationContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_argumentInvokation;
    }

	LPAREN() {
	    return this.getToken(graphParser.LPAREN, 0);
	};

	argumentList() {
	    return this.getTypedRuleContext(ArgumentListContext,0);
	};

	RPAREN() {
	    return this.getToken(graphParser.RPAREN, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterArgumentInvokation(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitArgumentInvokation(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitArgumentInvokation(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class ArgumentListContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_argumentList;
        this.positional = null;
        this.named = null;
    }

	positionalArgumentList() {
	    return this.getTypedRuleContext(PositionalArgumentListContext,0);
	};

	COMMA() {
	    return this.getToken(graphParser.COMMA, 0);
	};

	namedArgumentList() {
	    return this.getTypedRuleContext(NamedArgumentListContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterArgumentList(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitArgumentList(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitArgumentList(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class NamedArgumentListContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_namedArgumentList;
        this._namedArgument = null;
        this.arguments = [];
    }

	namedArgument = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(NamedArgumentContext);
	    } else {
	        return this.getTypedRuleContext(NamedArgumentContext,i);
	    }
	};

	COMMA = function(i) {
		if(i===undefined) {
			i = null;
		}
	    if(i===null) {
	        return this.getTokens(graphParser.COMMA);
	    } else {
	        return this.getToken(graphParser.COMMA, i);
	    }
	};


	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterNamedArgumentList(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitNamedArgumentList(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitNamedArgumentList(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class PositionalArgumentListContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_positionalArgumentList;
        this._positionalArgument = null;
        this.arguments = [];
    }

	positionalArgument = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(PositionalArgumentContext);
	    } else {
	        return this.getTypedRuleContext(PositionalArgumentContext,i);
	    }
	};

	COMMA = function(i) {
		if(i===undefined) {
			i = null;
		}
	    if(i===null) {
	        return this.getTokens(graphParser.COMMA);
	    } else {
	        return this.getToken(graphParser.COMMA, i);
	    }
	};


	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterPositionalArgumentList(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitPositionalArgumentList(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitPositionalArgumentList(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class PositionalArgumentContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_positionalArgument;
    }

	expression() {
	    return this.getTypedRuleContext(ExpressionContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterPositionalArgument(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitPositionalArgument(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitPositionalArgument(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class NamedArgumentContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_namedArgument;
    }

	argumentName() {
	    return this.getTypedRuleContext(ArgumentNameContext,0);
	};

	COLON() {
	    return this.getToken(graphParser.COLON, 0);
	};

	expression() {
	    return this.getTypedRuleContext(ExpressionContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterNamedArgument(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitNamedArgument(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitNamedArgument(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class ParameterListContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_parameterList;
        this._parameter = null;
        this.parameters = [];
    }

	parameter = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(ParameterContext);
	    } else {
	        return this.getTypedRuleContext(ParameterContext,i);
	    }
	};

	COMMA = function(i) {
		if(i===undefined) {
			i = null;
		}
	    if(i===null) {
	        return this.getTokens(graphParser.COMMA);
	    } else {
	        return this.getToken(graphParser.COMMA, i);
	    }
	};


	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterParameterList(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitParameterList(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitParameterList(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class ParameterContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_parameter;
        this.name = null;
        this.type = null;
        this.defaultValue = null;
    }

	COLON() {
	    return this.getToken(graphParser.COLON, 0);
	};

	parameterName() {
	    return this.getTypedRuleContext(ParameterNameContext,0);
	};

	typeDescription() {
	    return this.getTypedRuleContext(TypeDescriptionContext,0);
	};

	EQUALSIGN() {
	    return this.getToken(graphParser.EQUALSIGN, 0);
	};

	expression() {
	    return this.getTypedRuleContext(ExpressionContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterParameter(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitParameter(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitParameter(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class ReferenceContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_reference;
    }


	 
		copyFrom(ctx) {
			super.copyFrom(ctx);
		}

}


class LocalReferenceContext extends ReferenceContext {

    constructor(parser, ctx) {
        super(parser);
        this.refName = null;;
        super.copyFrom(ctx);
    }

	referenceName() {
	    return this.getTypedRuleContext(ReferenceNameContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterLocalReference(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitLocalReference(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitLocalReference(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

graphParser.LocalReferenceContext = LocalReferenceContext;

class DigressionReferenceContext extends ReferenceContext {

    constructor(parser, ctx) {
        super(parser);
        this.name = null;;
        this.shared = null;;
        this.refName = null;;
        super.copyFrom(ctx);
    }

	DIGRESSION() {
	    return this.getToken(graphParser.DIGRESSION, 0);
	};

	PERIOD = function(i) {
		if(i===undefined) {
			i = null;
		}
	    if(i===null) {
	        return this.getTokens(graphParser.PERIOD);
	    } else {
	        return this.getToken(graphParser.PERIOD, i);
	    }
	};


	digressionId() {
	    return this.getTypedRuleContext(DigressionIdContext,0);
	};

	SHARED() {
	    return this.getToken(graphParser.SHARED, 0);
	};

	referenceName() {
	    return this.getTypedRuleContext(ReferenceNameContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterDigressionReference(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitDigressionReference(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitDigressionReference(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

graphParser.DigressionReferenceContext = DigressionReferenceContext;

class ContextReferenceContext extends ReferenceContext {

    constructor(parser, ctx) {
        super(parser);
        this.refName = null;;
        super.copyFrom(ctx);
    }

	ContextMark() {
	    return this.getToken(graphParser.ContextMark, 0);
	};

	referenceName() {
	    return this.getTypedRuleContext(ReferenceNameContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterContextReference(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitContextReference(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitContextReference(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

graphParser.ContextReferenceContext = ContextReferenceContext;

class BuiltinReferenceContext extends ReferenceContext {

    constructor(parser, ctx) {
        super(parser);
        this.refName = null;;
        super.copyFrom(ctx);
    }

	BuiltinMark() {
	    return this.getToken(graphParser.BuiltinMark, 0);
	};

	referenceName() {
	    return this.getTypedRuleContext(ReferenceNameContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterBuiltinReference(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitBuiltinReference(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitBuiltinReference(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

graphParser.BuiltinReferenceContext = BuiltinReferenceContext;

class WhenIdContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_whenId;
    }

	ID() {
	    return this.getToken(graphParser.ID, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterWhenId(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitWhenId(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitWhenId(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class NodeIdContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_nodeId;
    }

	ID() {
	    return this.getToken(graphParser.ID, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterNodeId(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitNodeId(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitNodeId(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class BlockIdContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_blockId;
    }

	ID() {
	    return this.getToken(graphParser.ID, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterBlockId(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitBlockId(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitBlockId(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class DigressionIdContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_digressionId;
    }

	ID() {
	    return this.getToken(graphParser.ID, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterDigressionId(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitDigressionId(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitDigressionId(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class TransitionNameContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_transitionName;
    }

	ID() {
	    return this.getToken(graphParser.ID, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterTransitionName(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitTransitionName(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitTransitionName(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class ReferenceNameContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_referenceName;
    }

	ID() {
	    return this.getToken(graphParser.ID, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterReferenceName(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitReferenceName(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitReferenceName(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class MemberNameContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_memberName;
    }

	ID() {
	    return this.getToken(graphParser.ID, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterMemberName(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitMemberName(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitMemberName(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class TagNameContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_tagName;
    }

	ID() {
	    return this.getToken(graphParser.ID, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterTagName(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitTagName(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitTagName(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class FactNameContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_factName;
    }

	ID() {
	    return this.getToken(graphParser.ID, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterFactName(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitFactName(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitFactName(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class FieldNameContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_fieldName;
    }

	ID() {
	    return this.getToken(graphParser.ID, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterFieldName(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitFieldName(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitFieldName(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class VariableNameContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_variableName;
    }

	ID() {
	    return this.getToken(graphParser.ID, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterVariableName(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitVariableName(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitVariableName(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class ArgumentNameContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_argumentName;
    }

	ID() {
	    return this.getToken(graphParser.ID, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterArgumentName(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitArgumentName(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitArgumentName(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class ParameterNameContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_parameterName;
    }

	ID() {
	    return this.getToken(graphParser.ID, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterParameterName(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitParameterName(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitParameterName(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class PropertyNameContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_propertyName;
    }

	ID() {
	    return this.getToken(graphParser.ID, 0);
	};

	StringLiteral() {
	    return this.getToken(graphParser.StringLiteral, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterPropertyName(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitPropertyName(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitPropertyName(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class BuiltinFunctionNameContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_builtinFunctionName;
    }

	ID() {
	    return this.getToken(graphParser.ID, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterBuiltinFunctionName(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitBuiltinFunctionName(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitBuiltinFunctionName(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class ExternalFunctionNameContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_externalFunctionName;
    }

	ID() {
	    return this.getToken(graphParser.ID, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterExternalFunctionName(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitExternalFunctionName(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitExternalFunctionName(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class FunctionNameContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_functionName;
    }

	ID() {
	    return this.getToken(graphParser.ID, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterFunctionName(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitFunctionName(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitFunctionName(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class AnyNumberContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_anyNumber;
    }

	DecimalLiteral() {
	    return this.getToken(graphParser.DecimalLiteral, 0);
	};

	DecimalIntegerLiteral() {
	    return this.getToken(graphParser.DecimalIntegerLiteral, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterAnyNumber(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitAnyNumber(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitAnyNumber(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class TypeEofContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_typeEof;
    }

	type_() {
	    return this.getTypedRuleContext(Type_Context,0);
	};

	EOF() {
	    return this.getToken(graphParser.EOF, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterTypeEof(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitTypeEof(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitTypeEof(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class Type_Context extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_type_;
        this._nonNullableType = null;
        this.types = [];
        this._questionMarkOptional = null;
        this.nullableFlags = [];
    }

	nonNullableType = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(NonNullableTypeContext);
	    } else {
	        return this.getTypedRuleContext(NonNullableTypeContext,i);
	    }
	};

	questionMarkOptional = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(QuestionMarkOptionalContext);
	    } else {
	        return this.getTypedRuleContext(QuestionMarkOptionalContext,i);
	    }
	};

	TypeOr = function(i) {
		if(i===undefined) {
			i = null;
		}
	    if(i===null) {
	        return this.getTokens(graphParser.TypeOr);
	    } else {
	        return this.getToken(graphParser.TypeOr, i);
	    }
	};


	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterType_(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitType_(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitType_(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class QuestionMarkOptionalContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_questionMarkOptional;
    }


	 
		copyFrom(ctx) {
			super.copyFrom(ctx);
		}

}


class PresentQuestionMarkContext extends QuestionMarkOptionalContext {

    constructor(parser, ctx) {
        super(parser);
        super.copyFrom(ctx);
    }

	QMARK() {
	    return this.getToken(graphParser.QMARK, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterPresentQuestionMark(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitPresentQuestionMark(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitPresentQuestionMark(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

graphParser.PresentQuestionMarkContext = PresentQuestionMarkContext;

class NoQuestionMarkContext extends QuestionMarkOptionalContext {

    constructor(parser, ctx) {
        super(parser);
        super.copyFrom(ctx);
    }


	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterNoQuestionMark(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitNoQuestionMark(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitNoQuestionMark(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

graphParser.NoQuestionMarkContext = NoQuestionMarkContext;

class ObjectTypeElementSeparatorContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_objectTypeElementSeparator;
    }

	COMMA() {
	    return this.getToken(graphParser.COMMA, 0);
	};

	SEMICOLON() {
	    return this.getToken(graphParser.SEMICOLON, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterObjectTypeElementSeparator(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitObjectTypeElementSeparator(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitObjectTypeElementSeparator(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class NonNullableTypeContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_nonNullableType;
    }


	 
		copyFrom(ctx) {
			super.copyFrom(ctx);
		}

}


class PredefinedTypeRefContext extends NonNullableTypeContext {

    constructor(parser, ctx) {
        super(parser);
        super.copyFrom(ctx);
    }

	predefinedType() {
	    return this.getTypedRuleContext(PredefinedTypeContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterPredefinedTypeRef(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitPredefinedTypeRef(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitPredefinedTypeRef(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

graphParser.PredefinedTypeRefContext = PredefinedTypeRefContext;

class ArrayTypeContext extends NonNullableTypeContext {

    constructor(parser, ctx) {
        super(parser);
        this.nullable = null;;
        super.copyFrom(ctx);
    }

	nonNullableType() {
	    return this.getTypedRuleContext(NonNullableTypeContext,0);
	};

	LBRACKET() {
	    return this.getToken(graphParser.LBRACKET, 0);
	};

	RBRACKET() {
	    return this.getToken(graphParser.RBRACKET, 0);
	};

	QMARK() {
	    return this.getToken(graphParser.QMARK, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterArrayType(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitArrayType(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitArrayType(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

graphParser.ArrayTypeContext = ArrayTypeContext;

class StringLiteralTypeContext extends NonNullableTypeContext {

    constructor(parser, ctx) {
        super(parser);
        this.value = null;;
        super.copyFrom(ctx);
    }

	StringLiteral() {
	    return this.getToken(graphParser.StringLiteral, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterStringLiteralType(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitStringLiteralType(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitStringLiteralType(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

graphParser.StringLiteralTypeContext = StringLiteralTypeContext;

class TupleTypeContext extends NonNullableTypeContext {

    constructor(parser, ctx) {
        super(parser);
        this._type_ = null;;
        this.elementTypes = [];;
        super.copyFrom(ctx);
    }

	LBRACKET() {
	    return this.getToken(graphParser.LBRACKET, 0);
	};

	RBRACKET() {
	    return this.getToken(graphParser.RBRACKET, 0);
	};

	COMMA = function(i) {
		if(i===undefined) {
			i = null;
		}
	    if(i===null) {
	        return this.getTokens(graphParser.COMMA);
	    } else {
	        return this.getToken(graphParser.COMMA, i);
	    }
	};


	type_ = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(Type_Context);
	    } else {
	        return this.getTypedRuleContext(Type_Context,i);
	    }
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterTupleType(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitTupleType(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitTupleType(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

graphParser.TupleTypeContext = TupleTypeContext;

class TypeReferenceContext extends NonNullableTypeContext {

    constructor(parser, ctx) {
        super(parser);
        super.copyFrom(ctx);
    }

	typeAlias() {
	    return this.getTypedRuleContext(TypeAliasContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterTypeReference(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitTypeReference(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitTypeReference(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

graphParser.TypeReferenceContext = TypeReferenceContext;

class AngleUnionTypeContext extends NonNullableTypeContext {

    constructor(parser, ctx) {
        super(parser);
        this._type_ = null;;
        this.types = [];;
        super.copyFrom(ctx);
    }

	LANGLE() {
	    return this.getToken(graphParser.LANGLE, 0);
	};

	RANGLE() {
	    return this.getToken(graphParser.RANGLE, 0);
	};

	TypeOr = function(i) {
		if(i===undefined) {
			i = null;
		}
	    if(i===null) {
	        return this.getTokens(graphParser.TypeOr);
	    } else {
	        return this.getToken(graphParser.TypeOr, i);
	    }
	};


	type_ = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(Type_Context);
	    } else {
	        return this.getTypedRuleContext(Type_Context,i);
	    }
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterAngleUnionType(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitAngleUnionType(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitAngleUnionType(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

graphParser.AngleUnionTypeContext = AngleUnionTypeContext;

class ParenthesisedTypeContext extends NonNullableTypeContext {

    constructor(parser, ctx) {
        super(parser);
        super.copyFrom(ctx);
    }

	LPAREN() {
	    return this.getToken(graphParser.LPAREN, 0);
	};

	type_() {
	    return this.getTypedRuleContext(Type_Context,0);
	};

	RPAREN() {
	    return this.getToken(graphParser.RPAREN, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterParenthesisedType(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitParenthesisedType(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitParenthesisedType(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

graphParser.ParenthesisedTypeContext = ParenthesisedTypeContext;

class ObjectTypeContext extends NonNullableTypeContext {

    constructor(parser, ctx) {
        super(parser);
        this._propertySignature = null;;
        this.properties = [];;
        super.copyFrom(ctx);
    }

	LCURL() {
	    return this.getToken(graphParser.LCURL, 0);
	};

	RCURL() {
	    return this.getToken(graphParser.RCURL, 0);
	};

	objectTypeElementSeparator = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(ObjectTypeElementSeparatorContext);
	    } else {
	        return this.getTypedRuleContext(ObjectTypeElementSeparatorContext,i);
	    }
	};

	indexSignature() {
	    return this.getTypedRuleContext(IndexSignatureContext,0);
	};

	propertySignature = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(PropertySignatureContext);
	    } else {
	        return this.getTypedRuleContext(PropertySignatureContext,i);
	    }
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterObjectType(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitObjectType(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitObjectType(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

graphParser.ObjectTypeContext = ObjectTypeContext;

class PredefinedTypeContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_predefinedType;
    }


	 
		copyFrom(ctx) {
			super.copyFrom(ctx);
		}

}


class NumberTypeContext extends PredefinedTypeContext {

    constructor(parser, ctx) {
        super(parser);
        super.copyFrom(ctx);
    }

	Number() {
	    return this.getToken(graphParser.Number, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterNumberType(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitNumberType(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitNumberType(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

graphParser.NumberTypeContext = NumberTypeContext;

class BooleanTypeContext extends PredefinedTypeContext {

    constructor(parser, ctx) {
        super(parser);
        super.copyFrom(ctx);
    }

	Boolean() {
	    return this.getToken(graphParser.Boolean, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterBooleanType(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitBooleanType(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitBooleanType(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

graphParser.BooleanTypeContext = BooleanTypeContext;

class StringTypeContext extends PredefinedTypeContext {

    constructor(parser, ctx) {
        super(parser);
        super.copyFrom(ctx);
    }

	String() {
	    return this.getToken(graphParser.String, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterStringType(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitStringType(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitStringType(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

graphParser.StringTypeContext = StringTypeContext;

class EmptyTypeContext extends PredefinedTypeContext {

    constructor(parser, ctx) {
        super(parser);
        super.copyFrom(ctx);
    }

	Empty() {
	    return this.getToken(graphParser.Empty, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterEmptyType(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitEmptyType(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitEmptyType(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

graphParser.EmptyTypeContext = EmptyTypeContext;

class UnknownTypeContext extends PredefinedTypeContext {

    constructor(parser, ctx) {
        super(parser);
        super.copyFrom(ctx);
    }

	Unknown() {
	    return this.getToken(graphParser.Unknown, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterUnknownType(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitUnknownType(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitUnknownType(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

graphParser.UnknownTypeContext = UnknownTypeContext;

class PropertySignatureContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_propertySignature;
    }

	propertyName() {
	    return this.getTypedRuleContext(PropertyNameContext,0);
	};

	typeAnnotation() {
	    return this.getTypedRuleContext(TypeAnnotationContext,0);
	};

	QMARK() {
	    return this.getToken(graphParser.QMARK, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterPropertySignature(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitPropertySignature(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitPropertySignature(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class IndexSignatureContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_indexSignature;
    }

	LBRACKET() {
	    return this.getToken(graphParser.LBRACKET, 0);
	};

	ID() {
	    return this.getToken(graphParser.ID, 0);
	};

	COLON() {
	    return this.getToken(graphParser.COLON, 0);
	};

	String() {
	    return this.getToken(graphParser.String, 0);
	};

	RBRACKET() {
	    return this.getToken(graphParser.RBRACKET, 0);
	};

	typeAnnotation() {
	    return this.getTypedRuleContext(TypeAnnotationContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterIndexSignature(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitIndexSignature(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitIndexSignature(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class TypeAnnotationContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_typeAnnotation;
    }

	COLON() {
	    return this.getToken(graphParser.COLON, 0);
	};

	type_() {
	    return this.getTypedRuleContext(Type_Context,0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterTypeAnnotation(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitTypeAnnotation(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitTypeAnnotation(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class TypeAliasContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = graphParser.RULE_typeAlias;
    }

	ID() {
	    return this.getToken(graphParser.ID, 0);
	};

	enterRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.enterTypeAlias(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof graphListener ) {
	        listener.exitTypeAlias(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof graphVisitor ) {
	        return visitor.visitTypeAlias(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}




graphParser.FileContext = FileContext; 
graphParser.ReferenceFileContext = ReferenceFileContext; 
graphParser.ExpressionFileContext = ExpressionFileContext; 
graphParser.BlockContentContext = BlockContentContext; 
graphParser.SystemDescriptionContext = SystemDescriptionContext; 
graphParser.SystemElementDescriptionContext = SystemElementDescriptionContext; 
graphParser.ContextDescriptionContext = ContextDescriptionContext; 
graphParser.ContextElementDescriptionContext = ContextElementDescriptionContext; 
graphParser.ExternalFunctionDeclarationContext = ExternalFunctionDeclarationContext; 
graphParser.ImportDefinitionContext = ImportDefinitionContext; 
graphParser.WhenDescriptionContext = WhenDescriptionContext; 
graphParser.TypeDefinitionContext = TypeDefinitionContext; 
graphParser.FunctionDescriptionContext = FunctionDescriptionContext; 
graphParser.ContextFunctionDescriptionContext = ContextFunctionDescriptionContext; 
graphParser.BlockDeclarationContext = BlockDeclarationContext; 
graphParser.NodeDeclarationContext = NodeDeclarationContext; 
graphParser.DigressionDeclarationContext = DigressionDeclarationContext; 
graphParser.DigressionContentContext = DigressionContentContext; 
graphParser.CommentContext = CommentContext; 
graphParser.NodeContentContext = NodeContentContext; 
graphParser.NodeOnExitSectionContext = NodeOnExitSectionContext; 
graphParser.NodeOnExitSectionItemContext = NodeOnExitSectionItemContext; 
graphParser.OnExitDoWithConfidentContext = OnExitDoWithConfidentContext; 
graphParser.NodeOnDigressionReturnSectionContext = NodeOnDigressionReturnSectionContext; 
graphParser.NodeTransitionSectionContext = NodeTransitionSectionContext; 
graphParser.NamedTransitionDefinitionContext = NamedTransitionDefinitionContext; 
graphParser.TransitionDefinitionContext = TransitionDefinitionContext; 
graphParser.TransitionDefinitionOnMessageContext = TransitionDefinitionOnMessageContext; 
graphParser.TimeoutTransitionDefinitionContext = TimeoutTransitionDefinitionContext; 
graphParser.ConditionalTransitionDefinitionContext = ConditionalTransitionDefinitionContext; 
graphParser.UnconditionalTransitionDefinitionContext = UnconditionalTransitionDefinitionContext; 
graphParser.DoSectionContext = DoSectionContext; 
graphParser.BlockBodyContext = BlockBodyContext; 
graphParser.OneOrGroupedCommandContext = OneOrGroupedCommandContext; 
graphParser.NodeCommandContext = NodeCommandContext; 
graphParser.CycleCommandContext = CycleCommandContext; 
graphParser.StatementContext = StatementContext; 
graphParser.StatementBodyContext = StatementBodyContext; 
graphParser.LogExpressionTypeCommandContext = LogExpressionTypeCommandContext; 
graphParser.DigressionControlContext = DigressionControlContext; 
graphParser.DigressionSpecContext = DigressionSpecContext; 
graphParser.ExitCommandContext = ExitCommandContext; 
graphParser.ReturnCommandContext = ReturnCommandContext; 
graphParser.VariableDefinitionContext = VariableDefinitionContext; 
graphParser.VariableDeclarationContext = VariableDeclarationContext; 
graphParser.TypeDescriptionContext = TypeDescriptionContext; 
graphParser.ConditionalCommandContext = ConditionalCommandContext; 
graphParser.ForeachCommandContext = ForeachCommandContext; 
graphParser.WaitCommandContext = WaitCommandContext; 
graphParser.ActivatedTransitionListContext = ActivatedTransitionListContext; 
graphParser.GotoCommandContext = GotoCommandContext; 
graphParser.SetCommandContext = SetCommandContext; 
graphParser.AssignOpCommandContext = AssignOpCommandContext; 
graphParser.LValueContext = LValueContext; 
graphParser.ExpressionContext = ExpressionContext; 
graphParser.ObjectPropertyExpressionPartContext = ObjectPropertyExpressionPartContext; 
graphParser.ExternalFunctionCallContext = ExternalFunctionCallContext; 
graphParser.BlockFunctionCallContext = BlockFunctionCallContext; 
graphParser.SlotFillingCallContext = SlotFillingCallContext; 
graphParser.BuiltinFunctionCallContext = BuiltinFunctionCallContext; 
graphParser.ArgumentInvokationContext = ArgumentInvokationContext; 
graphParser.ArgumentListContext = ArgumentListContext; 
graphParser.NamedArgumentListContext = NamedArgumentListContext; 
graphParser.PositionalArgumentListContext = PositionalArgumentListContext; 
graphParser.PositionalArgumentContext = PositionalArgumentContext; 
graphParser.NamedArgumentContext = NamedArgumentContext; 
graphParser.ParameterListContext = ParameterListContext; 
graphParser.ParameterContext = ParameterContext; 
graphParser.ReferenceContext = ReferenceContext; 
graphParser.WhenIdContext = WhenIdContext; 
graphParser.NodeIdContext = NodeIdContext; 
graphParser.BlockIdContext = BlockIdContext; 
graphParser.DigressionIdContext = DigressionIdContext; 
graphParser.TransitionNameContext = TransitionNameContext; 
graphParser.ReferenceNameContext = ReferenceNameContext; 
graphParser.MemberNameContext = MemberNameContext; 
graphParser.TagNameContext = TagNameContext; 
graphParser.FactNameContext = FactNameContext; 
graphParser.FieldNameContext = FieldNameContext; 
graphParser.VariableNameContext = VariableNameContext; 
graphParser.ArgumentNameContext = ArgumentNameContext; 
graphParser.ParameterNameContext = ParameterNameContext; 
graphParser.PropertyNameContext = PropertyNameContext; 
graphParser.BuiltinFunctionNameContext = BuiltinFunctionNameContext; 
graphParser.ExternalFunctionNameContext = ExternalFunctionNameContext; 
graphParser.FunctionNameContext = FunctionNameContext; 
graphParser.AnyNumberContext = AnyNumberContext; 
graphParser.TypeEofContext = TypeEofContext; 
graphParser.Type_Context = Type_Context; 
graphParser.QuestionMarkOptionalContext = QuestionMarkOptionalContext; 
graphParser.ObjectTypeElementSeparatorContext = ObjectTypeElementSeparatorContext; 
graphParser.NonNullableTypeContext = NonNullableTypeContext; 
graphParser.PredefinedTypeContext = PredefinedTypeContext; 
graphParser.PropertySignatureContext = PropertySignatureContext; 
graphParser.IndexSignatureContext = IndexSignatureContext; 
graphParser.TypeAnnotationContext = TypeAnnotationContext; 
graphParser.TypeAliasContext = TypeAliasContext; 
