import React, { FC, useState, useCallback, useEffect } from "react";
import { TestPromptDTO, PromptVersionDTO } from "@dasha.ai/sdk/web/rest-api/generated/testsystem";
import ReactDiffViewer from "react-diff-viewer";

import "./index.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Card,
  Checkbox,
  Form,
  Input,
  List,
  ListItem,
  Loader,
  Message,
  Modal,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
  TabPane,
  TextArea,
} from "semantic-ui-react";
import CopyButtonAction from "../uikit/CopyButtonAction";
import { ActionButton, Icon } from "../uikit";
import { GptOptionsTable } from "../uikit/gpt/Options/gptOptions";
import { FunctionEditorCard } from "../uikit/gpt/Functions/functionEditor";
import { Prompt } from "../core/api/prompts/PromptStore";
import { useStore } from "../core/api/GlobalStoreContext";
import { observer } from "mobx-react-lite";
import * as S from "./styled";
import { TestGroupView } from "../TestViewer/TestList/testGroupView";
import { GptEmulatorInternalModal } from "./gptEmulatorInternal";
import { HistoryList } from "../uikit/gpt/History/historyList";
import { HistoryHolder } from "../uikit/gpt/History/types";
import { PromptVersion } from "../core/api/prompts/PromptVersion";
import { TestCase } from "../core/api/prompts/TestCase";
import { TestCardsView } from "../TestViewer/Cards";
import UIManager from "../core/misc/UIManager";
interface Props {}

export const PromptEditor: FC<Props> = observer((props) => {
  const { account, prompts } = useStore();
  const { promptId, testId } = useParams();
  const [prompt, setPrompt] = useState<Prompt | undefined>();
  const [selectedTestId, setSelectedTestIdInternal] = useState<string | undefined>();
  const [selectedTest, setSelectedTest] = useState<TestCase | undefined>();
  const [currentVersion, setCurrentVersion] = useState<PromptVersion | undefined>();
  const [loading, setLoading] = useState(true);
  const [saveChangesOpen, setSaveChangesOpen] = useState(false);
  const [description, setDescription] = useState("");

  const setSelectedTestId = useCallback(
    (id: string | undefined) => {
      setSelectedTestIdInternal(id);
      if (id === undefined) {
        setSelectedTest(undefined);
      } else {
        const test = prompt?.getTestCase(id);
        setSelectedTest(test);
        history.pushState({}, "switchTest", `/prompts/${promptId}/test/${id}`);
      }
    },
    [prompt]
  );

  useEffect(() => {
    prompts.loadPromptsAsync(true).catch((e) => UIManager.notice(`Failed to load prompts: ${e}`));
  }, [account, prompts]);

  const saveLocalChanges = useCallback(() => {
    currentVersion && account.setValue(`changes_prompt_${currentVersion?.promptId}`, currentVersion?.GetChanges());
    selectedTest && account.setValue(`changes_test_${selectedTest?.id}`, selectedTest?.GetUpdates());
  }, [currentVersion, selectedTest, account]);

  useEffect(() => {
    setLoading(true);
    const fetch = async () => {
      if (promptId !== undefined) {
        const fetched = await prompts.getAsync(promptId);
        await fetched.LoadVersionsAsync();
        await fetched.LoadTestCasesAsync();
        setPrompt(fetched);

        const localPromptChanges = account.getValue<string>(`changes_prompt_${promptId}`);
        if (localPromptChanges !== null) {
          fetched.versions[0]?.ApplyChanges(localPromptChanges);
        }
        setCurrentVersion(fetched.versions[0]);
        for (var x of fetched.testCases) {
          var data = account.getValue<string>(`changes_test_${x.id}`);
          if (data !== null && data !== undefined) {
            x.ApplyUpdates(data);
          }
        }

        if (testId !== undefined) {
          setSelectedTestIdInternal(testId);
          setSelectedTest(fetched?.getTestCase(testId));
        }
      }
    };
    fetch()
      .catch((e) => UIManager.notice(`Failed to laod prompt: ${e}`))
      .finally(() => setLoading(false));
  }, [promptId, account, prompts, testId]);

  const onStorageUpdate = useCallback((e) => {
    const { key, newValue } = e;
    if (key.includes(promptId)) {
      const localPromptChanges = account.getValue<string>(`changes_prompt_${promptId}`);
      currentVersion?.ApplyChanges(localPromptChanges);
    }
  }, [account, currentVersion]);

  
  useEffect(() => {
    window.addEventListener("storage", onStorageUpdate);
    return () => {
      window.removeEventListener("storage", onStorageUpdate);
    };
  }, [account, currentVersion]);

  if (loading) {
    return (
      <>
        <Icon name="spinner" style={{ width: 32 }} />
        <p>Loading Prompt</p>
      </>
    );
  }
  return (
    <>
      <S.Aside id="aside">
        <Link to={"/prompts"}>
          <Icon name="apps" style={{ margin: 4 }} />
        </Link>
        <TestGroupView
          prompt={prompt}
          indent={0}
          onSelect={(x) => setSelectedTestId(x)}
          selectedId={selectedTestId}
          onRun={(x, times) => {
            const test = prompt?.getTestCase(x);
            saveLocalChanges();
            prompt?.emulateAsync(currentVersion?.getData(test?.args), times, test?.id ? [test?.id] : []);
          }}
          onRunAll={(times) => {
            saveLocalChanges();
            prompt?.emulateAsync(
              currentVersion?.getData(selectedTest?.args),
              times,
              prompt.testCases.map((x) => x.id)
            );
          }}
        ></TestGroupView>
      </S.Aside>
      <div id="editor" style={{ height: "90vh" }}>
        <div style={{ display: "flex" }}>
          { selectedTest && 
            <ActionButton style={{ marginRight: "auto", marginLeft: 0 }} onClick={() => {
              saveLocalChanges();
              prompt?.emulateAsync(currentVersion?.getData(selectedTest?.args), 1, [selectedTest.id]);
            }}>
              Run test
            </ActionButton>
          }
          {((prompt && prompt.isDirty) ||
            (currentVersion && currentVersion.isDirty) ||
            (selectedTest &&
              (selectedTest.isDirty || selectedTest.history.isDirty || selectedTest.refResult.isDirty))) && (
            <Modal
              trigger={
                <ActionButton style={{ marginRight: 0, marginLeft: "auto" }} onClick={() => setSaveChangesOpen(true)}>
                  Save changes
                </ActionButton>
              }
              open={saveChangesOpen}
              onClose={() => setSaveChangesOpen(false)}
            >
              <Modal.Header>Save changes</Modal.Header>
              <Modal.Content>
                <Form>
                  <Form.Field>
                    <TextArea
                      rows={3}
                      label="changes description"
                      placeholder="description"
                      value={description}
                      onChange={(e, d) => setDescription(d.value?.toString() ?? "")}
                    />
                  </Form.Field>
                </Form>
              </Modal.Content>
              <Modal.Actions>
                <Button
                  onClick={() => {
                    saveLocalChanges();
                    prompt
                      ?.AddVersionAsync(description, currentVersion?.getData(undefined))
                      ?.then((x) => setCurrentVersion(x))
                      ?.catch((e) => UIManager.notice(`Failed to save version ${e}`))
                      ?.finally(() => setSaveChangesOpen(false));
                    prompt?.saveChangesAsync()?.catch((e) => UIManager.notice(`Failed to save prompt ${e}`));
                    selectedTest?.saveAsync()?.catch((e) => UIManager.notice(`Failed to save test: ${e}`));
                  }}
                >
                  Save changes
                </Button>
              </Modal.Actions>
            </Modal>
          )}
        </div>
        {!selectedTest && prompt && (
          <TestCardsView
            prompt={prompt}
            key="testCards"
            onSelect={(x) => setSelectedTestId(x)}
            onRun={(x, times) => {
              const test = prompt.getTestCase(x);
              saveLocalChanges();
              if (test && (test.isDirty || test.history.isDirty || test.refResult.isDirty)) {
                test
                  .saveAsync()
                  .then(() => {
                    prompt?.emulateAsync(currentVersion?.getData(test?.args), times, test?.id ? [test?.id] : []);
                  })
                  .catch((e) => {
                    UIManager.notice(`Failed to start test '${test.name}': ${e.message}`);
                  });
              } else {
                prompt?.emulateAsync(currentVersion?.getData(test?.args), times, test?.id ? [test?.id] : []);
              }
            }}
          />
        )}
        {currentVersion && prompt && selectedTest && (
          <GptEmulatorInternalModal
            prompt={prompt}
            version={currentVersion}
            testCase={selectedTest}
          ></GptEmulatorInternalModal>
        )}
      </div>
    </>
  );
});
