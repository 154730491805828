import React, { FC } from "react";
import { observer } from "mobx-react-lite";

import ConversationsFilter from "@core/profiler/ConversationsFilter";
import FilterMenu from "../../../DataMarkup/components/FilterMenu";
import { StrokeButton, Icon } from "../../../../../uikit";
import * as S from "./styled";

interface Props {
  filter: ConversationsFilter;
  isExporting: boolean;
  onExport: () => void;
}

const Filter: FC<Props> = ({ filter, isExporting, onExport, children }) => {
  const filters = [
    {
      name: "Node from",
      searchPlaceholder: "Search by node from",
      actions: filter.nodesFrom,
      onReset: () => filter.resetSelectedFilters("selectedNodesFrom"),
      selected: filter.selectedNodesFrom,
    },
    {
      name: "Node to",
      searchPlaceholder: "Search by node to",
      actions: filter.nodesTo,
      onReset: () => filter.resetSelectedFilters("selectedNodesTo"),
      selected: filter.selectedNodesTo,
    },
    ...Array.from(Object.keys(filter.valueFilters))
      .filter((key) => filter.selectedFilters.has(key))
      .map((key) => ({
        name: key,
        searchPlaceholder: `Search ${key}`,
        actions: filter.valueFilters[key].actions,
        onReset: () => {
          filter.valueFilters[key].selectedValues.clear();
        },
        selected: filter.valueFilters[key].selectedValues,
      })),
  ];

  const handleExport = () => onExport();

  return (
    <S.Container>
      <S.GlobalStyles />
      <S.FiltersBox>
        <div>
          {filters.map((f) => (
            <FilterMenu key={f.name} {...f} children />
          ))}
        </div>
        <div>
          <FilterMenu
            name="Filters"
            hasClearBtn
            children
            searchPlaceholder="Search by filters"
            actions={filter.filters}
            onReset={() => filter.selectedFilters.clear()}
            selected={filter.selectedFilters}
            renderTrigger={
              <S.FilterBtn>
                <span>Filters</span>
                <Icon name="filter-icon" />
              </S.FilterBtn>
            }
          />
          <FilterMenu
            name="Customize columns"
            children
            hasTooltip={false}
            searchPlaceholder="Search by columns"
            actions={filter.columns}
            onReset={() => filter.selectedColumns.clear()}
            selected={filter.selectedColumns}
            renderTrigger={
              <S.FilterBtn>
                <span>Customize columns</span>
                <Icon name="settings-icon" />
              </S.FilterBtn>
            }
          />
          <StrokeButton disabled={isExporting} onClick={handleExport}>
            {isExporting ? "Exporting" : "Export to CSV"}
          </StrokeButton>
          {children}
        </div>
      </S.FiltersBox>
    </S.Container>
  );
};

export default observer(Filter);
