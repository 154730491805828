import { FunctionDescription } from "@dasha.ai/sdk/web/rest-api/generated/core";
import { ITriggerOperation } from "@dasha.ai/sdk/web/rest-api/generated/testsystem";
import { makeAutoObservable } from "mobx";
import { objectToCamel } from "ts-case-convert";

export type Parameter = Record<string, any>;

export class gptFunctionsStore {
  public Functions: gptFunction[];
  public InitialFuncions: gptFunction[];


  constructor(descriptions: FunctionDescription[]) {
    this.Functions = descriptions.map((x) => new gptFunction(objectToCamel(x)));
    this.InitialFuncions = descriptions.map((x) => new gptFunction(objectToCamel(x)));
    makeAutoObservable(this);
  }

  public get isDirty(): boolean {
    if (this.Functions?.length !== this.InitialFuncions?.length)
    {
      return true;
    }
    return this.Functions.findIndex((x) => x.isDirty) >= 0;
  }

  public AddOrReplaceFunction(name: string, description: FunctionDescription) {
    for (var i in this.Functions) {
      if (this.Functions[i].Name === name) {
        this.Functions[i] = new gptFunction(description);
        return;
      }
    }

    this.Functions.push(new gptFunction(description));
  }

  public Replace(description: Map<string, FunctionDescription>) {
    for (var [k, v] of description.entries()) {
      this.AddOrReplaceFunction(k, v);
    }
  }

  public GetByName(name: string): gptFunction | undefined {
    return this.Functions.find((x) => x.Name === name);
  }

  public GetFunctionsDescription(): Map<string, FunctionDescription> {
    const result = new Map<string, FunctionDescription>();
    for (var f of this.Functions) {
      result.set(f.Name, f.GetFunctionDescription());
    }
    return result;
  }

  public Reset() {
    for (var k of this.Functions) {
      k.ResetChanges();
    }
  }
}

export class gptFunction {
  public Name: string;
  public Description: string;
  public Returns: string;
  public Parameters: Record<string, Parameter>;
  public FunctionTrigger: ITriggerOperation | null;
  public Required: string[];
  public Scheme: any;

  private InitialName: string;
  private InitialDescr: string;
  private InitialReturns: string;
  private InitialParameters: Record<string, Parameter>;
  private InitialRequired: string[];

  public InitialDescription: FunctionDescription;

  public get isDirty(): boolean {
    if (
      this.Name !== this.InitialName ||
      this.Description !== this.InitialDescr ||
      this.Returns !== this.InitialReturns
    ) {
      return true;
    }
    if (JSON.stringify(this.InitialRequired) !== JSON.stringify(this.Required)) {
      return true;
    }

    if (JSON.stringify(this.InitialParameters) !== JSON.stringify(this.Parameters)) {
      return true;
    }

    return false;
  }

  public setName(name: string) {
    this.Name = name;
  }

  public setDescription(description: string) {
    this.Description = description;
  }

  public setReturns(returns: string) {
    this.Returns = returns;
  }

  public updateParameterName(oldName: string, name: string) {
    this.Parameters[name] = this.Parameters[oldName];
    delete this.Parameters[name];
  }

  public updateParameterScheme(name: string, key: string, value: any) {
    const newParam = { ...this.Parameters[name], ...{ [key]: value } };
    this.Parameters = { ...this.Parameters , ...{ [name]: newParam } };
  }

  constructor(description: FunctionDescription) {
    this.InitialDescription = { ...description };
    makeAutoObservable(this);
    this.parse(description);

    this.InitialName = this.Name;
    this.InitialDescr = this.Description;
    this.InitialReturns = this.Returns;
    this.InitialRequired = [...this.Required];
    this.InitialParameters = { ...this.Parameters };
  }

  private parse(description: FunctionDescription) {
    const inititalDesc = description.description ?? "";
    const returnsConst = " returns: ";
    const index = inititalDesc.lastIndexOf(returnsConst);
    const params = JSON.parse(description.parameters ?? "{}");

    this.Name = description.name ?? "";
    if (index >= 0) {
      this.Description = inititalDesc.substring(0, index);
      this.Returns = inititalDesc.substring(index + returnsConst.length);
    } else {
      this.Description = inititalDesc;
      this.Returns = "";
    }

    this.Parameters = params["properties"] ?? {};
    this.Required = params["required"] ?? [];
    this.FunctionTrigger = description.functionTrigger ?? null;
    this.InitialDescription = description;
  }

  public ResetChanges() {
    this.parse(this.InitialDescription);
  }

  public GetJavaDoc() {
    var docs = "/**\n" + this.Description.replaceAll("\n", "\n*").trim() + "\n";
    for (var [k, v] of Object.entries(this.Parameters)) {
      docs += "* @param " + k + " " + (v.description as string).trim() + "\n";
    }
    docs += "* @return " + this.Returns.replaceAll("\n", " ").trim();
    docs += "\n*/";
    return docs;
  }

  public getJSONParams() {
    return {
      type: "object",
      required: [...this.Required],
      properties: JSON.parse(JSON.stringify(this.Parameters)),
    };
  }

  public updateJSONParams(params: any) {
    this.Parameters = params.properties ?? {};
    this.Required = params.required ?? [];
  }

  public GetFunctionDescription(): FunctionDescription {
    return {
      name: this.Name,
      description: this.Description + " returns: " + this.Returns,
      parameters: JSON.stringify(this.getJSONParams()),
      blockPath: this.InitialDescription.blockPath,
      functionTrigger: this.FunctionTrigger
    };
  }

  public SetFunctionTrigger(trigger: ITriggerOperation | null) {
    this.FunctionTrigger = trigger;
  }
}
