import React, { FC, useCallback, useMemo } from "react";
import { useTable, useBlockLayout, useSortBy } from "react-table";
import { observer } from "mobx-react-lite";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList } from "react-window";

import * as S from "./styled";

import IConversationsTable from "@core/profiler/ConversationsTable";
import { Icon, StrokeButton } from "../../../uikit";
import { DialogBtn } from "../DataMarkup/components/SubRow/styled";
import { ReactComponent as DialogIcon } from "../../assets/dialog.svg";
import { ReactComponent as GridLayout } from "@core/misc/GridLayout";
import { getColumns } from "./helpers";
import Filter from "./components/Filter";
import { Link } from "react-router-dom";
import { useStore } from "../../../core/api/GlobalStoreContext";
import Checkbox from "../DataMarkup/components/Checkbox";
import { AddSupportTicketModal } from "../../../DiagnosticsPanel/Support/AddTicketModal";
interface Props {
  table: IConversationsTable;
  layout: GridLayout;
}

const getScrollbarWidth = () => {
  // thanks too https://davidwalsh.name/detect-scrollbar-width
  const scrollDiv = document.createElement("div");
  scrollDiv.setAttribute("style", "width: 100px; height: 100px; overflow: scroll; position:absolute; top:-9999px;");
  document.body.appendChild(scrollDiv);
  const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
  document.body.removeChild(scrollDiv);
  return scrollbarWidth;
};

const ConversationsTable: FC<Props> = ({ table, layout }) => {
  const data = table.filter.filteredData;
  const columns = useMemo(() => getColumns(data), [data]);
  const { account } = useStore();
  const [cachedDelayJobs, setCachedDelayJobs] = React.useState<string[]>([]);
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows, totalColumnsWidth } = useTable(
    {
      data,
      columns,
      defaultColumn: {
        minWidth: 100,
        width: 150,
        maxWidth: 600,
      },
    },
    useBlockLayout,
    useSortBy
  );

  const handleExport = () => {
    table.exportToCsv(columns);
  };

  const handleOpenDialog = (id) => {
    layout.expandPanel("runner");
    table.openSession(id).catch((err) => console.log(err));
  };

  const delayedAddJob = useCallback(
    (jobId, add) => {
      var delayedJobs: string[] = account.getValue<string[]>("profiler_jobs") ?? [];
      const idx = delayedJobs.indexOf(jobId);
      if (idx < 0) {
        if (add) {
          delayedJobs.push(jobId);
        }
      } else {
        if (!add) {
          delayedJobs = [...delayedJobs.slice(0, idx), ...delayedJobs.slice(idx + 1)];
        }
      }
      setCachedDelayJobs(delayedJobs);
      account.setValue("profiler_jobs", delayedJobs);
    },
    [account]
  );

  React.useEffect(() => {
    setCachedDelayJobs(account.getValue<string[]>("profiler_jobs") ?? []);
  }, [account]);

  const scrollbarWidth = useMemo(() => getScrollbarWidth(), []);
  const ticketCreated = useCallback(() => {
    setCachedDelayJobs([]);
    account.setValue("profiler_jobs", []);
  }, [account]);
  const RenderRow = useCallback(
    ({ index, style }) => {
      const row = rows[index];
      prepareRow(row);

      return (
        <S.Row {...row.getRowProps({ style })}>
          <S.Cell $isActionCol>
            <div>
              <span>
                <Checkbox
                  checked={cachedDelayJobs.indexOf(row.original.id) >= 0}
                  onChange={(c) => delayedAddJob(row.original.id, c)}
                />
                {index + 1}
              </span>
            </div>
          </S.Cell>
          <S.Cell $isActionCol>
            <S.ActionsBox>
              <DialogBtn
                style={{ opacity: 1, visibility: "visible", position: "static" }}
                onClick={() => handleOpenDialog(row.original.id)}
              >
                <DialogIcon />
              </DialogBtn>
            </S.ActionsBox>
          </S.Cell>
          <S.Cell $isActionCol>
            <S.ActionsBox>
              <Link to={`/inspector/${row.original.id}`} target="_blank" rel="noopener noreferrer">
                Inspect
              </Link>
            </S.ActionsBox>
          </S.Cell>
          {row.cells.map(
            (cell) =>
              table.filter.selectedColumns.has(cell.column.Header) && (
                <S.Cell {...cell.getCellProps()}>
                  <div>
                    <span>{cell.render("Cell")}</span>
                  </div>
                </S.Cell>
              )
          )}
        </S.Row>
      );
    },
    [prepareRow, rows]
  );

  return table.filter ? (
    <>
      <Filter filter={table.filter} isExporting={table.isExporting} onExport={handleExport}>
        <AddSupportTicketModal additionalInformation={cachedDelayJobs.join("\n")} onCommited={() => ticketCreated()}>
          <StrokeButton disabled={cachedDelayJobs.length == 0}>
            {" "}
            Create ticket / {cachedDelayJobs.length} jobs
          </StrokeButton>
        </AddSupportTicketModal>
      </Filter>
      <S.Container>
        <S.GlobalStyles />
        <S.Table {...getTableProps()} style={{ width: `${totalColumnsWidth + scrollbarWidth}px` }}>
          <S.TableHead>
            {headerGroups.map((headerGroup) => (
              <div {...headerGroup.getHeaderGroupProps()}>
                <S.Header $isActionCol>
                  <div>
                    <span>#</span>
                  </div>
                </S.Header>
                <S.Header $isActionCol></S.Header>
                <S.Header $isActionCol>Inspect</S.Header>
                {headerGroup.headers.map(
                  (column) =>
                    table.filter.selectedColumns.has(column.Header) && (
                      <S.Header {...column.getHeaderProps()}>
                        <div>
                          <div {...column.getSortByToggleProps()}>
                            <span>{column.render("Header")}</span>
                            <S.SortIcon>
                              {column.isSorted &&
                                (column.isSortedDesc ? <Icon name="arrow" /> : <Icon name="arrow" angle={180} />)}
                            </S.SortIcon>
                          </div>
                        </div>
                      </S.Header>
                    )
                )}
              </div>
            ))}
          </S.TableHead>
          <div {...getTableBodyProps()} style={{ height: "100%", minHeight: "100%", flex: 1 }}>
            <AutoSizer style={{ width: "100%", height: "100%" }}>
              {({ height }) => (
                <FixedSizeList
                  width={totalColumnsWidth + scrollbarWidth}
                  height={height}
                  itemCount={rows.length}
                  itemSize={40}
                >
                  {RenderRow}
                </FixedSizeList>
              )}
            </AutoSizer>
          </div>
        </S.Table>
      </S.Container>
    </>
  ) : (
    <div></div>
  );
};

export default observer(ConversationsTable);
